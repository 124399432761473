/* eslint-disable react-hooks/exhaustive-deps */
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { useAdminContext } from "../../context/AdminAuthContext";
import { Question, QuestionCloneType, QuestionDeleteType, Info } from "./Type";
import { onConfirmClone, getQuestionList, getTagDropdownData, onConfirmDelete, onConfirmRestore } from "./Utils";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { OptionType } from "../../../CommonComponent/v2/fields/Type";
import lock from "../../../Images/icons/lock.svg";
import globe from "../../../Images/icons/globe.svg";
import limited from "../../../Images/icons/limited.svg";
import wildgoatIcon from "../../../Images/icons/wildgoatdisabled.svg";
import virtualLinkedIcon from "../../../Images/icons/virtuallinkeddisabled.svg";
import virtualUnLinked from "../../../Images/icons/virtualUnlinkedDisabled.svg";
import { Box, Tooltip } from "@mui/material";
import { useUserInfo } from "../../../../commonhooks/Hooks";
import { useHistory } from "react-router";
import { getInitials } from "../../../../utils/JambarUtils";

export const useQuestionLibrary = () => {
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "question");
    const [questionList, setQuestionList] = useState<Question[]>([]);
    const [cloneId, setCloneId] = useState<QuestionCloneType | null>(null);
    const [restoreId, setRestoreId] = useState<Info | null>(null);
    const [viewId, setViewId] = useState<number | null>(null);
    const [deleteId, setDeleteId] = useState<QuestionDeleteType | null>(null);
    const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
    const [selectedTagOption, setSelectedTagOption] = useState<string>("all");
    const [selectedStatusOption, setSelectedStatusOption] = useState<string>("active");
    const [openAddModal, setOpenAddModal] = useState<boolean>(false);
    const {
        onlyOneGameAllowed,
        permissionQuizFullGame,
        getGameOption,
        permissionQuizPhysicalGame,
        permissionQuizSocketGame
    } = useUserInfo();
    const history = useHistory();
    useEffect(() => {
        fetchTagData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [selectedStatusOption]);

    useEffect(() => {
        setQuestionList(questionList);
    }, [selectedTagOption]);

    const toggleAddModal = () => {
        if (onlyOneGameAllowed) {
            history.push(`/admin/questionlibrary/${getGameOption()}/add`);
            return;
        }
        setOpenAddModal(!openAddModal);
    };

    const fetchData = () => {
        setIsLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setQuestionList([]);
            setIsLoading(false);
            return;
        }
        getQuestionList(selectedStatusOption, true)
            .then((res) => {
                setQuestionList(() => {
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                    return res;
                });
            })
            .catch(() => {
                setQuestionList([]);
                setIsLoading(false);
            });
    };
    const confirmDelete = () => {
        deleteId &&
            onConfirmDelete(deleteId.id)
                .then(() => {
                    setDeleteId(null);
                    toast.success("Challenge deleted successfully", SiteConstants.successToastConfiguration);
                    fetchData();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
    };
    const confirmClone = () => {
        cloneId &&
            onConfirmClone(cloneId.id)
                .then(() => {
                    setCloneId(null);
                    toast.success("Challenge duplicated successfully", SiteConstants.successToastConfiguration);
                    fetchData();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
    };

    const confirmRestore = () => {
        restoreId &&
            onConfirmRestore(restoreId.id)
                .then(() => {
                    setRestoreId(null);
                    toast.success("Challenge restored successfully", SiteConstants.successToastConfiguration);
                    fetchData();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
    };
    const handleResetFilter = () => {
        setSelectedStatusOption("all");
        setSelectedTagOption("all");
    };

    const fetchTagData = () => {
        if (!(permission && permission.includes("fetch"))) {
            setTagOptions([]);
            setIsLoading(false);
            return;
        }
        getTagDropdownData(true)
            .then((res) => {
                setTagOptions(res);
            })
            .catch(() => {
                setTagOptions([]);
            });
    };
    return {
        searchText,
        deleteId,
        selectedTagOption,
        questionList,
        isLoading,
        cloneId,
        selectedStatusOption,
        tagOptions,
        viewId,
        handleResetFilter,
        setSelectedStatusOption,
        setSearchText,
        setCloneId,
        setViewId,
        setSelectedTagOption,
        confirmDelete,
        setDeleteId,
        confirmClone,
        toggleAddModal,
        openAddModal,
        permission,
        fetchData,
        permissionQuizFullGame,
        permissionQuizSocketGame,
        permissionQuizPhysicalGame,
        setRestoreId,
        restoreId,
        confirmRestore
    };
};

export const useQuestionListHook = (selectedStatus: string) => {
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "question");
    const { isSuperAdmin, onlyOneGameAllowed } = useUserInfo();
    const selectVisibilityIcon = (type: string) => {
        if (type === "private")
            return (
                <Tooltip title={"Private"}>
                    <img style={{ marginRight: "3px", height: 20, width: 20 }} src={lock} alt={"lock"} />
                </Tooltip>
            );
        if (type === "global")
            return (
                <Tooltip title={"Global"}>
                    <img style={{ marginRight: "3px", height: 20, width: 20 }} src={globe} alt={"glob"} />
                </Tooltip>
            );
        return (
            <Tooltip title={"Limited"}>
                <img style={{ marginRight: "3px", height: 20, width: 20 }} src={limited} alt={"limited"} />
            </Tooltip>
        );
    };

    const renderGameTypeIcon = (type: string) => {
        if (onlyOneGameAllowed) {
            return <></>;
        }
        if (type === "virtualLinked")
            return (
                <Tooltip title={"Virtual Unlinked"}>
                    <img
                        style={{ marginRight: "5px", height: 20, width: 20 }}
                        src={virtualUnLinked}
                        alt={"virtualUnLinked"}
                    />
                </Tooltip>
            );
        if (type === "socketGame")
            return (
                <Tooltip title={"Virtual linked"}>
                    <img
                        style={{ marginRight: "5px", height: 20, width: 20 }}
                        src={virtualLinkedIcon}
                        alt={"virtualLinkedIcon"}
                    />
                </Tooltip>
            );
        if (type === "wildgoatGame")
            return (
                <Tooltip title={"Wildgoat game"}>
                    <img
                        style={{ marginRight: "5px", height: 20, width: 20 }}
                        src={wildgoatIcon}
                        alt={"wildgoatIcon"}
                    />
                </Tooltip>
            );
        return "";
    };

    const questionListColumns = (
        handleCopyLinkClick: (info: Info) => void,
        handleDeleteClick: (info: Info) => void,
        handleViewClick: (id: number) => void,
        handleEditClick: (gameId: string, id: number) => void,
        handleInfoClick: (id: number) => void,
        handleRestoreClick: (info: Info) => void
    ): GridColDef[] => [
        {
            field: "title",
            headerName: "Challenge Name",
            flex: 1,
            renderHeader: () => <StyledHeader label="Challenge Name" />,
            renderCell: (params: GridCellParams) => (
                <Box
                    alignItems={"center"}
                    style={{ cursor: "pointer" }}
                    display={"flex"}
                    onClick={() => handleInfoClick(params.row.id)}>
                    {renderGameTypeIcon(params.row.gameType)}
                    {selectVisibilityIcon(params.row.accessibilityScope)}
                    <StyledColumn label={params.row.title} />
                </Box>
            )
        },
        {
            field: "createdBy",
            flex: 1,
            renderHeader: () => <StyledHeader label="Created By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.createdBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.createdBy || "")}`} />
                        <StyledColumn label={`- ${params.row.createdDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "lastUpdatedBy",
            flex: 1,
            renderHeader: () => <StyledHeader label="Last Updated By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.lastUpdatedBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.lastUpdatedBy || "")}`} />
                        <StyledColumn label={`- ${params.row.lastUpdatedDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "id",
            headerName: "Action",
            width: 180,
            sortable: false,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isViewAvailaible={{
                        isAvailable: !!(permission && permission.includes("view")),
                        onClick: () => handleViewClick(params.row.id)
                    }}
                    isDeleteAvailaible={{
                        isAvailable:
                            !(
                                params.row.accessibilityScope === "global" &&
                                !isSuperAdmin &&
                                !!(permission && permission.includes("delete"))
                            ) && selectedStatus !== "deleted",
                        onClick: () =>
                            handleDeleteClick({
                                id: params.row.id,
                                title: params.row.title
                            })
                    }}
                    isCloneAvailaible={{
                        isAvailable: selectedStatus !== "deleted" && !!(permission && permission.includes("clone")),
                        onClick: () => {
                            handleCopyLinkClick({
                                id: params.row.id,
                                title: params.row.title
                            });
                        }
                    }}
                    isRestoreAvailiable={{
                        isAvailable: selectedStatus === "deleted",
                        onClick: () => {
                            handleRestoreClick({
                                id: params.row.id,
                                title: params.row.title
                            });
                        }
                    }}
                    isEditAvailaible={{
                        isAvailable: !(
                            params.row.accessibilityScope === "global" &&
                            !isSuperAdmin &&
                            !!(permission && permission.includes("update"))
                        ),
                        onClick: () => {
                            handleEditClick(params.row.gameType, params.row.id);
                        }
                    }}
                />
            )
        }
    ];

    return {
        questionListColumns
    };
};
