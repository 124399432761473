import { Dialog, Grid } from "@mui/material";
import StripeElement from "../../../CommonComponent/v2/stripe/StripeProvider";
import { SelectedPaymentPlanInfoType } from "./type";

type CardDetailModalProps = {
    handleClose: () => void;
    planInfo: SelectedPaymentPlanInfoType;
    paymentComplete: () => void;
};
const CardDetailModal = ({ handleClose, planInfo, paymentComplete }: CardDetailModalProps) => {
    return (
        <Dialog open fullWidth maxWidth={"sm"} onClose={handleClose}>
            <Grid container p={"40px"}>
                <Grid item xs={12}>
                    <StripeElement
                        buttonText={`Pay $${Number(planInfo.credit) * planInfo.price} for ${planInfo.credit} Credits`}
                        credit={planInfo.credit}
                        paymentComplete={paymentComplete}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default CardDetailModal;
