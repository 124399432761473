import Style from "@emotion/styled";
import { greenColor, greyColor, optionTextColor, purpleColor, whiteColor } from "../../../../constants/Styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactPlayer from "react-player";

export const HeadPointsContainer = Style.div`
position: absolute;
top: 0%;
left: 50%;
transform: translate(-50%, -50%);
background: ${whiteColor};
padding: 5px 15px;
width: 60%;
border-radius: 55px;
`;

type PointsTextProps = {
    color?: string;
};

export const PointsText = Style.p<PointsTextProps>`
margin:0;
padding:0;
font-size: 16px;
color : ${(props) => props.color || greenColor};
font-weight: bold;
text-align: center;
@media (max-width: 360px) {
    font-size: 14px;
}
@media (max-width: 360px) {
    font-size: 12px;
}
`;

type StarPointsContainerProps = {
    isimage?: boolean;
};
export const StarPointsContainer = Style.div<StarPointsContainerProps>`
position: absolute;
${(props) =>
    props.isimage
        ? `
bottom: 10%;
left: 5%;
`
        : `
bottom: -32px;
right: 0%;
`}
background: ${greyColor};
border-radius: 10px;
padding: 2px;
color: ${whiteColor};
`;

type IconContainerProps = {
    bottom_pos: string;
};

export const IconContainer = Style.div<IconContainerProps>`
position: absolute;
bottom: ${(props) => props.bottom_pos};
right:5%;
color: ${whiteColor};
`;

type OptionBoxProps = {
    background: string;
};
export const OptionBox = Style.div`
width:100%;
background: ${(props: OptionBoxProps) => props.background};
border-radius: 30px;
padding: 12px 18px;
margin-bottom: 6px;
color: ${optionTextColor};
font-weight: bold;
`;

export const GreyCircle = Style.div`
display: flex;
justify-content: center;
align-items: center;
padding: 6px;
background-color: ${greyColor};
border-radius: 50%;
`;

export const FullScreenIconImage = Style.img`
width: 22.5px;
`;

export const FlexBox = Style.div`
display: flex;
align-items: center;
`;

export const StopWatchContainer = Style.div`
display: flex;
align-items: center;
border-left: 1px solid ${purpleColor};
padding-left: 10px;
`;

type StickyContainerProps = {
    iscoverimage?: number;
};
export const StickyContainer = Style.div<StickyContainerProps>`
display: grid;
place-items: center;
background: ${whiteColor};
border-radius: 35px;
border-bottom-right-radius: ${(props) => (props.iscoverimage ? "35px" : "0px")} !important;
border-bottom-left-radius: ${(props) => (props.iscoverimage ? "35px" : "0px")} !important;
padding: 15px;
`;

export const SkipQuestionText = Style.p`
margin:0;
padding:0;
color: ${whiteColor};
font-size: 16px;
text-decoration: underline;
font-weight: bold;`;

export const QuestionImage = Style.img`
width: 100%;
height: 100%;
border-radius: 35px;
`;

export const FullScreenImage = Style.img`

@media (min-width: 200px) and (max-width: 500px) {
    width: 100%;
  }
@media (min-width: 500px) and (max-width: 800px) {
    height: 100%;
  }
`;

export const FullScreenImageContainer = Style.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 50%);
    border-radius: 10px;
    @media (min-width: 500px) and (max-width: 800px) {
    height: calc(100vh - 89px);
    margin-top: 10px;
  }
  @media (min-width: 200px) and (max-width: 500px) {
    height: calc(100vh - 156px);
    margin-top: 20px;
  }
`;

type ArrowForwardIconProps = {
    selectionType?: "selected" | "unselected" | "loading";
    secondaryColor?: string;
    tertiaryColor?: string;
    primaryColor?: string;
};

export const ArrowForwardIconWrapper = Style(ArrowForwardIcon)`
  
color: ${(props: ArrowForwardIconProps) => `${props.selectionType === "selected" && "grey"}`};
color: ${(props: ArrowForwardIconProps) => `${props.selectionType === "unselected" && props.primaryColor}`};

  color: ${(props: ArrowForwardIconProps) => `${props.selectionType === "loading" && "grey"}`};
  font-size: 40px !important;
`;

type QuestionImageContainerProps = {
    hidebackground?: boolean;
};
export const QuestionImageContainer = Style.div<QuestionImageContainerProps>`
    position: relative;
    width: 100%;
    background: ${(props) => props.hidebackground && "#efefef"};
    aspect-ratio: 16/9;
    border-radius: 35px;
`;

export const StyledReactPlayer = Style(ReactPlayer)`
    iframe {
     border-radius: 5px !important;   
    }
`;
