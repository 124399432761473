import Cheers from "../../../Images/Wildgoat/celebration.svg";
import {
    WildGoatsCenterContainer,
    ContentBox,
    MainImage,
    MainText,
    WildGoatsContainerScroll,
    WildGoatsCorrectCenterContainer
} from "../CommonStyledComponents/CommonStyle";
import { ScoreText, DescriptionText } from "./Style";
import { FC } from "react";

interface CorrectAnswerProps {
    correctAnswerPoints: number;
    correctAnswerText: string;
    secondaryColor: string;
    correctAnswerHeading: string;
    correctAnswerImage: string;
}

const CorrectAnswer: FC<CorrectAnswerProps> = ({
    correctAnswerPoints,
    correctAnswerText,
    secondaryColor,
    correctAnswerHeading,
    correctAnswerImage
}) => {
    return (
        <WildGoatsCorrectCenterContainer>
            <WildGoatsContainerScroll>
                <WildGoatsCenterContainer>
                    <ContentBox style={{ margin: 0, overflow: "auto" }}>
                        <WildGoatsCenterContainer>
                            <MainImage src={correctAnswerImage || Cheers} alt="correctAnswer" />
                        </WildGoatsCenterContainer>
                        <MainText color={secondaryColor} style={{ fontSize: 24 }}>
                            {correctAnswerHeading || "Congratulations !"}
                        </MainText>
                        {correctAnswerPoints ? (
                            <ScoreText color={secondaryColor} style={{ fontSize: 20 }}>
                                {correctAnswerPoints} points.
                            </ScoreText>
                        ) : null}
                        <DescriptionText>
                            {correctAnswerText ? (
                                <span
                                    className={"quill-editor-text-align"}
                                    dangerouslySetInnerHTML={{
                                        __html: correctAnswerText
                                    }}
                                />
                            ) : (
                                `You have earned ${correctAnswerPoints} points that have been added to your achievements. It was a great adventure
                            for you. Let's buckle up for next destination. Adventure awaits you!`
                            )}
                        </DescriptionText>
                    </ContentBox>
                </WildGoatsCenterContainer>
            </WildGoatsContainerScroll>
        </WildGoatsCorrectCenterContainer>
    );
};

export default CorrectAnswer;
