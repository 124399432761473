import { IDropdownOption } from "@fluentui/react";
import {
    CONNECTOR,
    FILE_UPLOAD,
    FREE_TEXT,
    MCQ,
    QR_CODE
} from "../Component/Admin/v2/questionlibrary/questionInfo/Type";
import { OptionType } from "../Component/CommonComponent/v2/fields/Type";
import {
    IMAGE_QUESTION_TYPE,
    SCORE_PENALTY,
    TIMEOUT_PENALTY,
    UPLOAD_VIDEO_TYPE,
    VIDEO_QUESTION_TYPE,
    YOUTUBE_VIDEO_TYPE
} from "./SiteConstant";

export const freeTextAnswerTypeOptions = [
    { key: "contains", text: "Contains" },
    { key: "does_not_contain", text: "Does not contain" },
    { key: "exact_match", text: "Exact match" }
];

export const visibiltyTypeOptions = [
    { key: "global", text: "Global" },
    { key: "limited", text: "Limited" },
    { key: "private", text: "Private" }
];

export const mcqAnswerTypeOptions: IDropdownOption[] = [
    { key: "atleast_one_correct", text: "At least 1 correct" },
    { key: "atleast_2_correct", text: "At least 2 correct" },
    { key: "atleast_3_correct", text: "At least 3 correct" },
    { key: "atleast_4_correct", text: "At least 4 correct" },
    { key: "atleast_5_correct", text: "At least 5 correct" },
    { key: "all_correct", text: "All must be correct" }
];

export const getDropdownFilterOptions = (options: IDropdownOption[]): IDropdownOption[] => {
    return [{ key: -1, text: "All" }, ...options];
};

export const quizTypeDropdownOptions = [
    { key: "fullgame", text: "Virtual Unlinked" },
    { key: "simplesocket", text: "Virtual Linked" },
    { key: "wildgoat", text: "Wildgoat" }
];

export const statusDropdownOptions = [
    { key: "all", text: "All" },
    { key: "active", text: "Active" },
    { key: "deleted", text: "Deleted" }
];

export const ratingOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const onlineOptions = [
    { key: "yes", text: "Yes" },
    { key: "no", text: "No" }
];

export const EventTabsOption = [
    { label: "Upcoming", value: "upcoming" },
    { label: "Past", value: "past" },
    { label: "Archived", value: "archived" }
];

export const VisibiltyTypeOptions = [
    { value: "global", label: "Global" },
    { value: "limited", label: "Limited" },
    { value: "private", label: "Private" }
];

export const VuAnswerTypeOptions = [
    { value: MCQ, label: "Multiple choice" },
    { value: FREE_TEXT, label: "Free text" },
    { value: CONNECTOR, label: "Connector" },
    { value: FILE_UPLOAD, label: "Upload file" }
];

export const VuVideoAnswerTypeOptions = [
    { value: MCQ, label: "Multiple choice" },
    { value: FREE_TEXT, label: "Free text" },
    { value: FILE_UPLOAD, label: "Upload file" }
];

export const SocketAnswerTypeOptions = [
    { value: MCQ, label: "Multiple choice" },
    { value: FREE_TEXT, label: "Free text" },
    { value: FILE_UPLOAD, label: "Upload file" }
    // { value: QR_CODE, label: "QR Code" } TODO: OPEN This when qr code in admin is approved
];

export const QuestionTypeOptions = [
    { value: IMAGE_QUESTION_TYPE, label: "Image" },
    { value: VIDEO_QUESTION_TYPE, label: "Video" }
];

export const VideoTypeOptions = [
    { value: UPLOAD_VIDEO_TYPE, label: "Upload video" },
    { value: YOUTUBE_VIDEO_TYPE, label: "Paste Youtube video link" }
];

export const McqAnswerTypeOptions = [
    { value: "atleast_one_correct", label: "At least 1 correct" },
    { value: "atleast_2_correct", label: "At least 2 correct" },
    { value: "atleast_3_correct", label: "At least 3 correct" },
    { value: "atleast_4_correct", label: "At least 4 correct" },
    { value: "atleast_5_correct", label: "At least 5 correct" },
    { value: "all_correct", label: "All must be correct" }
];

export const TriggerTypeOptions = [{ value: "location", label: "Location" }];

export const FreeTextAnswerTypeOptions = [
    { value: "contains", label: "Contains" },
    { value: "does_not_contain", label: "Does not contain" },
    { value: "exact_match", label: "Exact match" }
];

export const QuestionTypeDropdownOptions: Record<string, OptionType[]> = {
    fullgame: VuAnswerTypeOptions,
    fullgameVideo: VuVideoAnswerTypeOptions,
    simplesocket: SocketAnswerTypeOptions,
    wildgoat: SocketAnswerTypeOptions
};

export const WrongAttemptPenaltyTypeOptions = [
    { value: SCORE_PENALTY, label: "Score penalty" },
    { value: TIMEOUT_PENALTY, label: "Timeout penalty" }
];

export const QuizTypeDropdownOptions = [
    { value: "virtualLinked", label: "Virtual Unlinked" },
    { value: "socketGame", label: "Virtual Linked" },
    { value: "wildgoatGame", label: "Physical" }
];

export const HintPenaltyTypeOptions = [
    {
        value: "score_penalty",
        label: "Score penalty"
    },
    {
        value: "timeout_penalty",
        label: "Timeout penalty"
    }
];

export const AttachmentOptions = [
    {
        value: "image",
        label: "Image"
    },
    {
        value: "file",
        label: "File"
    }
];

export const YesNoRadioOption = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" }
];

export const TriggerOption = [
    { value: "keyword", label: "Keyword" },
    { value: "score", label: "Score" }
];

export const AnsweroptionValue: Record<string, number> = {
    atleast_1_correct: 1,
    atleast_2_correct: 2,
    atleast_3_correct: 3,
    atleast_4_correct: 4,
    atleast_5_correct: 5
};

export const BranchQuestionTabOption = [
    { value: "quiz", label: "Quiz", toShow: true },
    { value: "question", label: "Question", toShow: true }
];

export const CreditOption: OptionType[] = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
    { value: "30", label: "30" }
];
