import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { AnsweroptionValue } from "../../../../../constants/DropdownOption";
import { SiteConstants, VIDEO_QUESTION_TYPE } from "../../../../../constants/SiteConstant";
import { getPermissions } from "../../../../../utils/PermissionUtils";
import { scrollTo } from "../../../../../utils/JambarUtils";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { dropDownType } from "../../../EventManager/EventDetails/utils/type";
import { UsageListType } from "../../gamelibrary/Type";
import { MCQ } from "../questionInfo/Type";
import { QuestionDetailType } from "./Type";
import {
    addVideoQuestion,
    deleteCoverImage,
    fetchUsageListAction,
    getAllIdentitiesList,
    getQuestionInfo,
    getTagsDropdownOptions,
    saveQuestion,
    updateQuestion
} from "./Util";
import { useFormikContext } from "formik";
import { useUserInfo } from "../../../../../commonhooks/Hooks";

export const useQuestionIdentificationFormHook = () => {
    const [tagsOptions, setTagsOptions] = useState<dropDownType[]>([]);
    const { isSuperAdmin, userDetail } = useUserInfo();
    const [identityDropdownOptions, setIdentityDropdownOptions] = useState<OptionType[]>([]);
    const [openTagModal, setTagOpenModal] = useState(false);
    const { setFieldValue, values, errors } = useFormikContext();
    const data = values as QuestionDetailType;
    const formErrors: any = errors;

    const permission: string[] | null = getPermissions(userDetail, "tag");
    const { gameid } = useParams<{
        gameid: string;
    }>();

    useEffect(() => {
        fetchTagsDropdownData();
        fetchIdentityDropdowndownData();
    }, []);

    const fetchIdentityDropdowndownData = () => {
        getAllIdentitiesList(true)
            .then((res) => setIdentityDropdownOptions(res))
            .catch(() => setIdentityDropdownOptions([]));
    };

    const fetchTagsDropdownData = (forceRefresh = false): void => {
        if (!(permission && permission.includes("fetch"))) {
            setTagsOptions([]);
            return;
        }
        getTagsDropdownOptions(forceRefresh)
            .then((data) =>
                setTagsOptions(data.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)))
            )
            .catch((error) => {
                setTagsOptions([]);
                toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
            });
    };

    const handleOptionChange = (selectedTags: dropDownType[], newTag: dropDownType) => {
        if (selectedTags.find((item) => item.value === newTag.value)) {
            setFieldValue(
                "questionIdentification.tags",
                selectedTags.filter((item) => item.value !== newTag.value)
            );
            return;
        }
        setFieldValue("questionIdentification.tags", [...selectedTags, newTag]);
    };

    return {
        handleOptionChange,
        gameid: gameid || data.questionIdentification.gameType,
        tagsOptions,
        userDetail,
        identityDropdownOptions,
        setTagOpenModal,
        openTagModal,
        fetchTagsDropdownData,
        formErrors,
        setFieldValue,
        data,
        isSuperAdmin
    };
};

export const useQuestionDetailHook = (selectedQuestionId?: string) => {
    const [questionDetails, setQuestionDetails] = useState<QuestionDetailType>();
    const [isLoading, setIsLoading] = useState(false);

    const { gameid, questionid } = useParams<{
        gameid: string;
        questionid: string;
    }>();

    useEffect(() => {
        if (questionid || selectedQuestionId) {
            setIsLoading(true);
            getQuestionInfo(questionid || selectedQuestionId || "")
                .then((res) => {
                    setQuestionDetails(res);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    return {
        gameId: gameid,
        isLoading,
        questionDetails,
        questionId: questionid
    };
};

export const useAddQuestionHook = (questionId?: string, refreshList?: () => void, handleClose?: () => void) => {
    const history = useHistory();
    const [openSaveQuestionModal, setOpenSaveQuestionModal] = useState<number | null>(null);
    const [usageList, setUsageList] = useState<UsageListType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo") || 1;

    useEffect(() => {
        openSaveQuestionModal && fetchUsageList();
    }, [openSaveQuestionModal]);

    const fetchUsageList = () => {
        setIsLoading(true);
        questionId &&
            fetchUsageListAction(questionId)
                .then((res: UsageListType[]) => {
                    setUsageList(res);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const removeCoverImage = (): void => {
        if (!questionId) {
            return;
        }
        deleteCoverImage(questionId).then(() => {
            toast.success("Cover image removed", SiteConstants.successToastConfiguration);
        });
    };

    const handleQuestionSubmit = (value: QuestionDetailType, setSubmitting: (isSubmitting: boolean) => void) => {
        if (value.answerDetails.answerType === MCQ) {
            const totalCorrectAnswer = value.answerDetails.mcq.mcqAnswerOptions.filter((item) => item.isCorrect).length;
            if (value.answerDetails.mcq.correctAnswerFrequency === "all_correct") {
                if (totalCorrectAnswer !== value.answerDetails.mcq.mcqAnswerOptions.length) {
                    toast.error("Please select all options as correct ", SiteConstants.deleteToastConfiguration);
                    scrollTo("mcqview");
                    setSubmitting(false);
                    setOpenSaveQuestionModal(null);
                    return;
                }
            }
            if (totalCorrectAnswer < AnsweroptionValue[value.answerDetails.mcq.correctAnswerFrequency]) {
                scrollTo("mcqview");
                toast.error(
                    `Please select at least ${
                        AnsweroptionValue[value.answerDetails.mcq.correctAnswerFrequency]
                    } correct option`,
                    SiteConstants.deleteToastConfiguration
                );
                setSubmitting(false);
                setOpenSaveQuestionModal(null);
                return;
            }
        }
        if (questionId) {
            updateQuestion(questionId, value)
                .then(() => {
                    if (value.questionDetails.uploadVideo && typeof value.questionDetails.uploadVideo !== "string") {
                        addVideoQuestion(parseInt(questionId), value)
                            .then(() => {
                                history.push(`/admin/v2/questionlibrary/${questionId}/questionInfo?pageNo=${pageNo}`);
                                toast.success("Challenge updated", SiteConstants.successToastConfiguration);
                                setSubmitting(false);
                                refreshList && refreshList();
                                handleClose && handleClose();
                            })
                            .catch((error) => {
                                toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
                                setSubmitting(false);
                            });
                        return;
                    }
                    refreshList
                        ? refreshList()
                        : history.push(`/admin/v2/questionlibrary/${questionId}/questionInfo?pageNo=${pageNo}`);
                    toast.success("Challenge updated", SiteConstants.successToastConfiguration);
                    handleClose && handleClose();
                    setSubmitting(false);
                })
                .catch((error) => {
                    toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
                    setSubmitting(false);
                });
            return;
        }

        saveQuestion(value)
            .then((res: number) => {
                if (value.questionDetails.questionType === VIDEO_QUESTION_TYPE && value.questionDetails.uploadVideo) {
                    addVideoQuestion(res, value)
                        .then(() => {
                            history.push("/admin/v2/questionlibrary");
                            toast.success("Challenge added", SiteConstants.successToastConfiguration);
                        })
                        .catch((error) =>
                            toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration)
                        );
                    return;
                }
                history.push("/admin/v2/questionlibrary");
                toast.success("Challenge added", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setSubmitting(false));
    };

    return {
        handleQuestionSubmit,
        removeCoverImage,
        setOpenSaveQuestionModal,
        usageList,
        isLoading,
        openSaveQuestionModal,
        pageNo
    };
};
