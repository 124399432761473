import { Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../constants/SiteConstant";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import { useAdminContext } from "../context/AdminAuthContext";
import { changePassword } from "./Util";
import { Box, CircularProgress, Grid } from "@mui/material";
import FormikTextInput from "../../CommonComponent/v2/fields/FormikTextInput";
import PasswordValidator from "../../CommonComponent/v2/PasswordValidator";
import { ChangePasswordValidationSchema } from "../register/util";
import { PrimaryButton } from "../../CommonComponent/v2/CommonStyle";

const ChangePasswordView = () => {
    const [loading, setLoading] = useState(false);

    const { onLogout } = useAdminContext();

    const updatePassword = (value: { currentPassword: string; password: string }) => {
        setLoading(true);
        changePassword(value.currentPassword, value.password)
            .then(() => {
                toast.success("Password changed", SiteConstants.successToastConfiguration);
                onLogout();
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Grid container justifyContent={"center"} spacing={"15px"} mt={"30px"}>
            <Grid item xs={4} border={"1px #efefef solid"} p={"20px"} borderRadius={"3px"}>
                <Formik
                    initialValues={{
                        currentPassword: "",
                        password: "",
                        confirmPassword: ""
                    }}
                    validationSchema={ChangePasswordValidationSchema}
                    onSubmit={updatePassword}
                    validateOnMount={false}
                    enableReinitialize>
                    {({ values, handleSubmit }) => {
                        return (
                            <Grid container rowSpacing={"20px"}>
                                <Grid item xs={12}>
                                    <FormikTextInput
                                        id={"currentPassword"}
                                        name={"currentPassword"}
                                        textfieldType={"password"}
                                        label={"Current Password"}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikTextInput
                                        id={"password"}
                                        name={"password"}
                                        label={"Password"}
                                        textfieldType={"password"}
                                        fullWidth
                                    />
                                    <Box mt={"10px"}>
                                        <PasswordValidator value={values.password} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormikTextInput
                                        id={"confirmPassword"}
                                        name={"confirmPassword"}
                                        textfieldType={"password"}
                                        label={"Confirm Password"}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container justifyContent={"center"}>
                                        <PrimaryButton
                                            style={{
                                                height: 50,
                                                width: 182,
                                                padding: 16
                                            }}
                                            fontsize="16px"
                                            startIcon={loading && <CircularProgress />}
                                            disabled={loading}
                                            onClick={() => handleSubmit()}>
                                            Reset Password
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    }}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default ChangePasswordView;
