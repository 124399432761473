import { Divider, Grid } from "@mui/material";
import { FC } from "react";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { CaptionText } from "../../../../CommonComponent/v2/CommonStyle";
import { ColorIcon } from "./Style";
import { GameTemplateType } from "./Type";

type GameTemplateProps = {
    gameTemplate: GameTemplateType;
    gameType: string;
};

const GameTemplate: FC<GameTemplateProps> = ({ gameTemplate, gameType }) => {
    const renderImage = () => {
        if (gameType === SiteConstants.socketQuiz) {
            return (
                <>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Game screen background image
                        </CaptionText>
                        {
                            gameTemplate.gameScreenBackgroundImage &&
                            <Grid mt={"10px"}>
                                <img
                                    width={"100%"}
                                    style={{
                                        height: 250,
                                        objectFit: "cover",
                                        borderRadius: 20
                                    }}
                                    src={gameTemplate.gameScreenBackgroundImage}
                                    alt="Image"
                                />
                            </Grid>
                        }
                    </Grid>
                </>
            );
        }

        if (gameType === SiteConstants.wildgoat) {
            return (
                <>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Correct answer image
                        </CaptionText>
                        {
                            gameTemplate.widlgoatGameTemplate.correctAnswerImage &&
                            <Grid mt={"10px"}>
                                <img
                                    width={"100%"}
                                    style={{
                                        height: 250,
                                        objectFit: "cover",
                                        borderRadius: 20
                                    }}
                                    src={gameTemplate.widlgoatGameTemplate.correctAnswerImage}
                                    alt="Image"
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Wrong answer image
                        </CaptionText>
                        {
                            gameTemplate.widlgoatGameTemplate.wrongAnswerImage && <Grid mt={"10px"}>
                                <img
                                    width={"100%"}
                                    style={{
                                        height: 250,
                                        objectFit: "cover",
                                        borderRadius: 20
                                    }}
                                    src={gameTemplate.widlgoatGameTemplate.wrongAnswerImage}
                                    alt="Image"
                                />
                            </Grid>
                        }
                    </Grid>
                </>
            );
        }

        if (gameType === SiteConstants.spaceGameQuiz) {
            return (
                <>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Background image
                        </CaptionText>
                        {
                            gameTemplate.gameScreenBackgroundImage &&
                            <Grid mt={"10px"}>
                                <img
                                    width={"100%"}
                                    style={{
                                        height: 250,
                                        objectFit: "cover",
                                        borderRadius: 20
                                    }}
                                    src={gameTemplate.gameScreenBackgroundImage}
                                    alt="Image"
                                />
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Progress image
                        </CaptionText>
                        {
                            gameTemplate.progressImage &&
                            <Grid mt={"10px"}>
                                <img
                                    width={"100%"}
                                    style={{
                                        height: 250,
                                        objectFit: "cover",
                                        borderRadius: 20
                                    }}
                                    src={gameTemplate.progressImage}
                                    alt="Image"
                                />
                            </Grid>
                        }
                    </Grid>
                </>
            );
        }
        return null;
    };

    return (
        <Grid container spacing={"30px"}>
            <Grid item xs={4}>
                <Grid container spacing={"20px"}>
                    {renderImage()}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    {gameType === SiteConstants.spaceGameQuiz && (
                        <>
                            <Grid item xs={12}>
                                <CaptionText color={"#333333"} fontWeight={700}>
                                    Progress title
                                </CaptionText>
                                <CaptionText
                                    gap={"10px"}
                                    display={"flex"}
                                    dangerouslySetInnerHTML={{
                                        __html: gameTemplate.proressTitle
                                    }}
                                />
                            </Grid>
                            <Grid my={"20px"} item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    )}
                    {gameType === SiteConstants.wildgoat && (
                        <>
                            <Grid item xs={12}>
                                <CaptionText color={"#333333"} fontWeight={700}>
                                    Correct Answer Heading
                                </CaptionText>
                                <CaptionText
                                    gap={"10px"}
                                    display={"flex"}
                                    dangerouslySetInnerHTML={{
                                        __html: gameTemplate.widlgoatGameTemplate.correctAnswerHeading
                                    }}
                                />
                            </Grid>
                            <Grid my={"20px"} item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <CaptionText color={"#333333"} fontWeight={700}>
                                    Wrong Answer Heading
                                </CaptionText>
                                <CaptionText
                                    gap={"10px"}
                                    display={"flex"}
                                    dangerouslySetInnerHTML={{
                                        __html: gameTemplate.widlgoatGameTemplate.wrongAnswerHeading
                                    }}
                                />
                            </Grid>
                            <Grid my={"20px"} item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    )}
                    {gameType !== SiteConstants.wildgoat && (
                        <>
                            <Grid item xs={12}>
                                <CaptionText color={"#333333"} fontWeight={700}>
                                    Attachment section title
                                </CaptionText>
                                <CaptionText
                                    gap={"10px"}
                                    display={"flex"}
                                    dangerouslySetInnerHTML={{
                                        __html: gameTemplate.attachemntSectionTitle
                                    }}
                                />
                            </Grid>
                            <Grid my={"20px"} item xs={12}>
                                <Divider />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Browser tab title
                        </CaptionText>
                        <CaptionText gap={"10px"} display={"flex"}>
                            {gameTemplate.browserTabTitle}
                        </CaptionText>
                    </Grid>
                    <Grid my={"20px"} item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Colors
                        </CaptionText>
                        {gameType !== SiteConstants.wildgoat && (
                            <>
                                <Grid container spacing={"10px"}>
                                    <Grid item mt={"10px"} xs={12}>
                                        <CaptionText gap={"10px"} display={"flex"}>
                                            <span>Background color:</span>
                                            <ColorIcon backgroundcolor={gameTemplate.colors.backgroundColor} />
                                            <span style={{ color: "#333333" }}>
                                                {gameTemplate.colors.backgroundColor}
                                            </span>
                                        </CaptionText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CaptionText gap={"10px"} display={"flex"}>
                                            <span>Button color:</span>
                                            <ColorIcon backgroundcolor={gameTemplate.colors.buttonColor} />
                                            <span style={{ color: "#333333" }}>{gameTemplate.colors.buttonColor}</span>
                                        </CaptionText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CaptionText gap={"10px"} display={"flex"}>
                                            <span>Correct answer background color:</span>
                                            <ColorIcon
                                                backgroundcolor={gameTemplate.colors.correctAnswerBackgroundColor}
                                            />
                                            <span style={{ color: "#333333" }}>
                                                {gameTemplate.colors.correctAnswerBackgroundColor}
                                            </span>
                                        </CaptionText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CaptionText gap={"10px"} display={"flex"}>
                                            <span>Wrong answer background color</span>
                                            <ColorIcon
                                                backgroundcolor={gameTemplate.colors.wrongAnswerBackgroundColor}
                                            />
                                            <span style={{ color: "#333333" }}>
                                                {gameTemplate.colors.wrongAnswerBackgroundColor}
                                            </span>
                                        </CaptionText>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {gameType === SiteConstants.wildgoat && (
                            <Grid container spacing={"10px"}>
                                <Grid mt={"20px"} item xs={12}>
                                    <CaptionText gap={"10px"} display={"flex"}>
                                        <span>Primary color</span>
                                        <ColorIcon backgroundcolor={gameTemplate.widlgoatGameTemplate.primaryColor} />
                                        <span style={{ color: "#333333" }}>
                                            {gameTemplate.widlgoatGameTemplate.primaryColor}
                                        </span>
                                    </CaptionText>
                                </Grid>
                                <Grid item xs={12}>
                                    <CaptionText gap={"10px"} display={"flex"}>
                                        <span>Secondary color</span>
                                        <ColorIcon backgroundcolor={gameTemplate.widlgoatGameTemplate.secondaryColor} />
                                        <span style={{ color: "#333333" }}>
                                            {gameTemplate.widlgoatGameTemplate.secondaryColor}
                                        </span>
                                    </CaptionText>
                                </Grid>
                                <Grid item xs={12}>
                                    <CaptionText gap={"10px"} display={"flex"}>
                                        <span>Tertiary color</span>
                                        <ColorIcon backgroundcolor={gameTemplate.widlgoatGameTemplate.tertiaryColor} />
                                        <span style={{ color: "#333333" }}>
                                            {gameTemplate.widlgoatGameTemplate.tertiaryColor}
                                        </span>
                                    </CaptionText>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GameTemplate;
