import { useState } from "react";
import { PlanMessage, PlanTitle, PlansCardContainer } from "./Style";
import { Grid } from "@mui/material";
import { PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import SelectWrapper from "../../../CommonComponent/v2/fields/SelectWrapper";
import { CreditOption } from "../../../../constants/DropdownOption";

type BasicPlanCardViewProps = {
    handlePaymentClick: (credit: string) => void;
};
const BasicPlanCardView = ({ handlePaymentClick }: BasicPlanCardViewProps) => {
    const [selectedCredit, setSelectedCredit] = useState<string>("10");

    return (
        <PlansCardContainer>
            <Grid item xs={12}>
                <PlanTitle>Buy Credits</PlanTitle>
                <PlanMessage>
                    Select the number of credits you would like to purchase. To generate event codes, you will need one
                    credit for each event code.
                </PlanMessage>
            </Grid>
            <Grid item xs={12}>
                <SelectWrapper options={CreditOption} value={selectedCredit} handleChange={setSelectedCredit} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <PrimaryButton
                    fullWidth
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => handlePaymentClick(selectedCredit)}
                    style={{ textTransform: "none" }}>
                    Buy Now
                </PrimaryButton>
            </Grid>
        </PlansCardContainer>
    );
};

export default BasicPlanCardView;
