import { Stack, Spinner } from "@fluentui/react";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router";
import { ApiConstants } from "../../constants/ApiConstant";
import { FieldValidation } from "../../constants/FieldValidation";
import { SocketGame, WildGoatGame } from "../../constants/SiteConstant";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../constants/Styles";
import { Client } from "../Base/Client";
import logo from "../Images/logo.svg";
import { cacheImages } from "../WebsiteGame/util";
import "./EventLoginView.scss";
import LobbyView from "./Lobby/LobbyView";
import InitialSetup from "./SetupWizard/InitialSetup";
import TeamNameModal from "./TeamNameModal";

const EventLoginView = () => {
    const [loading, setLoading] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [participantName, setParticipantName] = useState("");
    const [code, setCode] = useState("");
    const [event, setEvent] = useState("");
    const [loginFailed, setLoginFailed] = useState(false);
    const [showTeamNameErrorMessage, setShowTeamNameErrorMessage] = useState(false);
    const [showCodeErrorMessage, setShowCodeErrorMessage] = useState(false);
    const [isGameSocket, setIsSocketGame] = useState(false);
    const [showTeamNameModal, setShowTeamNameModal] = useState(false);
    const [isGameDemo, setIsGameDemo] = useState(false);
    const [showInitialSetupWizard, setShowInitialSetupWizard] = useState(false);
    const [openLobby, setOpenLobby] = useState(false);
    const [gameId, setGameId] = useState({
        quizId: "",
        eventId: ""
    });
    const [saveTeamNameLoading, setSaveTeamNameLoading] = useState(false);

    const history = useHistory();

    const fetchEvent = (eventLink: string): void => {
        Client.getInstance()
            .getData(ApiConstants.getEventDataApiUrl(eventLink))
            .then((res: any) => {
                if (res.data.data.event.quizType === SocketGame) {
                    setIsSocketGame(true);
                }
                Client.getInstance()
                    .getData(ApiConstants.getEventImages(eventLink), true)
                    .then((res) => cacheImages([res.data.data, "../Images/Wildgoat/Skip.svg"]));
                if (res.data.data.event.quizType === WildGoatGame) {
                    history.push(`/wildgoat/codeinput`, {
                        eventLink: eventLink,
                        data: res.data.data.event,
                        quiz: res.data.data.quiz
                    });
                    return;
                }
                if (res.data.data.event.isDemo) {
                    setIsGameDemo(true);
                    setCode(FieldValidation.generateAlphaNumericCode());
                }
                setLoading(false);
                setShowInitialSetupWizard(true);
                setShowLogin(true);
            })
            .catch(() => {
                setLoading(false);
                setShowLogin(false);
            });
    };

    const fetchEventId = (): string => {
        const url = window.location.href;
        const urlSplit = url.split("/");
        fetchEvent(urlSplit[urlSplit.length - 1]);
        setEvent(urlSplit[urlSplit.length - 1]);
        return urlSplit[urlSplit.length - 1];
    };

    useEffect(() => {
        fetchEventId();
    }, []);

    const openQuiz = (): void => {
        if (!isGameSocket && teamName === "") {
            setShowTeamNameErrorMessage(true);
            return;
        }
        if (isGameSocket && participantName === "") {
            setShowTeamNameErrorMessage(true);
            return;
        }
        if (code === "") {
            setShowCodeErrorMessage(true);
            return;
        }

        setLoginLoading(true);
        if (isGameSocket) {
            Client.getInstance()
                .getData(ApiConstants.getTeamNameApiUrl(event, code), true)
                .then((res) => {
                    if (!res.data.data.teamName) {
                        setShowTeamNameModal(true);
                        return;
                    }
                    setTeamName(res.data.data.teamName);
                    loginEvent();
                })
                .catch(() => setLoginFailed(true))
                .finally(() => setLoginLoading(false));
            return;
        }
        loginEvent();
    };

    const startEvent = () => {
        if (isGameSocket) {
            history.push("/socket", {
                code: code,
                eventLink: event,
                eventId: gameId.eventId,
                quizId: gameId.quizId,
                teamName: teamName,
                participantName: participantName
            });
            return;
        }
        history.push("/participant/quiz", {
            code: code,
            eventLink: event,
            quizId: gameId.quizId,
            teamName: teamName,
            eventId: gameId.eventId
        });
    };

    const loginEvent = () => {
        Client.getInstance()
            .getData(ApiConstants.validateParticipantCode(event, code))
            .then((response) => {
                setGameId({
                    quizId: response.data.data.quiz.id,
                    eventId: response.data.data.event.id
                });
                setOpenLobby(true);
                setShowTeamNameModal(false);
            })
            .catch(() => {
                setLoginFailed(true);
            })
            .finally(() => setLoginLoading(false));
    };

    const saveTeamName = (teamName: string) => {
        setTeamName(teamName);
        setSaveTeamNameLoading(true);
        Client.getInstance()
            .updateData(ApiConstants.addTeamNameApiUrl(event, code), {
                teamName: teamName
            })
            .then(() => {
                loginEvent();
            })
            .finally(() => {
                setSaveTeamNameLoading(false);
            });
    };

    if (!loading && showInitialSetupWizard) {
        return <InitialSetup closeSetup={() => setShowInitialSetupWizard(false)} />;
    }

    if (openLobby) {
        return (
            <div className={"lobby-main-container"}>
                <Stack horizontalAlign={"center"} verticalAlign={"center"} className={"height-100per"}>
                    <Stack className={"event-parent"} tokens={ThemedMediumStackTokens}>
                        <LobbyView handleSubmit={startEvent} />
                    </Stack>
                </Stack>
            </div>
        );
    }

    if (!loading) {
        return (
            <div className={"event-main-container"}>
                <Stack horizontalAlign={"center"} verticalAlign={"center"} className={"height-100per"}>
                    <Stack className={"event-parent"} tokens={ThemedMediumStackTokens}>
                        <Stack horizontalAlign={"center"} className={"event-logo"}>
                            <img src={logo} />
                        </Stack>
                        <Stack tokens={ThemedMediumStackTokens} className={"event-child"}>
                            <Stack tokens={ThemedSmall2StackTokens}>
                                <span className={"font-size-20 font-weight-bold"}>Hello,</span>
                                <span className={"font-size-20"}>Welcome to Jambar's Virtual Team Building Event</span>
                                {showLogin ? (
                                    <span className={"font-size-16"}>
                                        Please enter your team name & game code to continue
                                    </span>
                                ) : (
                                    <span className={"text-align-center font-size-16 error-message-font-color"}>
                                        Your event hasn’t started yet, please check back on your event day
                                    </span>
                                )}
                            </Stack>
                            <br />
                            {showLogin ? (
                                <Stack tokens={ThemedSmall2StackTokens}>
                                    <Stack.Item>
                                        <span className={"font-size-16"}>
                                            {isGameSocket ? "Enter participant name" : "Enter team name"}
                                        </span>
                                        {isGameSocket ? (
                                            <InputGroup className={participantName === "" ? undefined : "mb-3"}>
                                                <FormControl
                                                    required
                                                    onChange={(event) => setParticipantName(event.target.value)}
                                                    onFocus={() => setShowTeamNameErrorMessage(false)}
                                                />
                                            </InputGroup>
                                        ) : (
                                            <InputGroup className={teamName === "" ? undefined : "mb-3"}>
                                                <FormControl
                                                    required
                                                    onChange={(event) => setTeamName(event.target.value)}
                                                    onFocus={() => setShowTeamNameErrorMessage(false)}
                                                />
                                            </InputGroup>
                                        )}

                                        <span className={"error-message"}>
                                            {showTeamNameErrorMessage &&
                                                (isGameSocket
                                                    ? "Please enter your name"
                                                    : "Please enter your team name")}
                                        </span>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <span className={"font-size-16"}>Enter Code</span>
                                        <InputGroup className={code === "" ? undefined : "mb-3"}>
                                            <FormControl
                                                required
                                                onFocus={() => {
                                                    setLoginFailed(false);
                                                    setShowCodeErrorMessage(false);
                                                }}
                                                disabled={isGameDemo}
                                                value={code}
                                                onChange={(event) => setCode(event.target.value)}
                                            />
                                        </InputGroup>
                                        <span className={"error-message"}>
                                            {showCodeErrorMessage && "Please enter Code"}
                                        </span>
                                        {loginFailed && (
                                            <span className={"error-message"}>Please enter a valid Code</span>
                                        )}
                                    </Stack.Item>
                                </Stack>
                            ) : null}

                            <Stack horizontal horizontalAlign={"space-between"}>
                                {showLogin ? (
                                    <Button
                                        disabled={loginLoading}
                                        className={"login-button"}
                                        onClick={() => openQuiz()}>
                                        {loginLoading ? <Spinner /> : "Login"}
                                    </Button>
                                ) : null}

                                <Button
                                    className={"get-help-button"}
                                    onClick={() =>
                                        window.open("https://jambarteambuilding.com/contact-us-now", "_blank")
                                    }>
                                    Get Help
                                </Button>
                            </Stack>
                            <Stack.Item className={"text-align-center"}>
                                <span>
                                    By logging in, you agree to the{" "}
                                    <a href={"https://jambarteambuilding.com/contact-us-now"} target={"_blank"}>
                                        terms and conditions
                                    </a>{" "}
                                    of using our service
                                </span>
                            </Stack.Item>
                            {showTeamNameModal && (
                                <TeamNameModal
                                    onCloseModal={() => setShowTeamNameModal(false)}
                                    saveTeamName={saveTeamName}
                                    isLoading={saveTeamNameLoading}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        );
    }

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
            <Spinner className={"loading-component"} label="I am loading..." />
        </Grid>
    );
};

export default EventLoginView;
