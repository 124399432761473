import { Grid } from "@mui/material";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import QuestionList from "./QuestionList";
import { useQuestionLibrary } from "./Hooks";
import { getFilteredQuestionList } from "./Utils";
import { optionArray } from "../../../../constants/SiteConstant";
import { HeaderTextUi } from "./HeaderTextUI";
import GameTypeModal from "./addquestion/GameTypeModal";
import { gameOptions } from "../eventmanager/addeventdetail/Util";

const QuestionLibrary = () => {
    const {
        searchText,
        tagOptions,
        cloneId,
        questionList,
        isLoading,
        deleteId,
        selectedTagOption,
        selectedStatusOption,
        handleResetFilter,
        confirmDelete,
        setSelectedStatusOption,
        setSelectedTagOption,
        setSearchText,
        setDeleteId,
        setViewId,
        setCloneId,
        confirmClone,
        openAddModal,
        toggleAddModal,
        permission,
        fetchData,
        permissionQuizFullGame,
        permissionQuizSocketGame,
        permissionQuizPhysicalGame,
        restoreId,
        setRestoreId,
        confirmRestore
    } = useQuestionLibrary();

    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton={!!(permission && permission.includes("add"))}
                    onButtonClick={toggleAddModal}
                    buttonLabel={"Add new challenge"}
                    filterOptions={[
                        {
                            value: selectedStatusOption,
                            options: optionArray,
                            setValue: setSelectedStatusOption,
                            label: "Filter by status"
                        },
                        {
                            value: selectedTagOption,
                            options: tagOptions,
                            setValue: setSelectedTagOption,
                            label: "Filter by tag"
                        }
                    ]}
                    resetFilter={
                        <HeaderTextUi
                            value={questionList.length}
                            label={"Clear Filters"}
                            setValue={handleResetFilter}
                        />
                    }
                />
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <QuestionList
                    setCloneId={setCloneId}
                    setDeleteId={setDeleteId}
                    setViewId={setViewId}
                    questionList={getFilteredQuestionList(questionList, selectedTagOption, searchText)}
                    isLoading={isLoading}
                    refreshList={fetchData}
                    setRestoreId={setRestoreId}
                    selectedStatus={selectedStatusOption}
                />
            </Grid>
            {deleteId && (
                <ConfirmationBox
                    title={`Delete "${deleteId.title}" challenge`}
                    message={"Please confirm if you really want to delete this challenge"}
                    onConfirm={confirmDelete}
                    onClose={() => setDeleteId(null)}
                />
            )}

            {cloneId && (
                <ConfirmationBox
                    title={`Duplicate "${cloneId.title}" challenge`}
                    message={"Please confirm if you really want to duplicate this challenge"}
                    onConfirm={confirmClone}
                    type={"clone"}
                    onClose={() => setCloneId(null)}
                />
            )}

            {restoreId && (
                <ConfirmationBox
                    title={`Restore "${restoreId.title}" challenge`}
                    message={"Please confirm if you really want to restore this challenge"}
                    onConfirm={confirmRestore}
                    type={"clone"}
                    onClose={() => setRestoreId(null)}
                />
            )}
            {openAddModal && (
                <GameTypeModal
                    heading="Type of game for the challenge"
                    subHeading="Please select the game type that this challenge is relevant to."
                    type="questionlibrary"
                    handleClose={toggleAddModal}
                    gameOptions={gameOptions(
                        permissionQuizFullGame?.includes("add"),
                        permissionQuizSocketGame?.includes("add"),
                        permissionQuizPhysicalGame?.includes("add")
                    )}
                />
            )}
        </Grid>
    );
};
export default QuestionLibrary;
