import { intersection } from "lodash";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { Client } from "../../../Base/Client";
import { OptionType } from "../../../CommonComponent/v2/fields/Type";
import { IdentityListtype, UserListType } from "./Type";
import { errorLightColor } from "../../../../constants/ColorConstant";
import { number, object } from "yup";

export const entriesPerPage: OptionType[] = [
    { label: "10", value: "10" },
    { label: "20", value: "20" },
    { label: "30", value: "30" }
];

const tranformData = (data: any): UserListType[] => {
    if (!data) {
        return [];
    }
    return data.map(
        (item: any) =>
            ({
                id: item.id,
                email: item.email,
                firstName: item.firstName,
                lastName: item.lastName,
                identityName: item.identity.name,
                identityId: item.identity.id,
                identityCredit: item.identity.credits,
                subscriptionStatus: item.identity.subscriptionStatus
            } as UserListType)
    );
};

const identitytranformData = (data: any): IdentityListtype[] => {
    if (!data) {
        return [];
    }
    return data.map(
        (item: any) =>
            ({
                id: item.id,
                createdAt: JambarDateUtil.formatDate(item.createdAt),
                name: item.name,
                updatedAt: JambarDateUtil.formatDate(item.updatedAt),
                createdBy: item.createdBy,
                credits: item.credits,
                subscriptionStatus: item.subscriptionStatus
            } as IdentityListtype)
    );
};

export const getAllUserList = (forceRefresh = false): any =>
    Client.getInstance()
        .getData(ApiConstants.getAllUsersList(), forceRefresh)
        .then((res) => Promise.resolve(tranformData(res.data.message)))
        .catch((err) => Promise.reject(err));

export const getAllIdentitiesList = (forceRefresh = false): any =>
    Client.getInstance()
        .getData(ApiConstants.getAllIdentitiesList(), forceRefresh)
        .then((res) => Promise.resolve(identitytranformData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getIdentityListFilteredItems = (list: IdentityListtype[], searchText: string): any =>
    list.filter(
        (item: IdentityListtype) => searchText === "" || includeInFilteredItems(searchText.toLowerCase(), item)
    );

const includeInFilteredItems = (searchText: string, item: IdentityListtype): boolean => {
    if (item.name.toLowerCase().includes(searchText)) {
        return true;
    }

    return false;
};

export const getUserListFilteredItems = (list: UserListType[], searchText: string): any => {
    const trimSearchTextArray = searchText.trim().toLowerCase().split(" ");
    if (searchText === "") {
        return list;
    }
    return list.filter(
        (row: UserListType) =>
            intersection(row.email.trim().toLowerCase().split(" "), trimSearchTextArray).length ===
                trimSearchTextArray.length ||
            intersection(row.identityName.trim().toLowerCase().split(" "), trimSearchTextArray).length ===
                trimSearchTextArray.length ||
            intersection(row.firstName.trim().toLowerCase().split(" "), trimSearchTextArray).length ===
                trimSearchTextArray.length ||
            intersection(row.lastName.trim().toLowerCase().split(" "), trimSearchTextArray).length ===
                trimSearchTextArray.length
    );
};

export const getDeleteUser = (userId: number | null, ownerId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteUserApiUrl(userId || -1, ownerId))
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const deleteIdentity = (id: number): any =>
    Client.getInstance()
        .deleteData(ApiConstants.updateIdentitiyApiUrl(id))
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const updateIdentityCredit = (identity: IdentityListtype, credits: number): any =>
    Client.getInstance()
        .createData(ApiConstants.increaseCreditApiUrl(), {
            credits,
            identityId: identity.id
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const getIdentifySubscriptionStatusColor = (status: string) => {
    if (status === "Paid") {
        return "#65B44A";
    }
    if (status === "Unpaid") {
        return errorLightColor;
    }
    return "#828282";
};

export const updateCreditValidationSchema = object().shape({
    credit: number()
        .typeError("Please enter number")
        .max(100, "Credit should not be more then 100")
        .required("Credit is mandatory")
});
