import { differenceBy } from "lodash";
import brokenImage from "../Component/Images/noPreview.png";

export const extractHtmlContent = (text: string): string => {
    return text.replace(/<[^>]+>/g, "");
};

export const convertAlphaToHexadecimal = (alpha: number): string => {
    const intValue = Math.round((alpha / 100) * 255);
    const hexValue = intValue.toString(16);
    return hexValue.padStart(2, "0").toUpperCase();
};

export const addDefaultSrc = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = brokenImage;
};

export const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist * 1000;
};

export const isDateLess = (date: Date, endDate: Date): boolean => date.getTime() < endDate.getTime();

export const youtubeParser = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};

export const checkIfFilesAreCorrectType = (files: File[], fileValidation: string[]) => {
    let valid = true;
    if (files) {
        files.forEach((file) => {
            if (!fileValidation.includes(file.type)) {
                valid = false;
            }
        });
    }
    return valid;
};

export const checkIfFilesAreTooBig = (files: File[], fileSize: number) => {
    let valid = true;
    if (files) {
        files.forEach((file) => {
            const size = file.size / 1024 / 1024;
            if (size > fileSize) {
                valid = false;
            }
        });
    }
    return valid;
};

export const differenceArrayObject = (
    object1: Record<string, string | number>[],
    object2: Record<string, string | number>[],
    key: string
): any => {
    if (!object1 || !object2) {
        return [];
    }
    return differenceBy(object1, object2, key);
};

export const urlRegex =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const scrollTo = (id: string) => {
    document.getElementById(id)?.scrollIntoView();
};

export const getSingaporeTime = () => {
    const options = { timeZone: "Asia/Singapore", hour12: false };
    const singaporeTime = new Date().toLocaleString("en-US", options);
    return new Date(singaporeTime);
};

export const HIDE_MINI_EVENT_FLAG = false;
export const QUESTION_TRIGGER_FLAG = false;

export const getInitials = (fullName: string) => {
    const nameArray = fullName.split(" ");
    return `${nameArray[0].charAt(0).toUpperCase()}${nameArray?.[1].charAt(0).toUpperCase()}`;
};
