import { PlanMessage, PlanTitle, PlansCardContainer } from "./Style";
import { Grid } from "@mui/material";
import { PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";

const PremiumPlanCard = () => {
    return (
        <PlansCardContainer position={"relative"}>
            <Grid item xs={12}>
                <PlanTitle>Custom Plan</PlanTitle>
                <PlanMessage>
                    If you need more customisation to your activity, feel free to drop us an email at
                    team@pursuitpath.net
                </PlanMessage>
            </Grid>
            <Grid width={"81%"} position={"absolute"} bottom={20} style={{ marginTop: 20 }}>
                <PrimaryButton
                    fullWidth
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => window.open("mailto:team@pursuitpath.net", "_blank")}
                    style={{ textTransform: "none" }}>
                    Contact Us at team@pursuitpath.net
                </PrimaryButton>
            </Grid>
        </PlansCardContainer>
    );
};

export default PremiumPlanCard;
