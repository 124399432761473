import { Grid } from "@mui/material";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import { useHistory } from "react-router";
import { useReferenceListManager } from "./Hooks";
import RefrenceList from "./Refrencelist";
import { filterDataBySearchText } from "./Utils";
const ReferenceListManager = (): JSX.Element => {
    const {
        searchText,
        refrencelist,
        isLoading,
        cloneReferenceId,
        selectedReferenceDeleteId,
        onReferenceDeleteClose,
        setSearchText,
        onReferenceCloneClose,
        onReferenceClone,
        onReferenceDelete,
        setSelectedReferenceDeleteId,
        setCloneReferenceId,
        permission
    } = useReferenceListManager();

    const history = useHistory();
    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton={!!(permission && permission.includes("add"))}
                    onButtonClick={() => history.push("/admin/v2/referencelistmanager/addNewReference")}
                    buttonLabel={"Add new reference"}
                />
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <RefrenceList
                    refrenceList={filterDataBySearchText(searchText, refrencelist)}
                    setSelectedReferenceDeleteId={setSelectedReferenceDeleteId}
                    setCloneReferenceId={setCloneReferenceId}
                    isLoading={isLoading}
                />
            </Grid>
            {selectedReferenceDeleteId && (
                <ConfirmationBox
                    title={`Delete "${selectedReferenceDeleteId.name}" reference`}
                    message={"Please confirm if you really want to delete this refrence"}
                    onConfirm={onReferenceDelete}
                    onClose={onReferenceDeleteClose}
                />
            )}

            {cloneReferenceId && (
                <ConfirmationBox
                    title={`Duplicate "${cloneReferenceId.name}" reference`}
                    message={"Please confirm if you really want to duplicate this refrence"}
                    onConfirm={onReferenceClone}
                    onClose={onReferenceCloneClose}
                    type={"clone"}
                />
            )}
        </Grid>
    );
};

export default ReferenceListManager;
