import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { extractHtmlContent } from "../../../../../utils/JambarUtils";
import { StyledColumn, StyledHeader } from "../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { GameInfoType } from "./Type";
import { getGameInfoData, onConfirmDelete, onConfirmQuizClone } from "./Util";

export const useGameInfoHook = () => {
    const [selectedTab, setSelectedTab] = useState("settings");
    const [loading, setLoading] = useState(false);
    const [gameInfo, setGameInfo] = useState<GameInfoType | null>(null);
    const [cloneGame, setCloneGame] = useState(false);
    const [deleteGame, setDeleteGame] = useState(false);
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const gameType = searchParams.get("gameType");
    const searchText = searchParams.get("searchtext");

    const { gameId } = useParams<{
        gameId: string;
    }>();

    const onConfirmQuestionClone = () => {
        onConfirmQuizClone(parseInt(gameId))
            .then((questionId: string) => {
                history.push(
                    `/admin/v2/gamelibrary/${questionId}?gameType=${gameType}&${
                        searchText ? `&searchtext=${searchText}` : ``
                    }`
                );
            })
            .finally(() => setCloneGame(false));
    };

    const onDeleteQuestion = () => {
        onConfirmDelete(parseInt(gameId))
            .then(() => {
                toast.success("Question deleted", SiteConstants.successToastConfiguration);
                history.push(
                    `/admin/v2/gamelibrary??gameType=${gameType}&${searchText ? `&searchtext=${searchText}` : ``}`
                );
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setDeleteGame(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        getGameInfoData(gameId)
            .then((res) => {
                setGameInfo(res);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        selectedTab,
        setSelectedTab,
        loading,
        gameInfo,
        gameId,
        cloneGame,
        deleteGame,
        onDeleteQuestion,
        onConfirmQuestionClone,
        setCloneGame,
        setDeleteGame,
        gameType,
        searchText
    };
};

export const useGameQuestionHook = () => {
    const [searchText, setSearchText] = useState("");

    const columns: GridColDef[] = [
        {
            field: "index",
            headerName: "",
            width: 20,
            renderHeader: () => <StyledHeader label="#" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.index} />
        },
        {
            field: "questionTitle",
            headerName: "",
            flex: 1,
            renderCell: (params: GridCellParams) => (
                <StyledColumn label={extractHtmlContent(params.row.questionTitle)} />
            ),
            renderHeader: () => <StyledHeader label="Question title" />
        }
    ];

    return {
        columns,
        searchText,
        setSearchText
    };
};
