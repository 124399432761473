import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { fetchTagsDropdownData, getQuestionDetails, getQuestionHints } from "./Utils";
import { onConfirmClone, onConfirmDelete } from "../Utils";
import { QuestionDetailType, QuestionHintsType } from "./Type";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { useAdminContext } from "../../../context/AdminAuthContext";
import { getPermissions } from "../../../../../utils/PermissionUtils";

export const useQuestionInfo = (selectedQuestionId?: number) => {
    const [selectedTab, setSelectedTab] = useState("details");
    const [questionDetails, setQuestionDetails] = useState<QuestionDetailType>();
    const [isLoading, setIsLoading] = useState(false);
    const [deleteQuestion, setDeleteQuestion] = useState(false);
    const [cloneQuestion, setCloneQuestion] = useState(false);
    const [tagsOptions, setTagsOptions] = useState<OptionType[]>([]);
    const [questionHints, setQuestionHints] = useState<QuestionHintsType[]>([]);
    const { id } = useParams<{
        id: string;
    }>();
    const questionId = selectedQuestionId || parseInt(id);
    const history = useHistory();
    const [openGameModal, setOpenGameModal] = useState<boolean>(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "question");

    useEffect(() => {
        id && fetchData();
    }, [id]);

    const fetchData = () => {
        setIsLoading(true);
        getQuestionHints(questionId)
            .then((res) => setQuestionHints(res))
            .catch((err) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration));

        fetchTagsDropdownData()
            .then((data) => setTagsOptions(data))
            .catch((err) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration));

        getQuestionDetails(questionId)
            .then((res) => {
                setQuestionDetails(res);
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const toggleDelete = () => {
        setDeleteQuestion((prev) => !prev);
    };

    const toggleClone = () => {
        setCloneQuestion((prev) => !prev);
    };

    const onUseInGameClick = () => {
        return null;
    };

    const onConfirmQuestionClone = () => {
        onConfirmClone(questionId)
            .then((id) => {
                history.push(`/admin/v2/questionlibrary/${id}/questioninfo`);
                toast.success("Event cloned", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setCloneQuestion(false));
    };

    const onConfirmQuestionDelete = () => {
        onConfirmDelete(questionId)
            .then(() => {
                history.push(`/admin/v2/questionlibrary`);
                toast.success("Question Deleted", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setDeleteQuestion(false));
    };

    return {
        selectedTab,
        isLoading,
        questionDetails,
        questionId,
        deleteQuestion,
        cloneQuestion,
        onUseInGameClick,
        setSelectedTab,
        fetchData,
        onConfirmQuestionDelete,
        onConfirmQuestionClone,
        toggleClone,
        toggleDelete,
        tagsOptions,
        questionHints,
        setOpenGameModal,
        openGameModal,
        permission
    };
};
