import { IStackTokens } from "@fluentui/react";

export const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10
};

export const ThemedMediumStackTokens: IStackTokens = {
    childrenGap: "m"
};

export const ThemedSmall2StackTokens: IStackTokens = {
    childrenGap: "s2"
};

export const ThemedLargeStackTokens: IStackTokens = {
    childrenGap: "l1"
};

export const optionTextColor = "#0E596F";
export const selectedButtonColor = "#B2E4F1";
export const defaultButtonBackgroundColor = "#E5E5E5";
export const successButtonBackgroundColor = "#E6FFE6";
export const dashedBorderStyle = "dashed";
export const linkColor = `#007BFF`;
export const wildgoatBackgroundColor = `#00A5CF`;
export const wildgoatTextFieldLabelColor = `#004E64`;
export const wildgoatWelcomeLabelColor = `#004E64`;
export const wildgoatSubmitButtonColor = `#7AE582`;
export const scoreBoxColor = "#EFEDEF";
export const greenColor = `#B5975B`;
export const purpleColor = `#012D6A`;
export const whiteColor = "#ffff";
export const inputBackgroundColor = "#00000021";
export const avatarBackgroundColor = "#E0DCD3";
export const redColor = "#F54748";
export const lightBlueColor = "#d4f4f4";
export const pointsBarOrangeColor = "#d38e05";
export const pointsBarRedColor = "#d30505";
export const pointsBarGreeneColor = "#05d357";
export const defaultWildGoatBackgroundColor = "#66c9e2";
export const greyColor = "grey";
export const fadedWhiteColor = "rgba(181, 189, 195, 0.3)";
export const wrongAnswerBackgroundColor = "#d62d2d";
export const correctAnswerBackgroundColor = "#54c654";
export const scrollColor = `#d2d1d1`;
export const backgroundColor = "#efefef";
