import Styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { FiDownload, FiEdit2, FiLink } from "react-icons/fi";
import { FaRegClone } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { darkColor } from "../../../WebsiteGame/colorConstant";
import { MdSettingsBackupRestore } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";

type ColumnTextProps = {
    color?: string;
    fontWeight?: string | number;
};
export const ColumnsText = Styled(Typography)<ColumnTextProps>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 400};
    font-size: 16px;
    line-height: 18px;
    color: ${(props) => props.color || darkColor}; 
`;

export const ColumnAction = Styled.div`
    display: flex;
    gap: 10px;
`;

export const ChangePermissionIcon = Styled(FiLink)`
    color: #2F80ED;
    cursor: pointer;
    font-size: 18px;
`;

export const EditIcon = Styled(FiEdit2)`
    color: #828282;
    cursor: pointer;
    font-size: 18px;
`;

export const CloneIcon = Styled(FaRegClone)`
    color: #2F80ED;
    cursor: pointer;
    font-size: 18px;
`;

export const DeleteIcon = Styled(RiDeleteBinLine)`
    color: #EB5757;
    cursor: pointer;
    font-size: 18px;
`;

export const ViewIcon = Styled(AiOutlineEye)`
    color: #828282;
    cursor: pointer;
    font-size: 22px;
`;

export const RocketIcon = Styled.img`
    cursor: pointer;
    height: 18;
`;

export const DownloadIcon = Styled(FiDownload)`
    color: #828282;;
    cursor: pointer;
    font-size: 18px;
`;

export const RestoreIcon = Styled(MdSettingsBackupRestore)`
    color: #828282;;
    cursor: pointer;
    font-size: 18px;
`;

export const ColumnHeader = Styled(Typography)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #828282;
`;

export const StyledGrid = Styled(Grid)`
    & .MuiDataGrid-cell {
        border-bottom: 0px !important;
        outline: none !important;
    }
    & .MuiDataGrid-columnHeader {
        outline: none !important;
    }
    & .MuiDataGrid-root {
        border: 0px !important;
    }
    & .MuiDataGrid-withBorder {
        border: none !important;
    }
    & .MuiTablePagination-toolbar {
        min-height: 20px !important;
        align-items: baseline !important;
    }
    & .MuiDataGrid-footerContainer {
        min-height: 20px !important;
    }
    & .MuiTablePagination-displayedRows {
        margin-bottom: 0px !important;
    }
`;

export const LogoNameWrapper = Styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const RemoveIcon = Styled(IoIosCloseCircleOutline)`
    color: #EB5757;
    cursor: pointer;
    font-size: 20px;
`;
