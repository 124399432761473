import { toast } from "react-toastify";
import { RegsiterUserFormDetailType } from "./type";
import { getAllIdentitiesList, registerNewUserInfo, transformIdentityDropdown } from "./util";
import { SiteConstants } from "../../../constants/SiteConstant";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import { useEffect, useState } from "react";
import { OptionType } from "../../CommonComponent/v2/fields/Type";
import { useHistory } from "react-router";

export const useRegister = () => {
    const [loading, setLoading] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const registerNewUser = (values: RegsiterUserFormDetailType) => {
        setLoading(true);
        const userId = searchParams.get("userId");
        const verificationCode = searchParams.get("verificationCode");
        userId &&
            verificationCode &&
            registerNewUserInfo(values, userId, verificationCode)
                .then(() => {
                    toast.success(
                        "Congratulations ! Your account has been created",
                        SiteConstants.successToastConfiguration
                    );
                    history.push("/admin/login");
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    setLoading(false);
                });
    };

    return {
        loading,
        setLoading,
        registerNewUser,
        searchParams
    };
};

export const useRegisterFormHook = () => {
    const [identityDropdown, setIdentityDropdown] = useState<OptionType[]>([]);
    useEffect(() => {
        getAllIdentitiesList().then((res: any) => {
            setIdentityDropdown(transformIdentityDropdown(res));
        });
    }, []);

    return {
        identityDropdown
    };
};
