import React, { useState } from "react";
import TeamFlag from "../../../Images/Wildgoat/teamFlag.svg";
import { InputGroup } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import {
    WildGoatsCenterContainer,
    ContentBox,
    CustomInputBox,
    InfoText,
    MainImage,
    MainText,
    WildGoatsContainer,
    LoginCircleButtonBig
} from "../CommonStyledComponents/CommonStyle";
import { getOffsetColor } from "../Util/Util";
import { useHistory, useLocation } from "react-router";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import {
    wildgoatPrimaryColor,
    wildgoatSecondaryColor,
    wildgoatTertiaryColor
} from "../../../../constants/ColorConstant";
import Header from "../Header/Header";
import loadingGif from "../../../Images/loading.gif";

const TeamName = () => {
    const [name, setName] = useState<string>("");
    const history = useHistory();
    const info: any = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [loginFailed, setLoginFailed] = useState(false);

    const selectAvatar = () => {
        setLoading(true);
        Client.getInstance()
            .updateData(ApiConstants.addTeamNameApiUrl(info.state.eventLink, info.state.code), {
                teamName: name
            })
            .then(() => {
                history.push("/wildgoat", {
                    code: info.state.code,
                    eventLink: info.state.eventLink,
                    eventId: info.state.eventId,
                    quizId: info.state.quizId,
                    teamName: name,
                    isMultibranch: info.state.isMultibranch,
                    participantName: info.state.participantName
                });
            })
            .catch(() => {
                setLoginFailed(true);
            })
            .finally(() => setLoading(false));
        return;
    };

    return (
        <WildGoatsContainer
            bgcolor={info.state.quiz.quizConfig?.wildgoatPrimaryColor || wildgoatPrimaryColor}
            bgcolor_offset={getOffsetColor(info.state.quiz.quizConfig?.wildgoatPrimaryColor || wildgoatPrimaryColor)}>
            <Header
                quizTitle={info.state.quiz.displayTitle}
                companyLogo={info.state.data.companyLogo}
                quizLogo={info.state.data.logo}
                secondaryColor={info.state.quiz.quizConfig?.wildgoatSecondaryColor || wildgoatSecondaryColor}
            />
            <WildGoatsCenterContainer style={{ marginTop: 11 }}>
                <ContentBox>
                    <WildGoatsCenterContainer>
                        <MainImage src={TeamFlag} alt="teamFlag" />
                    </WildGoatsCenterContainer>
                    <MainText>Team name</MainText>
                    <InfoText>Please enter your team name</InfoText>
                    <InputGroup size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Enter name here"}
                            value={name}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setName(target.value)}
                        />
                    </InputGroup>
                    {loginFailed && <span className={"error-message"}>Something went wrong. Please try again.</span>}
                    <LoginCircleButtonBig
                        secondaryColor={info.state.quiz.quizConfig?.wildgoatSecondaryColor || wildgoatSecondaryColor}
                        tertiarycolor={info.state.quiz.quizConfig?.wildgoatTertiaryColor || wildgoatTertiaryColor}
                        disabled={name === "" || loading}
                        onClick={selectAvatar}>
                        {loading ? (
                            <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                        ) : (
                            <Icon iconName={"ChevronRight"} style={{ fontSize: 20 }} />
                        )}
                    </LoginCircleButtonBig>
                </ContentBox>
            </WildGoatsCenterContainer>
        </WildGoatsContainer>
    );
};

export default TeamName;
