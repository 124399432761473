import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { Grid, InputAdornment } from "@mui/material";
import { IconContainer, PrimaryButton, PrimaryOutlinedButton, TextButton } from "../CommonStyle";
import { SearchOutlined } from "@mui/icons-material";
import { StyledTextField } from "./Style";
import DownloadIcon from "../../../Images/icons/download.svg";
import { debounce } from "lodash";
import ColumnIcon from "../../../Images/icons/columns.svg";
import { OptionType } from "../fields/Type";
import SelectWrapper from "../fields/SelectWrapper";

type SearchTabProps = {
    filterOptions: {
        value: string;
        options: OptionType[];
        setValue: (value: string) => void;
    };
    label: string;
};
export const SearchTab: FC<SearchTabProps> = ({ filterOptions, label }) => (
    <SelectWrapper
        options={filterOptions.options}
        value={filterOptions.value}
        handleChange={filterOptions.setValue}
        label={label}
        style={{ borderRadius: "10px" }}
    />
);

type SearchHeaderProps = {
    searchText?: string;
    setSearchText?: (value: string) => void;
    showAddButton?: boolean;
    hideSearch?: boolean;
    buttonLabel?: string;
    onButtonClick?: () => void;
    showDownloadButton?: boolean;
    searchPlaceholder?: string;
    onDownloadButtonClick?: () => void;
    showManageColumnButton?: () => void;
    filterOptions?: {
        value: string;
        options: OptionType[];
        setValue: (value: string) => void;
        label: string;
    }[];
    resetFilter?: ReactNode;
};
const SearchHeader: FC<SearchHeaderProps> = ({
    setSearchText,
    showAddButton,
    buttonLabel,
    onButtonClick,
    onDownloadButtonClick,
    showDownloadButton,
    searchPlaceholder,
    showManageColumnButton,
    filterOptions,
    hideSearch,
    searchText,
    resetFilter
}) => {
    const [textfieldSearch, setTextfieldSearch] = useState(searchText);

    useEffect(() => {
        setTextfieldSearch(searchText);
    }, [searchText]);

    const handleSearchTextChange = useCallback(
        debounce((text) => {
            setSearchText && setSearchText(text);
        }, 300),
        []
    );
    return (
        <Grid container flexWrap={"nowrap"} alignItems={"center"} justifyContent={"space-between"}>
            <Grid item xs={9}>
                <Grid container flexWrap={"nowrap"} columnSpacing={"10px"}>
                    <Grid item xs={5}>
                        {!hideSearch && (
                            <StyledTextField
                                InputLabelProps={{ shrink: true }}
                                placeholder={searchPlaceholder || "Search"}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    )
                                }}
                                value={textfieldSearch}
                                onChange={(e) => {
                                    setTextfieldSearch(e.target.value);
                                    handleSearchTextChange(e.target.value);
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        )}
                    </Grid>
                    {filterOptions &&
                        filterOptions.map((opt, index) => (
                            <Grid item maxWidth={"250px"} flex={1} key={index}>
                                <SelectWrapper
                                    options={opt.options}
                                    value={opt.value}
                                    handleChange={opt.setValue}
                                    label={opt.label}
                                    style={{ borderRadius: "10px" }}
                                />
                            </Grid>
                        ))}
                    {resetFilter && (
                        <Grid maxWidth={"150px"} item>
                            {resetFilter}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container justifyContent={"flex-end"}>
                    {showAddButton && (
                        <Grid item width={"200px"}>
                            <PrimaryButton
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    height: 56
                                }}
                                fontsize="16px"
                                onClick={onButtonClick}
                                fullWidth>
                                {buttonLabel || "Add"}
                            </PrimaryButton>
                        </Grid>
                    )}
                    {showDownloadButton && (
                        <Grid item>
                            <TextButton fontsize="16px" variant="text" onClick={onDownloadButtonClick} fullWidth>
                                <img src={DownloadIcon} alt="download" />
                                {buttonLabel || "Download"}
                            </TextButton>
                        </Grid>
                    )}
                    {showManageColumnButton && (
                        <Grid item>
                            <PrimaryOutlinedButton fontsize="16px" onClick={showManageColumnButton}>
                                <IconContainer style={{ marginRight: 20 }} src={ColumnIcon} />
                                Manage Columns
                            </PrimaryOutlinedButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SearchHeader;
