import { Grid } from "@mui/material";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { FC } from "react";
import { useHistory } from "react-router";
import LoadingButtonWrapper from "../../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { CancelButton, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import GameDeleteDialog from "../../gamelibrary/GameDeleteDialog";
import AnswerDetailsForm from "./AnswerDetailsForm";
import { useAddQuestionHook } from "./Hooks";
import QuestionDetailsForm from "./QuestionDetailsForm";
import QuestionIdentificationForm from "./QuestionIdentificationForm";
import QuestionSettingsForm from "./QuestionSettingsForm";
import { QuestionDetailType } from "./Type";
import { QuestionIntialValue, QuestionFormValidationSchema, getLast } from "./Util";

type QuestionAddUpdateFormProps = {
    questionDetails?: QuestionDetailType;
    gameId: string;
    questionId?: string;
    refreshList?: () => void;
    pageNo: string | number;
    handleClose?: () => void;
};

const QuestionAddUpdateForm: FC<QuestionAddUpdateFormProps> = ({
    questionDetails,
    gameId,
    questionId,
    refreshList,
    pageNo,
    handleClose
}) => {
    const {
        handleQuestionSubmit,
        removeCoverImage,
        isLoading,
        setOpenSaveQuestionModal,
        usageList,
        openSaveQuestionModal
    } = useAddQuestionHook(questionId, refreshList, handleClose);

    const history = useHistory();

    return (
        <Grid container>
            <Formik
                initialValues={QuestionIntialValue(gameId, questionDetails)}
                enableReinitialize
                validationSchema={QuestionFormValidationSchema(gameId)}
                onSubmit={(values, { setSubmitting }) => {
                    handleQuestionSubmit(values, setSubmitting);
                }}>
                {({ handleSubmit, isSubmitting, validateForm }) => (
                    <Grid item xs={12}>
                        <Grid container maxWidth={"576px"}>
                            <Grid item xs={12}>
                                <QuestionIdentificationForm />
                            </Grid>
                            <Grid item xs={12}>
                                <QuestionDetailsForm removeCoverImage={removeCoverImage} />
                            </Grid>
                            <Grid item xs={12}>
                                <AnswerDetailsForm
                                    gameId={questionDetails?.questionIdentification?.gameType || gameId}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <QuestionSettingsForm />
                            </Grid>
                            <Grid my={"80px"} item xs={12}>
                                <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                                    <Grid item>
                                        <CancelButton
                                            onClick={() => history.push(`/admin/v2/questionlibrary?pageNo=${pageNo}`)}>
                                            Cancel
                                        </CancelButton>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButtonWrapper loading={isSubmitting}>
                                            <PrimaryButton
                                                onClick={async () => {
                                                    const validationErrors = await validateForm();
                                                    if (!isEmpty(validationErrors)) {
                                                        getLast(validationErrors);
                                                        return;
                                                    }
                                                    questionId
                                                        ? setOpenSaveQuestionModal(parseInt(questionId))
                                                        : handleSubmit();
                                                }}
                                                style={{ padding: "16px 18px" }}>
                                                Save challenge
                                            </PrimaryButton>
                                        </LoadingButtonWrapper>
                                    </Grid>
                                    {openSaveQuestionModal && (
                                        <GameDeleteDialog
                                            usageList={usageList.slice(0, 5)}
                                            type={"update"}
                                            title={`Save Challenge`}
                                            confirmLoading={isSubmitting}
                                            message={
                                                usageList.length === 0
                                                    ? [`Are you sure you want to save this challenge.`]
                                                    : [
                                                          "This challenge is currently being used in the following game(s). If you save, any edits made will also affect those games.",
                                                          "Alternatively, you can duplicate the current challenge to avoid affecting other games."
                                                      ]
                                            }
                                            showDeleteButton
                                            onConfirm={() => {
                                                handleSubmit();
                                            }}
                                            onClose={() => setOpenSaveQuestionModal(null)}
                                            isLoading={isLoading}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default QuestionAddUpdateForm;
