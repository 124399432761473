import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getPermissions } from "../../../../../../utils/PermissionUtils";
import { extractHtmlContent } from "../../../../../../utils/JambarUtils";
import { OptionType } from "../../../../../CommonComponent/v2/fields/Type";
import { useAdminContext } from "../../../../context/AdminAuthContext";
import { getAllIdentitiesList } from "../../../questionlibrary/addquestion/Util";
import { tranformGameTypeOption } from "../../../questionlibrary/questionInfo/Utils";
import { getQuestionList } from "../../../questionlibrary/Utils";
import { GameDetailType, GameQuestionListItem } from "../Type";

export const useGameIdentificationHooks = () => {
    const [identityDropdownOptions, setIdentityDropdownOptions] = useState<OptionType[]>([]);
    const { userDetail } = useAdminContext();
    const [questionList, setQuestionList] = useState<GameQuestionListItem[]>([]);
    const permission: string[] | null = getPermissions(userDetail, "question");
    const [questionListLoading, setQuestionListLoading] = useState(true);
    const { setFieldValue, values } = useFormikContext();
    const data = values as GameDetailType;
    const [selectedEditQuestion, setSelectedEditQuestion] = useState<{
        gameId: string;
        questionId: string;
    } | null>(null);
    const [refreshListKey, setRefreshList] = useState<string>(Math.random().toString());

    const { gameType } = useParams<{
        gameType: string;
    }>();

    useEffect(() => {
        fetchIdentityDropdowndownData();
        fetchData();
    }, []);

    const fetchData = (forceRefresh = false) => {
        if (!(permission && permission.includes("fetch"))) {
            setQuestionList([]);
            setQuestionListLoading(false);
            return;
        }
        setQuestionListLoading(true);
        getQuestionList("active", forceRefresh)
            .then((res) => {
                const questionListData: GameQuestionListItem[] = res.map((item, index) => ({
                    id: item.id,
                    questionTitle: extractHtmlContent(item.title),
                    tags: item.tags,
                    gameType: item.gameType,
                    type: item.questionInfo.questionType,
                    index: index
                }));
                const filteredData = questionListData.filter(
                    (item) => tranformGameTypeOption[item.gameType] === gameType
                );
                setQuestionList(
                    filteredData.map((item, index) => ({
                        ...item,
                        index: index + 1
                    }))
                );
            })
            .catch(() => {
                setQuestionList([]);
            })
            .finally(() => {
                setRefreshList(Math.random().toString());
                setQuestionListLoading(false);
                setSelectedEditQuestion(null);
            });
    };

    const fetchIdentityDropdowndownData = () => {
        getAllIdentitiesList(true)
            .then((res) => setIdentityDropdownOptions(res))
            .catch(() => setIdentityDropdownOptions([]));
    };

    return {
        identityDropdownOptions,
        gameType,
        userDetail,
        questionList,
        questionListLoading,
        setSelectedQuestion: (question: GameQuestionListItem[]) => setFieldValue("gameQuestionList", question),
        selectedQuestion: data.gameQuestionList,
        setSelectedEditQuestion,
        selectedEditQuestion,
        fetchData,
        refreshListKey,
        questionInfo: data
    };
};
