import { RegisterStyledContainer } from "./style";
import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { UserDetailValidationSchema, registerDetailFormInitialValues } from "./util";
import { useRegister } from "./hook";
import RegisterForm from "./component/RegisterForm";
import JambarLogo from "../../Images/logo.svg";

const Register = () => {
    const { loading, registerNewUser, searchParams } = useRegister();

    return (
        <RegisterStyledContainer py={"50px"} container justifyContent={"center"} minHeight={"100vh"}>
            <Grid item xs={6} bgcolor={"#fff"} p={"40px"} borderRadius={"30px"}>
                <Box display={"flex"} justifyContent={"center"}>
                    <img src={JambarLogo} height={"100px"} alt={"jambar"} />
                </Box>
                <Formik
                    initialValues={registerDetailFormInitialValues({
                        email: searchParams.get("email"),
                        firstName: searchParams.get("firstName"),
                        lastName: searchParams.get("lastName")
                    })}
                    validationSchema={UserDetailValidationSchema()}
                    onSubmit={registerNewUser}
                    enableReinitialize
                    validateOnMount={false}>
                    {() => <RegisterForm loading={loading} />}
                </Formik>
            </Grid>
        </RegisterStyledContainer>
    );
};

export default Register;
