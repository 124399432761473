import { Dialog, Grid } from "@mui/material";
import { useHistory } from "react-router";
import {
    CaptionText,
    Header1,
    PrimaryButton,
    PrimaryOutlinedButton,
    TextButton
} from "../../../../CommonComponent/v2/CommonStyle";
import SuccessEventIcon from "../../../../Images/icons/flyrocket.svg";
import { FC } from "react";
import { NewEventInfoType } from "./Type";

type EventCreatedSuccessfulModalProps = {
    newEventInfo: NewEventInfoType;
};
const EventCreatedSuccessfulModal: FC<EventCreatedSuccessfulModalProps> = ({ newEventInfo }) => {
    const history = useHistory();

    return (
        <Dialog maxWidth={"lg"} fullWidth open>
            <Grid p={"40px"}>
                <Header1 textAlign={"center"}>Event created successfully</Header1>
                <Grid mt={"50px"} container justifyContent={"center"}>
                    <img src={SuccessEventIcon} />
                </Grid>
                <Grid position={"relative"} bottom={"140px"} container justifyContent={"center"}>
                    <Grid item xs={7}>
                        <CaptionText textAlign={"center"}>
                            You have successfully launched your event. Now you can generate the codes for the
                            participants, visit event manager to generate the codes later, or follow the event link to
                            view your event.
                        </CaptionText>
                    </Grid>
                </Grid>
                <Grid spacing={"20px"} container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <TextButton fontsize="16px" onClick={() => history.push("/admin/event")} variant="text">
                            Go to event manager
                        </TextButton>
                    </Grid>
                    <Grid item>
                        <PrimaryButton
                            onClick={() => {
                                newEventInfo.type === "mini"
                                    ? window.open(`${window.location.origin}/minievent/${newEventInfo.eventLink}`)
                                    : window.open(`${window.location.origin}/event/${newEventInfo.eventLink}`);
                            }}
                            fontsize="16px"
                            variant="contained">
                            Follow event link
                        </PrimaryButton>
                    </Grid>
                    <Grid item>
                        <PrimaryOutlinedButton
                            onClick={() =>
                                history.push(`/admin/event/${newEventInfo.id}/info`, {
                                    tab: "code"
                                })
                            }
                            fontsize="16px"
                            variant="outlined"
                            textcolor={"#65B44A"}>
                            Generate Code
                        </PrimaryOutlinedButton>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default EventCreatedSuccessfulModal;
