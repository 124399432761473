import { toast } from "react-toastify";
import { array, mixed, number, object, string } from "yup";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../../constants/FieldValidation";
import {
    IMAGE_QUESTION_TYPE,
    SCORE_PENALTY,
    SiteConstants,
    TIMEOUT_PENALTY,
    UPLOAD_VIDEO_TYPE,
    VIDEO_QUESTION_TYPE,
    WildGoatGame,
    YOUTUBE_VIDEO_TYPE
} from "../../../../../constants/SiteConstant";
import { FREE_TEXT_CONNECTORS, MCQ } from "../../../../../data/question/types";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { extractHtmlContent, urlRegex } from "../../../../../utils/JambarUtils";
import { Client } from "../../../../Base/Client";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import { dropDownType } from "../../../EventManager/EventDetails/utils/type";
import { UsageListType } from "../../gamelibrary/Type";
import { revTranformGameTypeOption, tranformGameTypeOption } from "../questionInfo/Utils";
import { MCQAnswerOption } from "../Type";
import { ConnectorOptionType, QuestionDetailType } from "./Type";

export const AddQuestionbreadcrumbValues = (isEdit: boolean): BreadCrumbType[] => [
    {
        label: "Challenge Library",
        url: "/admin/v2/questionlibrary"
    },
    {
        label: "Challenges",
        url: "/admin/v2/questionlibrary"
    },
    {
        label: isEdit ? "Update Challenge" : "Add new Challenge"
    }
];

export const QuestionIntialValue = (gameType: string, questionDetails?: QuestionDetailType): QuestionDetailType => ({
    questionIdentification: {
        gameType: questionDetails?.questionIdentification?.gameType || gameType,
        visibilityType: questionDetails?.questionIdentification.visibilityType || "private",
        questionTitle: questionDetails?.questionIdentification.questionTitle || "",
        identity: questionDetails?.questionIdentification.identity || [],
        tags: questionDetails?.questionIdentification.tags || []
    },
    questionDetails: {
        questionType: questionDetails?.questionDetails.questionType || IMAGE_QUESTION_TYPE,
        videoQuestionType: questionDetails?.questionDetails.videoQuestionType || UPLOAD_VIDEO_TYPE,
        coverImage: {
            file: questionDetails?.questionDetails.coverImage.file || null,
            fileName: questionDetails?.questionDetails.coverImage.fileName || "",
            fileSize: questionDetails?.questionDetails.coverImage.fileSize
                ? (parseInt(questionDetails?.questionDetails.coverImage.fileSize) / 1024).toFixed(2)
                : ""
        },
        topQuestionText: questionDetails?.questionDetails.topQuestionText || "",
        youtubeVideoLink: questionDetails?.questionDetails.youtubeVideoLink || "",
        uploadVideo: questionDetails?.questionDetails.uploadVideo || null,
        bottomQuestionText: questionDetails?.questionDetails.bottomQuestionText || "",
        referenceItemText1: questionDetails?.questionDetails.referenceItemText1 || "",
        referenceItemText2: questionDetails?.questionDetails.referenceItemText2 || ""
    },
    answerDetails: {
        answerHeader: questionDetails?.answerDetails.answerHeader || "",
        answerType: questionDetails?.answerDetails.answerType || MCQ,
        mcq: {
            correctAnswerFrequency: questionDetails?.answerDetails.mcq.correctAnswerFrequency || "atleast_one_correct",
            mcqAnswerOptions: questionDetails?.answerDetails.mcq.mcqAnswerOptions || [
                {
                    id: 1,
                    text: "",
                    isCorrect: true
                }
            ]
        },
        // qrCodeValue: "MYTRS",
        triggers: questionDetails?.answerDetails.triggers || [],
        answerText: questionDetails?.answerDetails.answerText || "",
        freeText: {
            correctionCriteriaType: questionDetails?.answerDetails.freeText.correctionCriteriaType || "contains",
            freeTextAnswerFields: questionDetails?.answerDetails.freeText.freeTextAnswerFields || [
                {
                    id: 1,
                    text: ""
                }
            ]
        },
        connector: {
            completionTrigger: questionDetails?.answerDetails.connector.completionTrigger || "all",
            connectorTimer: questionDetails?.answerDetails.connector.connectorTimer || false,
            connectorOptions: questionDetails?.answerDetails.connector.connectorOptions || [
                {
                    id: 1,
                    option: "",
                    connectingQuiz: "",
                    isClosable: false
                }
            ]
        }
    },
    questionSettings: {
        maximumAttempts: questionDetails?.questionSettings.maximumAttempts || "",
        forceCorrect: questionDetails?.questionSettings.forceCorrect || false,
        nonScoring: questionDetails?.questionSettings.nonScoring || false,
        skippingAllowed: questionDetails?.questionSettings.skippingAllowed || false,
        isCorrectAnswerText: questionDetails?.questionSettings.isCorrectAnswerText || false,
        isWrongAnswerText: questionDetails?.questionSettings.isWrongAnswerText || false,
        correctAnswerText: questionDetails?.questionSettings.correctAnswerText || "",
        wrongAnswerText: questionDetails?.questionSettings.wrongAnswerText || "",
        timeoutText: {
            specifyTimeout: questionDetails?.questionSettings.timeoutText.specifyTimeout || false,
            text: questionDetails?.questionSettings.timeoutText.text || "",
            timelimit: {
                minutes: questionDetails?.questionSettings.timeoutText.timelimit.minutes || "",
                seconds: questionDetails?.questionSettings.timeoutText.timelimit.seconds || ""
            }
        },
        wrongAttemptPenaltyType: {
            type: questionDetails?.questionSettings.wrongAttemptPenaltyType.type || SCORE_PENALTY,
            correctPoints: questionDetails?.questionSettings.wrongAttemptPenaltyType.correctPoints || "",
            wrongPoints: questionDetails?.questionSettings.wrongAttemptPenaltyType.wrongPoints || "",
            skipPoints: questionDetails?.questionSettings.wrongAttemptPenaltyType.skipPoints || "",
            timeoutPenalty: questionDetails?.questionSettings.wrongAttemptPenaltyType.timeoutPenalty || []
        }
    }
});

export const QuestionFormValidationSchema = (gameType: string) =>
    object().shape({
        questionIdentification: object().shape({
            questionTitle: string().required("Challenge Name is required"),
            visibilityType: string().required("Visibility Type is required"),
            identity: mixed().when("visibilityType", {
                is: (val: string) => val === "limited",
                then: array().min(1, "Please select identity").required("Identity is required"),
                otherwise: array().nullable()
            })
        }),
        questionDetails: object().shape({
            questionType: string().required("Challenge Type is required"),
            topQuestionText: string().when(["questionType"], {
                is: (val: string) => val === IMAGE_QUESTION_TYPE,
                then: string().required("Name (in game) is required"),
                otherwise: string()
            }),
            youtubeVideoLink: string().matches(urlRegex, "Enter correct url!"),
            bottomQuestionText:
                gameType === WildGoatGame
                    ? string()
                    : string().when(["questionType"], {
                          is: (val: string) => val === IMAGE_QUESTION_TYPE,
                          then: string().required("Bottom Challenge Text is required"),
                          otherwise: string()
                      })
        }),
        answerDetails: object().shape({
            mcq: mixed().when("answerType", {
                is: (val: string) => val === MCQ,
                then: object().shape({
                    mcqAnswerOptions: array()
                        .min(1, "Answer Options is required")
                        .of(
                            object().shape({
                                text: string().required("Answer Option is required")
                            })
                        ),
                    otherwise: object().nullable()
                })
            }),
            triggers: mixed().when("answerType", {
                is: () => gameType === SiteConstants.wildgoat,
                then: array()
                    .min(0, "Answer Options is required")
                    .of(
                        object().shape({
                            targetLocation: string().required("Target location is required"),
                            radius: number().typeError("Radius must be number").required("Radius is required"),
                            type: string().required("Please select trigger type")
                        })
                    ),
                otherwise: object().nullable()
            }),
            freeText: mixed().when("answerType", {
                is: (val: string) => val === "free_text",
                then: object().shape({
                    freeTextAnswerFields: array()
                        .min(1, "Answer is required")
                        .of(
                            object().shape({
                                text: string().required("Answer is required")
                            })
                        )
                }),
                otherwise: object().nullable()
            }),
            connector: mixed().when("answerType", {
                is: (val: string) => val === "connector",
                then: object().shape({
                    completionTrigger: number().typeError("Completion Trigger must be a number"),
                    connectorOptions: array()
                        .min(1, "Connector Options is required")
                        .of(
                            object().shape({
                                option: string().required("Connector Option is required"),
                                connectingQuiz: string().required("Please select quiz")
                            })
                        )
                }),
                otherwise: object().nullable()
            })
        }),
        questionSettings: object().shape({
            maximumAttempts: number().typeError("Maximum Attempts must be a number"),
            correctAnswerText: string().when(["isCorrectAnswerText"], {
                is: (val: boolean) => val,
                then: string().required("Correct Answer Text is required"),
                otherwise: string()
            }),
            wrongAnswerText: string().when(["isWrongAnswerText"], {
                is: (val: boolean) => val,
                then: string().required("Wrong Answer Text is required"),
                otherwise: string()
            }),
            timeoutText: object().shape({
                text: string().when("specifyTimeout", {
                    is: (val: boolean) => val,
                    then: string().required("Timeout Text is required"),
                    otherwise: string()
                }),
                timelimit: object().shape({
                    minutes: mixed().when("specifyTimeout", {
                        is: (val: boolean) => val,
                        then: number()
                            .typeError("Minutes must be number")
                            .min(0, "Must be greater then 0")
                            .max(61, "Must be less then 61"),
                        otherwise: number().typeError("Minutes must be number").nullable()
                    }),
                    seconds: mixed().when("specifyTimeout", {
                        is: (val: boolean) => val,
                        then: number()
                            .typeError("Seconds must be number")
                            .min(0, "Must be greater then 0")
                            .max(61, "Must be less then 61"),
                        otherwise: number().typeError("Seconds must be number").nullable()
                    })
                })
            }),
            wrongAttemptPenaltyType: object().shape({
                correctPoints: number().typeError("Correct Points must be a number"),
                wrongPoints: number().typeError("Wrong Points must be a number"),
                skipPoints: number().typeError("Skip Points must be a number"),
                timeoutPenalty: array().when("type", {
                    is: (val: string) => val !== SCORE_PENALTY,
                    then: array().of(number().typeError("Please enter a number").required("Mandatory")),
                    otherwise: array().nullable()
                })
            })
        })
    });

export const GameTypeOption: OptionType[] = [
    {
        value: SiteConstants.spaceGameQuiz,
        label: "Virtual unlinked game"
    },
    {
        value: SiteConstants.socketQuiz,
        label: "Virtual linked game"
    },
    {
        value: SiteConstants.wildgoat,
        label: "Physical"
    }
];

const transformData = (res: any): dropDownType[] => {
    if (!res) {
        return [];
    }
    return res.data.data.map((item: any) => ({
        value: item.id,
        label: item.value
    }));
};

export const getTagsDropdownOptions = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response)))
        .catch((error: any) => Promise.reject(error));

const transformQuizListDropdown = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((quiz: any) => ({
        id: quiz.id,
        value: quiz.id,
        label: extractHtmlContent(quiz.title)
    }));
};

export const getQuizList = () =>
    Client.getInstance()
        .getData(ApiConstants.getQuizListApiUrl(SiteConstants.spaceGameQuiz, "all"))
        .then((response: any) => Promise.resolve(transformQuizListDropdown(response.data.data)))
        .catch((error: any) => Promise.reject(error));

const transformIdentityListDropdown = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((identity: any) => ({
        id: identity.id,
        value: identity.id,
        label: identity.name
    }));
};

export const getAllIdentitiesList = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getAllIdentitiesList(), forceRefresh)
        .then((res) => Promise.resolve(transformIdentityListDropdown(res.data.data)))
        .catch((err) => Promise.reject(err));

const getTime = (minutes: string, seconds: string): string => {
    if ((minutes === "60" && seconds === "60") || minutes === "60") {
        return `00:59:59`;
    }
    if (seconds === "60") {
        return `00:${minutes === "" ? "01" : parseInt(minutes) + 1}:00`;
    }
    return SiteConstants.getMaskedTime("00", minutes, seconds);
};

export const transformTimePenalty = (timePenalty: string[]) => {
    const timePenaltyValues = timePenalty.map((item) => Number(item)).join(";");
    return timePenaltyValues ? timePenaltyValues : "";
};

const transformConnectorOption = (options: ConnectorOptionType[]) => {
    return options.map((option: ConnectorOptionType) => ({
        id: option.id,
        optionText: option.option,
        quizId: option.connectingQuiz,
        isClosable: option.isClosable
    }));
};

const transformMCQOption = (options: MCQAnswerOption[]) => {
    return options.map((option: MCQAnswerOption) => ({
        text: option.text,
        id: option.id
    }));
};

const transformMCQOptionAnswer = (options: MCQAnswerOption[]) => {
    const answer: number[] = [];
    options.forEach((option: MCQAnswerOption) => {
        if (option.isCorrect) {
            answer.push(option.id);
        }
    });
    return answer.join(",");
};

const reverseTransformData = (data: QuestionDetailType) => {
    const questionData = new FormData();
    questionData.append("questionImage", data.questionDetails.coverImage.file as Blob);
    const triggerData = data.answerDetails.triggers.map((item) => ({
        description: item.targetLocation,
        radius: item.radius,
        type: item.type
    }));

    const reverseData = {
        accessibilityScope: data.questionIdentification.visibilityType,
        accessibleIdentityIds: data.questionIdentification.identity.join(";"),
        title: data.questionIdentification.questionTitle,
        gameType: revTranformGameTypeOption?.[data.questionIdentification.gameType] || "",
        tagIds: data.questionIdentification.tags.map((tag) => tag.value).join(","),
        answerHeader: data.answerDetails.answerHeader,
        type: data.answerDetails.answerType,
        triggers: JSON.stringify(triggerData),
        maxAttempts: data.questionSettings.maximumAttempts,
        youtubeVideoLink:
            data.questionDetails.questionType === VIDEO_QUESTION_TYPE ? data.questionDetails.youtubeVideoLink : "",
        questionText: data.questionDetails.topQuestionText,
        questionText2: data.questionDetails.bottomQuestionText,
        referenceItemText1: data.questionDetails.referenceItemText1,
        referenceItemText2: data.questionDetails.referenceItemText2,
        forceCorrect: data.questionSettings.forceCorrect,
        isNonScoringQuestion: data.questionSettings.nonScoring ? 1 : 0,
        allowSkipping: data.questionSettings.skippingAllowed,
        wrongPoints:
            data.questionSettings.wrongAttemptPenaltyType.type === TIMEOUT_PENALTY
                ? ""
                : data.questionSettings.wrongAttemptPenaltyType.wrongPoints,
        correctPoints:
            data.questionSettings.wrongAttemptPenaltyType.correctPoints === ""
                ? null
                : data.questionSettings.wrongAttemptPenaltyType.correctPoints,
        skipPoints:
            data.questionSettings.wrongAttemptPenaltyType.skipPoints === ""
                ? null
                : data.questionSettings.wrongAttemptPenaltyType.skipPoints,
        correctAnswerText: data.questionSettings.isCorrectAnswerText
            ? FieldValidation.quillTextEditorValidation(data.questionSettings.correctAnswerText)
                ? ""
                : data.questionSettings.correctAnswerText
            : "",
        wrongAnswerText: data.questionSettings.isWrongAnswerText
            ? FieldValidation.quillTextEditorValidation(data.questionSettings.wrongAnswerText)
                ? " "
                : data.questionSettings.wrongAnswerText
            : "",
        timeOutText: data.questionSettings.timeoutText.specifyTimeout
            ? FieldValidation.quillTextEditorValidation(data.questionSettings.timeoutText.text)
                ? " "
                : data.questionSettings.timeoutText.text
            : "",

        timeLimit: data.questionSettings.timeoutText.specifyTimeout
            ? getTime(
                  data.questionSettings.timeoutText.timelimit.minutes,
                  data.questionSettings.timeoutText.timelimit.seconds
              )
            : "",
        timePenaltyOnWrongAttempts:
            data.questionSettings.wrongAttemptPenaltyType.type === TIMEOUT_PENALTY
                ? transformTimePenalty(data.questionSettings.wrongAttemptPenaltyType.timeoutPenalty)
                : ""
    };

    if (data.answerDetails.answerType === FREE_TEXT_CONNECTORS) {
        const connectorQuestionData: Record<string, any> = {
            ...reverseData,
            connectorOptions: JSON.stringify(transformConnectorOption(data.answerDetails.connector.connectorOptions)),
            showConnectorTimer: data.answerDetails.connector.connectorTimer ? 1 : 0,
            thresholdQuizScore: data.answerDetails.connector.completionTrigger
        };
        for (const key in connectorQuestionData) {
            questionData.append(key, connectorQuestionData[key]);
        }
        return questionData;
    }
    if (data.answerDetails.answerType === MCQ) {
        const mcqData: Record<string, any> = {
            ...reverseData,
            mcqOptions: JSON.stringify(transformMCQOption(data.answerDetails.mcq.mcqAnswerOptions)),
            mcqAnswers: transformMCQOptionAnswer(data.answerDetails.mcq.mcqAnswerOptions),
            answerType: data.answerDetails.mcq.correctAnswerFrequency
        };
        for (const key in mcqData) {
            questionData.append(key, mcqData[key]);
        }
        return questionData;
    }

    const textData: Record<string, any> = {
        ...reverseData,
        answer:
            data.answerDetails.freeText.correctionCriteriaType === SiteConstants.EXACT_MATCH
                ? data.answerDetails.freeText.freeTextAnswerFields[0].text
                : data.answerDetails.freeText.freeTextAnswerFields.map((field) => field.text).join(","),
        answerType: data.answerDetails.freeText.correctionCriteriaType
    };

    for (const key in textData) {
        questionData.append(key, textData[key]);
    }
    return questionData;
};

export const saveQuestion = (data: QuestionDetailType) =>
    Client.getInstance()
        .createData(ApiConstants.addQuestionApiUrl(), reverseTransformData(data))
        .then((response) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));

export const updateQuestion = (questionId: string, data: QuestionDetailType) =>
    Client.getInstance()
        .updateData(ApiConstants.updateQuestionDetailsApiUrl(parseInt(questionId)), reverseTransformData(data))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const addVideoQuestion = (id: number, value: QuestionDetailType) => {
    const videoQuestionData = new FormData();
    videoQuestionData.append("questionVideo", value.questionDetails.uploadVideo as File);
    return Client.getInstance()
        .createData(ApiConstants.addQuestionVideoApiUrl(id), videoQuestionData)
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
};

const tranformMcqData = (data: any): MCQAnswerOption[] => {
    const mcq = JSON.parse(data.mcqOptions);
    if (!mcq) {
        return [];
    }
    return mcq.map((item: any) => ({
        id: item.id,
        text: item.text,
        isCorrect: data.mcqAnswers.split(",").includes(item.id.toString())
    }));
};

const transformQuestionData = (data: any): any => {
    return {
        answerDetails: {
            answerHeader: data?.answerHeader || "",
            answerText: "",
            answerType: data.type || "",
            connector: {
                completionTrigger: data.thresholdQuizScore,
                connectorOptions:
                    data?.questionConnector?.map((item: any) => ({
                        id: item.id,
                        isClosable: item.isClosable,
                        option: item.optionText,
                        connectingQuiz: item.quizId
                    })) || [],
                connectorTimer: data.showConnectorTimer
            },
            triggers: data.triggers.map((trigger: any) => ({
                id: trigger.id,
                targetLocation: trigger.description,
                radius: trigger.radius,
                type: trigger.type
            })),
            freeText: {
                correctionCriteriaType: data.answerType,
                freeTextAnswerFields:
                    data?.answer?.split(",").map((item: string, index: number) => ({
                        id: index,
                        text: item
                    })) || []
            },
            mcq: {
                correctAnswerFrequency: data.answerType,
                mcqAnswerOptions: tranformMcqData(data)
            }
        },
        questionDetails: {
            bottomQuestionText: data.questionText2,
            topQuestionText: data.questionText,
            coverImage: {
                file: data.fileLocation,
                fileName: data.fileName,
                fileSize: data.fileSize
            },
            questionType:
                data.youtubeVideoLink || data.videoQuestionFileLocation ? VIDEO_QUESTION_TYPE : IMAGE_QUESTION_TYPE,
            referenceItemText1: data.referenceItemText1,
            referenceItemText2: data.referenceItemText2,
            uploadVideo: data.videoQuestionFileLocation,
            videoQuestionType: data.youtubeVideoLink ? YOUTUBE_VIDEO_TYPE : UPLOAD_VIDEO_TYPE,
            youtubeVideoLink: data.youtubeVideoLink
        },
        questionIdentification: {
            gameType: tranformGameTypeOption?.[data.gameType] || "",
            identity: data.accessibleIdentityIds || [],
            questionTitle: data.title || "",
            tags:
                data?.questionTags?.map((item: any) => ({
                    value: item.tag.id,
                    label: item.tag.value
                })) || [],
            visibilityType: data.accessibilityScope
        },
        questionSettings: {
            correctAnswerText: data.correctAnswerText,
            forceCorrect: data.forceCorrect,
            isCorrectAnswerText: data.correctAnswerText !== "",
            isWrongAnswerText: data.wrongAnswerText !== "",
            maximumAttempts: data.maxAttempts,
            nonScoring: data.isNonScoringQuestion !== 0,
            skippingAllowed: data.allowSkipping,
            timeoutText: {
                specifyTimeout: data.timeLimit !== "00:00:00", //Need to cehck
                text: data.timeOutText,
                timelimit: {
                    minutes: data.timeLimit.split(":")[1],
                    seconds: data.timeLimit.split(":")[2]
                }
            },
            wrongAnswerText: data.wrongAnswerText,
            wrongAttemptPenaltyType: {
                correctPoints: data.correctPoints,
                skipPoints: data.skipPoints,
                timeoutPenalty: data?.timePenaltyOnWrongAttempts?.split(";") || [],
                type: data.timePenaltyOnWrongAttempts ? TIMEOUT_PENALTY : SCORE_PENALTY,
                wrongPoints: data.timePenaltyOnWrongAttempts ? "" : data.wrongPoints
            }
        }
    };
};
export const getQuestionInfo = (questionId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getQuestionDetailsApiUrl(parseInt(questionId)), true)
        .then((response: any) => Promise.resolve(transformQuestionData(response.data.data)))
        .catch(() => Promise.reject());

export const deleteCoverImage = (questionId: string) =>
    Client.getInstance()
        .deleteData(ApiConstants.removeQuestionCoverImage(parseInt(questionId)))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

const transformQuestionUsageList = (data: any): UsageListType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        name: extractHtmlContent(item.title)
    }));
};

export const fetchUsageListAction = (questionId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getUsageQuestionListApiUrl(questionId))
        .then((res) => {
            return Promise.resolve(transformQuestionUsageList(res.data.data));
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject(error);
        });

export const getInitialAnswerOption = (mcqOptions: MCQAnswerOption[], numberOfField: number) => {
    return Array(numberOfField)
        .fill(1)
        .map((_, index) => ({
            id: mcqOptions?.[index]?.id || Math.random(),
            text: mcqOptions?.[index]?.text || "",
            isCorrect: mcqOptions?.[index]?.isCorrect || index < 1
        }));
};

export const getLast = (object: Record<string, any>): any => {
    if (typeof object !== "object") {
        toast.error(object);
        return object;
    }
    for (const prop in object) {
        return getLast(object[prop]);
    }
};
