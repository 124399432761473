/* eslint-disable react-hooks/exhaustive-deps */
import {
    Dialog,
    DialogFooter,
    DialogType,
    IColumn,
    IconButton,
    IDialogContentProps,
    Panel,
    PanelType,
    PrimaryButton
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../constants/SiteConstant";
import DeleteConfirmationDialog from "../../CommonComponent/DeleteConfirmationDialog";
import { QuizAttachedToEvents, QuizListItem } from "./QuizListDataTypes";
import QuizDetailView from "./QuizDetails/QuizDetailsView";
import { JambarDateUtil } from "../../../constants/JambarDateUtils";
import ConfirmationDialog from "../../CommonComponent/ConfirmationDialog";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../CommonComponent/FixedHeaderSortingDetilsList";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import { statusDropdownOptions } from "../../../constants/DropdownOption";
import { usageListType } from "../../../data/question/types";
import { useAdminContext } from "../context/AdminAuthContext";
import { getPermissions } from "../../../utils/PermissionUtils";
import { RiGitRepositoryPrivateFill, RiGlobalLine } from "react-icons/ri";
import { SiRelianceindustrieslimited } from "react-icons/si";
import ConfirmationClose from "../../CommonComponent/ConfirmationClose";

type QuizListViewProps = {
    quizType: string;
    isConnector?: boolean;
};

const QuizListView: React.FC<QuizListViewProps> = ({ quizType, isConnector }) => {
    const [searchText, setSearchText] = useState("");
    const [showAddEditQuizPanel, setAddEditQuizPanelDisplay] = useState(false);
    const [quizList, setQuizList] = useState<QuizListItem[]>([]);
    const [selectedQuizId, setSelectedQuizId] = useState(-1);
    const [isListLoading, setIsListLoading] = useState(false);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [deleteEntryName, setDeleteEntryName] = useState("");
    const [showCloneConfirmationDialog, setCloneConfirmationDialogDisplay] = useState(false);
    const [showEventListDialog, setShowEventListDialog] = useState(false);
    const [eventList, setEventList] = useState<QuizAttachedToEvents[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>("active");
    const [usageList, setUsageList] = useState<usageListType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [accessibilityScope, setAccessibitlyScope] = useState("");
    const [closeConfirmationDialog, setCloseConfirmationDialog] = useState<boolean>(false);

    const { userDetail } = useAdminContext();

    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");

    useEffect(() => {
        fetchData(selectedStatus === "deleted" || selectedStatus === "all", quizType, true, selectedStatus);
    }, [selectedStatus]);

    const fetchData = (
        forceRefresh = false,
        quizListType = quizType,
        updateCurrentList = true,
        status = "all"
    ): void => {
        setIsListLoading(true);
        if (
            !(
                permissionQuizFullGame &&
                (permissionQuizFullGame?.includes("fetch") ||
                    permissionQuizSocketGame?.includes("fetch") ||
                    permissionQuizPhysicalGame?.includes("fetch"))
            )
        ) {
            setQuizList([]);
            setIsListLoading(false);
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getQuizListApiUrl(quizListType, status), forceRefresh)
            .then((response: any) => {
                if (updateCurrentList) {
                    const data: QuizListItem[] = response.data.data.map((quiz: any) => transformData(quiz));
                    setQuizList(data.filter((item) => (isConnector ? item.isConnectorQuiz : !item.isConnectorQuiz)));
                }
            })
            .catch(() => {
                setQuizList([]);
            })
            .finally(() => setIsListLoading(false));
    };

    const transformData = (quiz: any): QuizListItem => {
        return {
            id: quiz.id,
            accessibilityScope: quiz.accessibilityScope,
            title: extractHtmlContent(quiz.title),
            createdBy: `${quiz.createdByUser.firstName} ${quiz.createdByUser.lastName}`,
            createdDate: JambarDateUtil.formatDate(quiz.createdAt),
            lastUpdatedBy: `${quiz.updatedByUser.firstName} ${quiz.updatedByUser.lastName}`,
            lastUpdatedDate: JambarDateUtil.formatDate(quiz.updatedAt),
            displayTitle: extractHtmlContent(quiz.displayTitle || ""),
            isConnectorQuiz: quiz.isConnectorQuiz
        };
    };

    const onCloseAddQuizPanel = (id: number, quizListType: string): void => {
        setAddEditQuizPanelDisplay(true);
        setSelectedQuizId(id);
        fetchData(true, quizListType, quizListType === quizType, selectedStatus);
    };

    const onOpenEditQuizPanel = (id: number): void => {
        setAddEditQuizPanelDisplay(true);
        setSelectedQuizId(id);
    };

    const fetchQuizUsage = (forceRefresh = true, id: number): void => {
        Client.getInstance()
            .getData(ApiConstants.getQuizUsageApiUrl(id), forceRefresh)
            .then((response) => {
                const QuizUsageList = response.data.data.event;
                setUsageList(QuizUsageList);
                setLoading(false);
            });
    };

    const onDeleteQuiz = (id: number): void => {
        setLoading(true);
        fetchQuizUsage(true, id);
        setDeleteConfirmationDialogDisplay(true);
        setSelectedQuizId(id);
        setDeleteEntryName("quiz");
    };

    const onConfirmDelete = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteQuizApiUrl(selectedQuizId))
            .then(() => {
                setDeleteConfirmationDialogDisplay(false);
                toast.success("Quiz deleted", SiteConstants.deleteToastConfiguration);
                setSelectedQuizId(-1);
                setDeleteEntryName("");
                fetchData(true, quizType, true, selectedStatus);
            })
            .catch((error) => {
                setSelectedQuizId(-1);
                setDeleteConfirmationDialogDisplay(false);
                if (error.response.data.data && error.response.data.data[0] && error.response.data.data[0].eventId) {
                    setShowEventListDialog(true);
                    setEventList(() =>
                        error.response.data.data.map((item: any) => ({
                            id: item.eventId,
                            eventName: item.eventName
                        }))
                    );
                    return;
                }
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onCancelDelete = (): void => {
        setDeleteConfirmationDialogDisplay(false);
        setSelectedQuizId(-1);
        setDeleteEntryName("");
    };

    const onConfirmQuizClone = (): void => {
        Client.getInstance()
            .createData(ApiConstants.cloneQuizApiUrl(selectedQuizId), {})
            .then((response) => {
                setCloneConfirmationDialogDisplay(false);
                setSelectedQuizId(response.data.data.id);
                setAddEditQuizPanelDisplay(true);
                fetchData(true, quizType, true, selectedStatus);
                toast.success("Quiz cloned", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                setSelectedQuizId(-1);
                setCloneConfirmationDialogDisplay(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const quizListColumns: IColumn[] = [
        {
            key: "title",
            name: "Game Title",
            fieldName: "title",
            minWidth: SiteConstants.listColumnLarge
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "lastUpdatedBy",
            name: "Updated By",
            fieldName: "lastUpdatedBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        }
    ];

    const quizMenuItems = [
        {
            key: "clone",
            name: "",
            fieldName: "clone",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "update",
            name: "",
            fieldName: "update",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const renderVisibityIcon = (type: string) => {
        if (type === "private") {
            return <RiGitRepositoryPrivateFill style={{ marginLeft: "10px", fontSize: 14 }} />;
        }
        if (type === "global") {
            return <RiGlobalLine style={{ marginLeft: "10px", fontSize: 14 }} />;
        }

        return <SiRelianceindustrieslimited style={{ marginLeft: "10px", fontSize: 14 }} />;
    };

    const getColumns = () => {
        if (permissionQuizFullGame || permissionQuizSocketGame || permissionQuizPhysicalGame) {
            return [
                ...quizListColumns,
                ...quizMenuItems.filter(
                    (column) =>
                        permissionQuizFullGame?.includes(column.key) ||
                        permissionQuizSocketGame?.includes(column.key) ||
                        permissionQuizPhysicalGame?.includes(column.key)
                )
            ];
        }
        return quizListColumns;
    };

    const getFilteredItems = (): any => {
        const filteredItems =
            searchText === ""
                ? quizList
                : quizList.filter((item: QuizListItem) => {
                      return includeInFilteredItems(searchText.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: QuizListItem): boolean => {
        if (item.title && item.title.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.id && item.id.toString().includes(searchText)) {
            return true;
        }
        if (item.createdBy && item.createdBy.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.createdDate && item.createdDate.includes(searchText)) {
            return true;
        }
        if (item.lastUpdatedBy && item.lastUpdatedBy.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.lastUpdatedDate && item.lastUpdatedDate.includes(searchText)) {
            return true;
        }
        return false;
    };

    const renderItemColumn = (
        item: QuizListItem,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "title":
                return (
                    <span>
                        {item.title}
                        {renderVisibityIcon(item.accessibilityScope)}
                        <br />
                        <i>{item.displayTitle}</i>
                    </span>
                );
            case "createdBy":
                return (
                    <span>
                        {item.createdBy}
                        <br />
                        on <i>{item.createdDate}</i>
                    </span>
                );
            case "lastUpdatedBy":
                return (
                    <span>
                        {item.lastUpdatedBy}
                        <br />
                        on <i>{item.lastUpdatedDate}</i>
                    </span>
                );
            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => onDeleteQuiz(item.id)}
                        className={"deleteQuizTc"}
                    />
                );
            case "update":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => {
                            onOpenEditQuizPanel(item.id);
                            item.accessibilityScope && setAccessibitlyScope(item.accessibilityScope);
                        }}
                        className={"editQuizTc"}
                    />
                );
            case "clone":
                return (
                    <IconButton
                        iconProps={{ iconName: "DependencyAdd" }}
                        title="Duplicate"
                        onClick={() => {
                            setSelectedQuizId(item.id);
                            setCloneConfirmationDialogDisplay(true);
                        }}
                    />
                );

            default: {
                const fieldContent = item[column.fieldName as keyof QuizListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const eventListDialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: "Cannot delete this quiz",
        subText: "This quiz is currently in use in following events. Please close these events and then try again"
    };

    const closePanel = () => {
        setAddEditQuizPanelDisplay(false);
        setSelectedQuizId(-1);
        setAccessibitlyScope("");
    };

    return (
        <>
            <ToastContainer />
            <FixedHeaderSortingDetailsList
                onSearchTextChange={(text: string) => setSearchText(text)}
                searchText={searchText}
                hideAddNewButton={
                    !(
                        permissionQuizFullGame?.includes("add") ||
                        permissionQuizSocketGame?.includes("add") ||
                        permissionQuizPhysicalGame?.includes("add")
                    )
                }
                onAddButtonClick={() => setAddEditQuizPanelDisplay(true)}
                addNewButtonClassName={"addNewQuizTc"}
                columns={getColumns()}
                isLoading={isListLoading}
                items={getFilteredItems()}
                onRenderItemColumn={renderItemColumn}
                statusField={true}
                statusDropdownOptions={statusDropdownOptions}
                selectedStatusKey={selectedStatus}
                onStatusValueChange={(value: string) => setSelectedStatus(value)}
            />
            <ConfirmationDialog
                entryName={"quiz"}
                dialogHeader={"Confirm Clone"}
                isShown={showCloneConfirmationDialog}
                onConfirm={onConfirmQuizClone}
                actionName={"clone"}
                onDismiss={() => {
                    setSelectedQuizId(-1);
                    setCloneConfirmationDialogDisplay(false);
                }}
            />

            <Dialog
                hidden={!showEventListDialog}
                onDismiss={() => setShowEventListDialog(false)}
                dialogContentProps={eventListDialogContentProps}>
                <ul>
                    {eventList.map((item) => (
                        <li key={item.id}>{item.eventName}</li>
                    ))}
                </ul>
                <DialogFooter>
                    <PrimaryButton onClick={() => setShowEventListDialog(false)} text="Close" />
                </DialogFooter>
            </Dialog>
            <DeleteConfirmationDialog
                entryName={deleteEntryName}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={onCancelDelete}
                usageList={usageList}
                loading={loading}
                usedBy="events"
                hideButton={usageList.length !== 0}
            />
            <Panel
                className={"addEditQuizPanelTc"}
                isLightDismiss
                isOpen={showAddEditQuizPanel}
                onDismiss={() => {
                    selectedQuizId !== -1 ? closePanel() : setCloseConfirmationDialog(true);
                }}
                headerText={selectedQuizId === -1 ? "Add Game" : "Edit Game"}
                type={PanelType.extraLarge}>
                <QuizDetailView
                    quizId={selectedQuizId}
                    accessibilityScope={accessibilityScope}
                    refreshQuizList={(quizListType) => fetchData(true, quizListType, true, selectedStatus)}
                    closeAddPanel={(id, quizListType) => onCloseAddQuizPanel(id, quizListType)}
                />
            </Panel>
            <ConfirmationClose
                warningText="You have unsaved changes. Are you sure you want to close this?"
                dialogHeader={"Unsaved Changes"}
                isShown={closeConfirmationDialog}
                onConfirm={() => {
                    setCloseConfirmationDialog(false);
                    closePanel();
                }}
                onDismiss={() => {
                    setCloseConfirmationDialog(false);
                }}
            />
        </>
    );
};

export default QuizListView;
