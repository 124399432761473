import { Divider, Grid } from "@mui/material";
import { FC } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { CaptionText, SubText } from "../../../../CommonComponent/v2/CommonStyle";
import ConfirmationBox from "../../../../CommonComponent/v2/ConfirmationBox";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import SearchHeader from "../../../../CommonComponent/v2/searchheader/SearchHeader";
import { darkColor } from "../../../../WebsiteGame/colorConstant";
import { SelectedFileType } from "../Type";
import FileInfoCard from "./FileInfoCard";
import { useFileDetailHook } from "./Hooks";
import { deleteEventFiles, downloadEventFile, getFilesFilteredItems } from "./Util";

type FileDetailDrawerProps = {
    eventInfo: SelectedFileType;
};
const FileDetailDrawer: FC<FileDetailDrawerProps> = ({ eventInfo }) => {
    const {
        searchText,
        setSearchText,
        fileList,
        isLoading,
        selectedFileToDelete,
        setSelectedFileToDelete,
        fetchFiles
    } = useFileDetailHook(eventInfo.eventId);

    const handleDelete = () => {
        selectedFileToDelete &&
            deleteEventFiles(selectedFileToDelete.id)
                .then(() => {
                    toast.success("File deleted", SiteConstants.successToastConfiguration);
                    fetchFiles(true);
                    setSelectedFileToDelete(null);
                })
                .catch(() =>
                    toast.success("Something went wrong. Please try again", SiteConstants.deleteToastConfiguration)
                );
    };

    return (
        <Grid>
            <Grid container padding={"20px"}>
                <Grid item xs={12}>
                    <SearchHeader
                        searchText={searchText}
                        setSearchText={setSearchText}
                        showDownloadButton
                        buttonLabel="Download all data"
                        onDownloadButtonClick={() => {
                            downloadEventFile(eventInfo.eventId.toString());
                        }}
                    />
                </Grid>
                <Grid item xs={12} mt={"30px"}>
                    <CaptionText color={darkColor} fontWeight={"bold"}>
                        {eventInfo.eventName} -{" "}
                        <SubText>{fileList.length ? `Total ${fileList.length} files` : "No file found"}</SubText>
                    </CaptionText>
                    <Divider style={{ marginTop: 10 }} />
                </Grid>
            </Grid>
            <LoadingWrapper
                emptyTileLabel="No file found here"
                isNoItem={getFilesFilteredItems(fileList, searchText).length === 0}
                emptyTileSubLabel="Try finding files in another event"
                isLoading={isLoading}
                loadingHeight="250px">
                <Grid container p={"20px"} spacing={"15px"} alignItems={"stretch"}>
                    {getFilesFilteredItems(fileList, searchText).map((file) => (
                        <Grid item xs={6} key={file.id}>
                            <FileInfoCard
                                handleDelete={() =>
                                    setSelectedFileToDelete({
                                        id: file.id,
                                        fileName: file.fileName
                                    })
                                }
                                fileInfo={file}
                            />
                        </Grid>
                    ))}
                </Grid>
            </LoadingWrapper>
            {selectedFileToDelete && (
                <ConfirmationBox
                    message={`Are you sure you want to delete this ${selectedFileToDelete.fileName}?`}
                    onClose={() => setSelectedFileToDelete(null)}
                    title="Delete File"
                    onConfirm={handleDelete}
                />
            )}
        </Grid>
    );
};

export default FileDetailDrawer;
