import moment from "moment";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import { EventType } from "../addeventdetail/Type";
import { reverseTransformData } from "../addeventdetail/Util";

export const editEventBreadCrumbValues = (id: string): BreadCrumbType[] => [
    {
        label: "Event Manager",
        url: "/admin/event"
    },
    {
        label: "Events",
        url: `/admin/event/${id}/info`
    },
    {
        label: "Edit event"
    }
];

const transformData = (data: any): EventType => {
    return {
        gameType: data?.quiz?.type || "",
        eventLink: data?.eventLink || "",
        startDate: moment(data.eventStartDate).format("YYYY-MM-DD"),
        endDate: moment(data.eventEndDate).format("YYYY-MM-DD"),
        linkName: data.eventLink,
        eventName: data.name,
        noOfTeams: data.teamCount,
        gameId: data.quizId,
        isClosed: data.isClosed,
        generatedLink: data.eventLink
            ? `${window.location.origin}/${data.type === "mini" ? "minievent" : "event"}/${data.eventLink}`
            : "",
        leaderBoardLink: data.leaderboardLink || "",
        isDemo: data.isDemo,
        showSurvey: data.showSurveyAtEnd,
        isMini: data.type === "mini",
        referenceLinkFirst: data.referenceList.length !== 0 ? data.referenceList[0].id : "",
        referenceLinkSecond: data.referenceList.length === 2 ? data.referenceList[1].id : "",
        participantFilesLink: data.participantFilesLink || "",
        clientLogo: data.fileLocation ? `${data.fileLocation}?key=${new Date().getTime()}` : "",
        companyLogo: data.companyLogoFileLocation ? `${data.companyLogoFileLocation}?key=${new Date().getTime()}` : ""
    };
};


export const getEventDetails = (eventId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getEventDetailApiUrl(eventId), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

export const updateEventDetails = (eventId: string, values: EventType) =>
    Client.getInstance()
        .updateData(ApiConstants.updateEventApiUrl(eventId.toString()), reverseTransformData(values))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));
