export const FREE_TEXT = "free_text";
export const MCQ = "mcq";
export const FREE_TEXT_CONTAINS = "contains";
export const FREE_TEXT_DOES_NOT_CONTAINS = "does_not_contain";
export const FREE_TEXT_EXACT_MATCH = "exact_match";
export const BASIC_TEXT_EDITOR_CLASS = "basic-text-editor";
export const ADVANCE_TEXT_EDITOR_CLASS = "advance-text-editor";
export const FREE_TEXT_CONNECTORS = "connector";
export const FILE_UPLOAD = "file_upload";
export const QR_CODE = "qr_code";
export const CORRECT_ANSWER_COUNT = "CORRECT_ANSWER_COUNT";
export const WRONG_ANSWER_COUNT = "WRONG_ANSWER_COUNT";
export const SKIP_QUESTION = "SKIP_QUESTION";
export const NON_SCORING_QUESTION = "NON_SCORING_QUESTION";
export const QUESTION_TIMER_TYPE = "questionTimer";
export const QUIZ_TIMER_TYPE = "quizTimer";
export const CONNECTOR = "connector";
export const ATLEAST_ONE_CORRECT = "atleast_one_correct";
export const ALL_CORRECT = "all_correct";
export const ATLEAST_TWO_CORRECT = "atleast_2_correct";
export const ATLEAST_THREE_CORRECT = "atleast_3_correct";
export const ATLEAST_FOUR_CORRECT = "atleast_4_correct";
export const ATLEAST_FIVE_CORRECT = "atleast_5_correct";
export const CONNECTOR_SKIP = "connector_skip";
export const HINT = "hint";
export const QUESTION_TIMEOUT = "questionTimeOut";
export const EVENT_TIMEOUT = "eventTimeOut";
export const TIME_PENALTY = "timePenalty";
export const SCORE_PENALTY = "scorePenalty";
export const UPLOAD_VIDEO = "uploadVideo";
export const YOUTUBE_LINK = "youtubeLink";

export type QuestionDetailType = {
    visibility: string;
    questionType: string;
    startDate: string;
    endDate: string;
    coverImage: string;
    answerType: string;
    gameType: string;
    videoType: string | null;
    youtubeVideoText: string;
    isVideo: boolean;
    videoQuestion: string;
    tags: string[];
    question: string;
    correctAnswerText: string;
    wrongAnswerText: string;
    maxAttempts: string;
    fileInfo: {
        fileName: string;
        fileSize: number;
    };
    minutes: string;
    seconds: string;
    accessibleIdentityIds: string;
    specifyAnswerText: string;
    timeOutText: string;
    skippingAllowed: string;
    timeOutAllowed: boolean;
    timePenalty: string;
    QuestionTitle: string;
    isNonScoring: string;
    bottomQuestionText: string;
    answerHeader: string;
    referenceItemText1: string;
    referenceItemText2: string;
    showConnectorTimer: string;
    completionTriggerQuizScore: string;
    forceCorrect: string;
    createdUserDetail: {
        firstName: string;
        id: string;
        lastName: string;
    };
    updatedUserDetail: {
        firstName: string;
        id: string;
        lastName: string;
    };
    mcqOptions: McqOptionType;
    freeTextAnswer: string[];
    correctPoints: number;
    wrongPoints: number;
    skipPoints: number;
};

export interface IMcqOptions {
    text: string;
    id: number;
}
export type McqOptionType = {
    correctOptionsText: IMcqOptions[];
    wrongOptionsText: IMcqOptions[];
};

export type QuestionHintsType = {
    id: number;
    scorePenality: number;
    timePenality: number;
    description: string;
};
