import { SiteConstants } from "../../../../constants/SiteConstant";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import { QuizListItemType } from "./Type";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";

export const QuizTabOption = ({
    permissions
}: {
    permissions: {
        permissionQuizFullGame: string[];
        permissionQuizSocketGame: string[];
        permissionQuizPhysicalGame: string[];
    };
}) => [
    {
        label: "Virtual Unlinked",
        value: SiteConstants.spaceGameQuiz,
        isConnector: false,
        toShow: permissions.permissionQuizFullGame?.includes("fetch")
    },
    {
        label: "Virtual Linked",
        value: SiteConstants.socketQuiz,
        isConnector: false,
        toShow: permissions.permissionQuizSocketGame?.includes("fetch")
    },
    {
        label: "Physical",
        value: SiteConstants.wildgoat,
        isConnector: false,
        toShow: permissions.permissionQuizPhysicalGame?.includes("fetch")
    },
    {
        label: "Connector Game",
        value: SiteConstants.connectorQuestion,
        isConnector: true,
        toShow: permissions.permissionQuizFullGame?.includes("fetch")
    }
];

const transformData = (data: any, isConnectorQuiz = false): QuizListItemType[] => {
    if (!data) return [];
    const list: QuizListItemType[] = data.map((event: any) => ({
        id: event.id,
        title: extractHtmlContent(event.title),
        createdBy: `${event.createdByUser.firstName} ${event.createdByUser.lastName}`,
        createdDate: JambarDateUtil.formatDate(event.createdAt),
        lastUpdatedBy: `${event.updatedByUser.firstName} ${event.updatedByUser.lastName}`,
        lastUpdatedDate: JambarDateUtil.formatDate(event.updatedAt),
        displayTitle: extractHtmlContent(event.displayTitle || ""),
        isConnectorQuiz: event.isConnectorQuiz,
        accessibilityScope: event.accessibilityScope,
        type: event.type
    }));
    if (isConnectorQuiz) {
        return list.filter((item) => item.isConnectorQuiz);
    }

    return list;
};
export const getQuizListData = (quizListType: string, status: string, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getQuizListApiUrl(quizListType, status), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const onConfirmClone = (selectedQuizId: number) =>
    Client.getInstance()
        .createData(ApiConstants.cloneQuizApiUrl(selectedQuizId), {})
        .then((response: any) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));

export const onConfirmDelete = (selectedQuizId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteQuizApiUrl(selectedQuizId))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const filterDataBySearchText = (searchText: string, rows: QuizListItemType[]) => {
    return rows.filter(
        (row: QuizListItemType) =>
            (searchText !== "" && row.lastUpdatedDate.toLowerCase().includes(searchText.toLowerCase())) ||
            row.title.toLowerCase().includes(searchText.toLowerCase()) ||
            row.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.lastUpdatedBy.toLowerCase().includes(searchText.toLowerCase())
    );
};

export const onQuizListTypeOrStatusChange = (quizListType: string, status: string, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getQuizListApiUrl(quizListType, status), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const quizDelete = (quizId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteQuizApiUrl(quizId))
        .then(() => {
            toast.success("Quiz deleted", SiteConstants.deleteToastConfiguration);
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });

export const quizClone = (quizId: number) => {
    return Client.getInstance()
        .createData(ApiConstants.cloneQuizApiUrl(quizId), {})
        .then(() => {
            toast.success("Quiz cloned", SiteConstants.successToastConfiguration);
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const fetchQuizUsage = (forceRefresh = true, id: number) =>
    Client.getInstance()
        .getData(ApiConstants.getQuizUsageApiUrl(id), forceRefresh)
        .then((response) => Promise.resolve(response.data.data.event))
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
