import { Dialog, Grid } from "@mui/material";
import { FC } from "react";
import { FaCircle } from "react-icons/fa";
import { deleteColor, linkColor } from "../../../../constants/ColorConstant";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import LoadingButtonWrapper from "../../../CommonComponent/v2/button/LoadingButtonWrapper";
import {
    CaptionText,
    Header1,
    PrimaryButton,
    PrimaryOutlinedButton,
    Wrapper
} from "../../../CommonComponent/v2/CommonStyle";
import CloseIcon from "../../../Images/icons/close.svg";
import { UsageListType } from "./Type";

type GameDeleteDialogProps = {
    title: string;
    message: string[];
    onConfirm: () => void;
    onClose: () => void;
    usageList: UsageListType[];
    type?: "delete" | "update";
    showDeleteButton?: boolean;
    isLoading: boolean;
    confirmLoading?: boolean;
};
const GameDeleteDialog: FC<GameDeleteDialogProps> = ({
    title,
    message,
    onConfirm,
    onClose,
    type = "delete",
    isLoading,
    usageList,
    showDeleteButton,
    confirmLoading
}) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    padding: "40px"
                }
            }}
            maxWidth={"sm"}
            fullWidth
            onClose={onClose}
            open>
            <Wrapper>
                <Grid container justifyContent={"flex-end"}>
                    <img onClick={onClose} src={CloseIcon} style={{ height: 20 }} />
                </Grid>
                <Header1>{title}</Header1>
                {message.length > 0 ? (
                    message.map((item, index) => (
                        <CaptionText key={index} textAlign={"justify"}>
                            {item}
                        </CaptionText>
                    ))
                ) : (
                    <CaptionText textAlign={"center"}>{"Are you sure you want to delete"}</CaptionText>
                )}
                <LoadingButtonWrapper loading={isLoading}>
                    <Grid>
                        {usageList.map((item) => (
                            <CaptionText key={item.id} mt={"10px"} ml={"50px"}>
                                <FaCircle style={{ fontSize: 12, marginRight: 4 }} /> {extractHtmlContent(item.name)}
                            </CaptionText>
                        ))}
                    </Grid>
                </LoadingButtonWrapper>
                <Grid container justifyContent={"center"} spacing={"30px"} mt={"30px"}>
                    {(showDeleteButton || (!isLoading && usageList.length === 0)) && (
                        <Grid item>
                            <LoadingButtonWrapper loading={!!confirmLoading}>
                                <PrimaryButton
                                    background={type === "delete" ? deleteColor : linkColor}
                                    onClick={onConfirm}
                                    variant={"contained"}>
                                    Confirm
                                </PrimaryButton>
                            </LoadingButtonWrapper>
                        </Grid>
                    )}
                    <Grid item>
                        <PrimaryOutlinedButton onClick={onClose} variant={"text"}>
                            Cancel
                        </PrimaryOutlinedButton>
                    </Grid>
                </Grid>
            </Wrapper>
        </Dialog>
    );
};

export default GameDeleteDialog;
