import React, { useState } from "react";
import welcomeImage from "../../../Images/Wildgoat/Welcome.svg";
import { InputGroup } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import {
    WildGoatsCenterContainer,
    ContentBox,
    CustomInputBox,
    InfoText,
    WildGoatsContainer,
    LoginCircleButtonBig,
    CodeInputLogo
} from "../CommonStyledComponents/CommonStyle";
import { WelcomeText, MainImageContainer } from "./Style";
import { getOffsetColor } from "../Util/Util";
import { useHistory, useLocation } from "react-router";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import {
    wildgoatPrimaryColor,
    wildgoatSecondaryColor,
    wildgoatTertiaryColor
} from "../../../../constants/ColorConstant";
import Header from "../Header/Header";
import loadingGif from "../../../Images/loading.gif";

const CodeInputView = () => {
    const [code, setCode] = useState<string>("");
    const info: any = useLocation();
    const history = useHistory();
    const [eventLink] = useState(info.state.eventLink);
    const [loginFailed, setLoginFailed] = useState(false);
    const [name, setName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const moveToTeamNameLink = () => {
        setLoading(true);
        Client.getInstance()
            .getData(ApiConstants.validateParticipantCode(eventLink, code.toUpperCase()))
            .then((response) => {
                Client.getInstance()
                    .getData(ApiConstants.getTeamNameApiUrl(info.state.eventLink, code.toUpperCase()), true)
                    .then((res) => {
                        if (!res.data.data.teamName) {
                            history.push("/wildgoat/teamname", {
                                code: code.toUpperCase(),
                                eventLink: eventLink,
                                eventId: response.data.data.event.id,
                                quizId: response.data.data.quiz.id,
                                participantName: name,
                                quiz: info.state.quiz,
                                data: info.state.data,
                                isMultibranch: response.data.data.quiz.isMultiBranchQuiz,
                            });
                            return;
                        }
                        history.push("/wildgoat", {
                            code: code.toUpperCase(),
                            eventLink: eventLink,
                            eventId: response.data.data.event.id,
                            quizId: response.data.data.quiz.id,
                            teamName: res.data.data.teamName,
                            participantName: name,
                            isMultibranch: response.data.data.quiz.isMultiBranchQuiz,
                        });
                    });
            })
            .catch(() => {
                setLoginFailed(true);
                setLoading(false);
            });
    };

    return (
        <WildGoatsContainer
            bgcolor={info.state.quiz.quizConfig?.wildgoatPrimaryColor || wildgoatPrimaryColor}
            bgcolor_offset={getOffsetColor(info.state.quiz.quizConfig?.wildgoatPrimaryColor || wildgoatPrimaryColor)}>
            <Header
                quizTitle={info.state.quiz.displayTitle}
                companyLogo={info.state.data.companyLogo}
                quizLogo={info.state.data.logo}
                secondaryColor={info.state.quiz.quizConfig?.wildgoatSecondaryColor || wildgoatSecondaryColor}
            />
            <WildGoatsCenterContainer style={{ marginTop: 11 }}>
                <ContentBox>
                    <WildGoatsCenterContainer>
                        <MainImageContainer>
                            <CodeInputLogo src={info?.state?.data?.logo || welcomeImage} alt="welcome" />
                        </MainImageContainer>
                    </WildGoatsCenterContainer>
                    <WelcomeText>Welcome</WelcomeText>
                    <InfoText>Please input the code to start the game</InfoText>
                    <InputGroup onFocus={() => setLoginFailed(false)} size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Type code here..."}
                            value={code}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setCode(target.value)}
                        />
                    </InputGroup>
                    <InfoText style={{ marginTop: 20 }}>Enter your name to get started.</InfoText>
                    <InputGroup size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Enter your name here..."}
                            value={name}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setName(target.value)}
                        />
                    </InputGroup>
                    {loginFailed && <span className={"error-message"}>Please enter a valid Code</span>}
                    <LoginCircleButtonBig
                        secondaryColor={info.state.quiz.quizConfig?.wildgoatSecondaryColor || wildgoatSecondaryColor}
                        tertiarycolor={info.state.quiz.quizConfig?.wildgoatTertiaryColor || wildgoatTertiaryColor}
                        disabled={code === "" || name === "" || loading}
                        onClick={moveToTeamNameLink}>
                        {loading ? (
                            <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                        ) : (
                            <Icon iconName={"ChevronRight"} style={{ fontSize: 20 }} />
                        )}
                    </LoginCircleButtonBig>
                </ContentBox>
            </WildGoatsCenterContainer>
        </WildGoatsContainer>
    );
};

export default CodeInputView;
