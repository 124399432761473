import { FormHelperText, Grid, IconButton } from "@mui/material";
import Select from "react-select";
import { CaptionText, Header2, StyledTag2 } from "../../../../CommonComponent/v2/CommonStyle";
import FormikSelect, { FormikMultiSelect } from "../../../../CommonComponent/v2/fields/FormikSelect";
import { newSelectStyle } from "../../../EventManager/EventDetails/utils/helperFunctions";
import { useQuestionIdentificationFormHook } from "./Hooks";
import DeleteIcon from "../../../../Images/icons/delete.svg";
import { VisibiltyTypeOptions } from "../../../../../constants/DropdownOption";
import { differenceArrayObject } from "../../../../../utils/JambarUtils";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import { superAdmin } from "../../../../../constants/SiteConstant";
import { Settings } from "@mui/icons-material";
import TagModal from "./TagModal";
import { dropDownType } from "../../../EventManager/EventDetails/utils/type";

const QuestionIdentificationForm = () => {
    const {
        handleOptionChange,
        tagsOptions,
        userDetail,
        identityDropdownOptions,
        openTagModal,
        setTagOpenModal,
        fetchTagsDropdownData,
        data,
        formErrors,
        isSuperAdmin
    } = useQuestionIdentificationFormHook();

    return (
        <Grid container>
            <Grid item xs={12} mt={"20px"}>
                <Header2>Challenge identification</Header2>
                <Grid container mt={"19px"} spacing={"15px"}>
                    {!!userDetail?.currentRoles.find((item) => item.name === superAdmin) &&
                        userDetail?.identity.name === "jambar" && (
                            <>
                                <Grid item xs={12}>
                                    <FormikSelect
                                        name={"questionIdentification.visibilityType"}
                                        id={"questionIdentification.visibilityType"}
                                        options={VisibiltyTypeOptions}
                                        label={"Visibility type"}
                                    />
                                </Grid>
                                {data.questionIdentification.visibilityType === "limited" && (
                                    <Grid item xs={12}>
                                        <FormikMultiSelect
                                            name={"questionIdentification.identity"}
                                            id={"questionIdentification.identity"}
                                            options={identityDropdownOptions}
                                            label={"Identity type"}
                                            multiple
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"questionIdentification.questionTitle"}
                            id={"questionIdentification.questionTitle"}
                            label={"Challenge Name"}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={11}>
                                <Select
                                    options={differenceArrayObject(
                                        tagsOptions,
                                        data.questionIdentification.tags,
                                        "value"
                                    )}
                                    placeholder="Tags"
                                    isClearable
                                    styles={newSelectStyle(!!formErrors?.questionIdentification?.tags)}
                                    onChange={(value) => {
                                        value &&
                                            handleOptionChange(
                                                data.questionIdentification.tags,
                                                value as unknown as dropDownType
                                            );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => setTagOpenModal(true)}>
                                    <Settings />
                                </IconButton>
                            </Grid>
                            {openTagModal && (
                                <TagModal
                                    refreshTagList={fetchTagsDropdownData}
                                    handleClose={() => setTagOpenModal(false)}
                                />
                            )}
                        </Grid>
                        {!!formErrors?.questionIdentification?.tags && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                                {formErrors.questionIdentification.tags}
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {isSuperAdmin && (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid item mt={"20px"} xs={10}>
                                <CaptionText fontWeight={700} color={"#828282"}>
                                    Added tags
                                </CaptionText>
                                <CaptionText mt={"10px"} color={"#BDBDBD"}>
                                    {data.questionIdentification.tags.length === 0 ? (
                                        <span style={{ cursor: "pointer" }}>
                                            No tags added yet{" "}
                                            <span style={{ color: "#1f71B6", fontWeight: 700 }}>try adding one</span>
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </CaptionText>
                                <Grid container mt={"30px"} gap={"20px"}>
                                    {data.questionIdentification.tags &&
                                        data.questionIdentification.tags.map((role) => (
                                            <StyledTag2 item key={role.value}>
                                                <CaptionText color={"#1F71B6"} fontWeight={"bold"}>
                                                    {role.label}
                                                </CaptionText>
                                                <img
                                                    onClick={() =>
                                                        handleOptionChange(data.questionIdentification.tags, role)
                                                    }
                                                    alt={"delete"}
                                                    src={DeleteIcon}
                                                    style={{ height: 18, cursor: "pointer" }}
                                                />
                                            </StyledTag2>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default QuestionIdentificationForm;
