import { Grid, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { CancelButton, CaptionText, Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import ImageDrop from "../../../../CommonComponent/v2/fields/ImageDrop";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import FormikToggle from "../../../../CommonComponent/v2/fields/FormikToggle";
import { EventType } from "./Type";
import { getFile } from "./Util";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { whiteColor } from "../../../../WebsiteGame/colorConstant";
import { downloadFileFromUrl } from "../../../../../constants/HelperFns";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { HIDE_MINI_EVENT_FLAG } from "../../../../../utils/JambarUtils";

type ProvideDetailsProps = {
    nextButtonClick?: () => void;
    isUpdate?: boolean;
    backButtonClick?: () => void;
};
const ProvideDetails: FC<ProvideDetailsProps> = ({ nextButtonClick, backButtonClick, isUpdate = false }) => {
    const { errors, setFieldValue, values }: any = useFormikContext();
    const data: EventType = values as EventType;
    return (
        <Grid py={isUpdate ? "15px" : "40px"}>
            <Header1>Event Type</Header1>
            <Grid container mt={"30px"}>
                <Grid item xs={12}>
                    <FormikTextInput id={"eventName"} name={"eventName"} label={"Name of the event"} fullWidth />
                </Grid>
            </Grid>
            <Grid container gap={"40px"} mt={"30px"} alignItems={"center"}>
                <Grid item>
                    <CaptionText mb={"10px"} fontWeight={700}>
                        Client Logo{" "}
                        {typeof data.clientLogo === "string" && (
                            <IconButton
                                style={{
                                    backgroundColor: whiteColor
                                }}
                                onClick={() => {
                                    downloadFileFromUrl(data.clientLogo as string);
                                }}>
                                <AiOutlineCloudDownload color={"#609ACB"} />
                            </IconButton>
                        )}
                    </CaptionText>
                    <ImageDrop
                        logoInfo="Attach client logo (80px * 80px)"
                        selectedFile={getFile(data.clientLogo)}
                        imageSize={2}
                        name={"clientLogo"}
                    />
                </Grid>
                <Grid item>
                    <CaptionText mb={"10px"} fontWeight={700}>
                        Company Logo{" "}
                        {typeof data.companyLogo === "string" && (
                            <IconButton
                                style={{
                                    backgroundColor: whiteColor
                                }}
                                onClick={() => {
                                    downloadFileFromUrl(data.companyLogo as string);
                                }}>
                                <AiOutlineCloudDownload color={"#609ACB"} />
                            </IconButton>
                        )}
                    </CaptionText>
                    <ImageDrop
                        logoInfo="Attach company logo (80px * 80px)"
                        selectedFile={getFile(data.companyLogo)}
                        name={"companyLogo"}
                        imageSize={2}
                    />
                </Grid>
            </Grid>
            <Grid container gap={"50px"} mt={"30px"}>
                {HIDE_MINI_EVENT_FLAG && (
                    <Grid item>
                        <FormikToggle
                            onChange={() => {
                                setFieldValue("isMini", !data.isMini);
                                setFieldValue("isDemo", false);
                            }}
                            name="isMini"
                            id={"isMini"}
                            label={"Mini event"}
                        />
                    </Grid>
                )}

                {![SiteConstants.wildgoat, SiteConstants.socketQuiz].includes(data.gameType) && (
                    <>
                        <Grid item>
                            <FormikToggle disabled={data.isMini} name="isDemo" id={"isDemo"} label={"Demo event"} />
                        </Grid>
                        <Grid item>
                            <FormikToggle name="showSurvey" id={"showSurvey"} label={"Show survey"} />
                        </Grid>
                    </>
                )}
            </Grid>
            {!isUpdate && nextButtonClick && (
                <Grid item xs={10}>
                    <Grid container mt={"68px"} justifyContent={"center"}>
                        <Grid item>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <CancelButton fontsize="16px" onClick={backButtonClick}>
                                    Back
                                </CancelButton>
                                <PrimaryButton
                                    fontsize="16px"
                                    onClick={nextButtonClick}
                                    disabled={errors.eventName || errors.clientLogo || errors.companyLogo}>
                                    Next
                                </PrimaryButton>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default ProvideDetails;
