import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useGameListHook } from "./Hooks";
import { QuizListItemType, GameInfoType } from "./Type";

type GameListProps = {
    quizList: QuizListItemType[];
    isLoading: boolean;
    setCloneId: (info: GameInfoType) => void;
    setDeleteId: (info: GameInfoType) => void;
    tabOption: string;
    searchText: string;
};
const GameList: FC<GameListProps> = ({ quizList, isLoading, setCloneId, setDeleteId, tabOption, searchText }) => {
    const { gameListColumns } = useGameListHook(searchText);
    return (
        <Grid height={"calc(100vh - 280px)"}>
            <DataGridWrapper
                columns={gameListColumns(tabOption, setCloneId, setDeleteId)}
                rows={quizList as any}
                loading={isLoading}
            />
        </Grid>
    );
};

export default GameList;
