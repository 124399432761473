import { Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, IconContainer } from "../../../../../CommonComponent/v2/CommonStyle";
import { gameOptions, getFile } from "../../addeventdetail/Util";
import { EventInfoType } from "../Type";
import EditIcon from "../../../../../Images/icons/edit.svg";
import LogoRender from "../../addeventdetail/LogoRender";
import { useHistory, useParams } from "react-router";
import ReferenceGame from "./ReferenceGame";
import EventLink from "./EventLink";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import moment from "moment";

type EventInfoProps = {
    eventDetails: EventInfoType;
    showEdit?: boolean;
};
const EventInfo: FC<EventInfoProps> = ({ eventDetails, showEdit }) => {
    const { gameName, gameType, eventName, isMini, isDemo, showSurvey, clientLogo, companyLogo, startDate, endDate } =
        eventDetails;
    const selectedGameType = gameOptions().find((item) => item.value === gameType);
    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();
    return (
        <Grid>
            <CaptionText color={"#333333"} fontWeight={700}>
                Event Design
                {
                    showEdit && <IconContainer
                        onClick={() => history.push(`/admin/event/${id}/update`)}
                        src={EditIcon}
                        style={{ marginLeft: 10 }}
                    />
                }
            </CaptionText>
            <CaptionText mt={"10px"} display={"flex"}>
                Game name:{" "}
                <span
                    dangerouslySetInnerHTML={{
                        __html: gameName
                    }}
                    style={{ color: "#333333", marginLeft: 5 }}
                />{" "}
                - {selectedGameType?.title}
            </CaptionText>
            <CaptionText mt={"10px"} display={"flex"}>
                Valid from:{" "}
                <span style={{ color: "#333333", marginLeft: 5 }}>
                    {moment(new Date(startDate)).format("MMM Do YYYY")}
                </span>
            </CaptionText>
            <CaptionText mt={"10px"} display={"flex"}>
                Valid to:{" "}
                <span style={{ color: "#333333", marginLeft: 5 }}>
                    {moment(new Date(endDate)).format("MMM Do YYYY")}
                </span>
            </CaptionText>
            <hr style={{ margin: "20px 0px" }} />
            <Grid container gap={"80px"}>
                <Grid item xs={9}>
                    <CaptionText color={"#333333"} fontWeight={700}>
                        Event Type
                        {
                            showEdit && <IconContainer
                                onClick={() => history.push(`/admin/event/${id}/update`)}
                                src={EditIcon}
                                style={{ marginLeft: 10 }}
                            />
                        }
                    </CaptionText>
                    <CaptionText mt={"10px"}>
                        Event name: <span style={{ color: "#333333" }}>{eventName}</span>
                    </CaptionText>
                    <Grid container mt={"10px"} justifyContent={"space-between"}>
                        <Grid item mt={"10px"}>
                            <CaptionText>
                                Mini event: <span style={{ color: "#333333" }}>{isMini ? "Yes" : "No"}</span>
                            </CaptionText>
                        </Grid>
                        <Grid item mt={"10px"}>
                            <CaptionText>
                                Demo event: <span style={{ color: "#333333" }}>{isDemo ? "Yes" : "No"}</span>
                            </CaptionText>
                        </Grid>
                        <Grid item mt={"10px"}>
                            <CaptionText>
                                Show survey: <span style={{ color: "#333333" }}>{showSurvey ? "Yes" : "No"}</span>
                            </CaptionText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container mt={"10px"} spacing={"40px"}>
                <Grid item mt={"10px"}>
                    <CaptionText color={"#333333"} fontWeight={700}>
                        Client logo
                    </CaptionText>
                    <Grid mt={"10px"}>
                        <LogoRender file={getFile(clientLogo)} />
                    </Grid>
                </Grid>
                <Grid item mt={"10px"}>
                    <CaptionText color={"#333333"} fontWeight={700}>
                        Company logo
                    </CaptionText>
                    <Grid mt={"10px"}>
                        <LogoRender file={getFile(companyLogo)} />
                    </Grid>
                </Grid>
            </Grid>
            {![SiteConstants.wildgoat, SiteConstants.socketQuiz].includes(gameType) && (
                <Grid container mt={"10px"} spacing={"40px"}>
                    <Grid item xs={12}>
                        <ReferenceGame showEdit={showEdit} id={id} eventDetails={eventDetails} />
                    </Grid>
                </Grid>
            )}
            <hr style={{ margin: "20px 0px" }} />
            <Grid container mt={"10px"} spacing={"40px"}>
                <Grid item xs={12}>
                    <EventLink showEdit={showEdit} eventInfo={eventDetails} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EventInfo;
