import { Grid } from "@mui/material";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import EventList from "./EventList";
import { useGetEventManagerHook } from "./Hooks";
import { filterDataByTab } from "./util";

const EventManager = () => {
    const {
        searchText,
        setSearchText,
        eventTabs,
        selectedTab,
        setSelectedTab,
        eventList,
        isLoading,
        setSelectedCloneItem,
        selectedCloneItem,
        confirmDelete,
        selectedEventDeleteId,
        setSelectedEventDeleteId,
        permission,
        onConfirmEventClone,
        history,
        selectedEventForLiveStatus,
        setSelectedEventForLiveStatus,
        confirmHandleLiveStatus
    } = useGetEventManagerHook();

    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton={!!(permission && permission.includes("add"))}
                    onButtonClick={() => history.push("/admin/event/add")}
                    buttonLabel={"Add new event"}
                />
                <Grid container spacing={"20px"} mt={"20px"}>
                    <Grid item xs={12}>
                        <TabWrapper tabsOptions={eventTabs} selectedTab={selectedTab} handleChange={setSelectedTab} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <EventList
                    setSelectedCloneItem={setSelectedCloneItem}
                    setSelectedEventDeleteId={setSelectedEventDeleteId}
                    eventList={filterDataByTab(eventList, selectedTab, searchText)}
                    isLoading={isLoading}
                    selectedTab={selectedTab}
                    searchedText={searchText}
                    setSelectedEventForUpdateLiveStatus={setSelectedEventForLiveStatus}
                />
            </Grid>
            {selectedEventDeleteId && (
                <ConfirmationBox
                    title={`Delete "${selectedEventDeleteId.name}" event`}
                    message={"Please confirm if you really want to delete this event"}
                    onConfirm={confirmDelete}
                    onClose={() => setSelectedEventDeleteId(null)}
                />
            )}
            {selectedEventForLiveStatus && (
                <ConfirmationBox
                    title={`Change live Status ${selectedEventForLiveStatus.eventName} event`}
                    message={selectedEventForLiveStatus.isLive ? "Turn off this event?" : "Go-Live on this event?"}
                    onConfirm={confirmHandleLiveStatus}
                    onClose={() => setSelectedEventForLiveStatus(null)}
                />
            )}
            {selectedCloneItem && (
                <ConfirmationBox
                    title={`Do you want to duplicate the event - ${selectedCloneItem?.eventName}`}
                    message={"Are you sure you want to duplicate the event?"}
                    onConfirm={onConfirmEventClone}
                    onClose={() => setSelectedCloneItem(null)}
                />
            )}
        </Grid>
    );
};

export default EventManager;
