/* eslint-disable react-hooks/exhaustive-deps */
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import LogoNameColumn from "../../../CommonComponent/v2/datagrid/LogoNameColumn";
import { useAdminContext } from "../../context/AdminAuthContext";
import { cloneSelectedEvent } from "./eventinfo/eventdetail/Util";
import { EventDeleteType, EventListItem, EventUrls } from "./Type";
import { getEventDetails, getEventListData, initialUrls, onConfirmDelete, onMakeEventLiveStatus } from "./util";
import { Switch } from "@mui/material";

export const useGetEventManagerHook = () => {
    const [searchText, setSearchText] = useState("");
    const [selectedTab, setSelectedTab] = useState("upcoming");
    const [isLoading, setIsLoading] = useState(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "event");
    const [eventList, setEventList] = useState<EventListItem[]>([]);
    const [selectedCloneItem, setSelectedCloneItem] = useState<EventListItem | null>(null);
    const [selectedEventDeleteId, setSelectedEventDeleteId] = useState<EventDeleteType | null>(null);
    const history = useHistory();
    const [selectedEventForLiveStatus, setSelectedEventForLiveStatus] = useState<EventListItem | null>(null);

    const searchParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        searchParams.get("searchtext") && setSearchText(searchParams.get("searchtext"));
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (): void => {
        setIsLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setEventList([]);
            setIsLoading(false);
            return;
        }
        getEventListData(true)
            .then((res) => {
                setEventList(res);
            })
            .catch(() => {
                setEventList([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const confirmDelete = () => {
        selectedEventDeleteId &&
            onConfirmDelete(selectedEventDeleteId.id)
                .then(() => {
                    setSelectedEventDeleteId(null);
                    toast.success("Event deleted successfully", SiteConstants.successToastConfiguration);
                    fetchData();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
    };

    const confirmHandleLiveStatus = () => {
        selectedEventForLiveStatus &&
            onMakeEventLiveStatus(selectedEventForLiveStatus.id, !selectedEventForLiveStatus.isLive)
                .then(() => {
                    setSelectedEventForLiveStatus(null);
                    toast.success("Event Live Status Updated successfully", SiteConstants.successToastConfiguration);
                    fetchData();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
    };
    const eventTabs = useMemo(
        () => [
            {
                label: "Upcoming/Active",
                value: "upcoming",
                toShow: true
            },
            {
                label: "Past",
                value: "archived",
                toShow: true
            },
            {
                label: "Closed",
                value: "closed",
                toShow: true
            }
        ],
        []
    );

    const onConfirmEventClone = () => {
        selectedCloneItem &&
            cloneSelectedEvent(selectedCloneItem.id.toString())
                .then((eventId) => {
                    history.push(`/admin/event/${eventId}/info`);
                    toast.success("Event cloned", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setSelectedCloneItem(null));
    };

    return {
        searchText,
        setSearchText,
        eventTabs,
        setSelectedTab,
        selectedTab,
        eventList,
        isLoading,
        selectedCloneItem,
        setSelectedCloneItem,
        confirmDelete,
        selectedEventDeleteId,
        setSelectedEventDeleteId,
        permission,
        onConfirmEventClone,
        history,
        selectedEventForLiveStatus,
        setSelectedEventForLiveStatus,
        confirmHandleLiveStatus
    };
};

export const useEventListHook = () => {
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "event");

    const eventListColumns = (
        handleCopyLinkClick: (eventItem: EventListItem) => void,
        handleDeleteClick: (info: EventDeleteType) => void,
        handleEditClick: (id: number) => void,
        handleInfoClick: (id: number) => void,
        handleLiveLinkStatusClick: (info: EventListItem) => void
    ): GridColDef[] => [
        {
            field: "liveEvent",
            headerName: "Live Event",
            minWidth: 120,
            renderHeader: () => <StyledHeader label="Live Event" />,
            renderCell: (params: GridCellParams) => (
                <Switch onClick={() => handleLiveLinkStatusClick(params.row)} checked={params.row.isLive} />
            )
        },
        {
            field: "eventName",
            headerName: "Event Name",
            flex: 1,
            renderHeader: () => <StyledHeader label="Event Name" />,
            renderCell: (params: GridCellParams) => (
                <LogoNameColumn
                    onClick={() => handleInfoClick(params.row.id)}
                    name={params.row.eventName}
                    logo={params.row.fileLocation}
                />
            )
        },
        {
            field: "startDate",
            width: 120,
            renderHeader: () => <StyledHeader label="Starting On" />,
            renderCell: (params: GridCellParams) => (
                <StyledColumn
                    fontWeight={"500"}
                    color={"#1F71B6"}
                    label={JambarDateUtil.dateFormat(new Date(params.row.startDate), "DD/MM/YYYY")}
                />
            )
        },
        {
            field: "Created By",
            flex: 1,
            renderHeader: () => <StyledHeader label="Created By" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.createdBy} />
        },
        {
            field: "gameTitle",
            flex: 1,
            renderHeader: () => <StyledHeader label="Game Title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.gameTitle} />
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: !!(permission && permission.includes("delete")),
                        onClick: () =>
                            handleDeleteClick({
                                id: params.row.id,
                                name: params.row.eventName
                            })
                    }}
                    isUrlLinkAvailaible={{
                        isAvailable: true,
                        onClick: () => {
                            params.row.type === "mini"
                                ? window.open(`${window.location.origin}/minievent/${params.row.eventLink}`)
                                : window.open(`${window.location.origin}/event/${params.row.eventLink}`);
                        }
                    }}
                    isEditAvailaible={{
                        isAvailable: !!(permission && permission.includes("update")),
                        onClick: () => {
                            handleEditClick(params.row.id);
                        }
                    }}
                    isCloneAvailaible={{
                        isAvailable: !!(permission && permission.includes("clone")),
                        onClick: () => {
                            handleCopyLinkClick(params.row);
                        }
                    }}
                    isViewAvailaible={{
                        isAvailable: true,
                        onClick: () => {
                            handleInfoClick(params.row.id);
                        }
                    }}
                />
            )
        }
    ];

    return {
        eventListColumns
    };
};

export const useCopyUrlHook = (eventId: number) => {
    const [isLoading, setIsLoading] = useState(false);
    const [urlLink, setUrlLink] = useState<EventUrls>(initialUrls);
    const [selectedQrCodeUrl, setSelectedQrCodeUrl] = useState<string>("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (): void => {
        setIsLoading(true);
        getEventDetails(eventId.toString())
            .then((res) => setUrlLink(res))
            .catch((err) => {
                setUrlLink(initialUrls);
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        urlLink,
        selectedQrCodeUrl,
        setSelectedQrCodeUrl
    };
};
