import { toast } from "react-toastify";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { Client } from "../../../../Base/Client";
import { GameInfoQuestionType, GameInfoType } from "./Type";

export const gameDetailBreadcrumb = [
    {
        label: "Game library",
        url: "/admin/event"
    },
    {
        label: "Games",
        url: "/admin/event"
    },
    {
        label: "Game template"
    }
];

export const gameInfoTabs = (gameType: string) => [
    {
        label: "Game settings",
        value: "settings",
        toShow: true
    },
    {
        label: "Game questions",
        value: "questions",
        toShow: true
    },
    {
        label: "Attachments",
        value: "attachments",
        toShow: gameType !== SiteConstants.wildgoat
    },
    {
        label: "Game template",
        value: "template",
        toShow: true
    },
    {
        label: "Multibranch",
        value: "multibranch",
        toShow: true
    }
];

const transformQuizData = (data: any): GameInfoType => {
    const questions: GameInfoQuestionType[] = data.quiz_questions.map((quizQuestion: any, index: number) => ({
        id: quizQuestion.question.id,
        questionTitle: quizQuestion.question.title,
        index: index + 1
    }));

    return {
        gameSettings: {
            createdBy: {
                firstName: data.createdByUser.firstName,
                lastName: data.createdByUser.lastName
            },
            updateBy: {
                firstName: data.updatedByUser.firstName,
                lastName: data.updatedByUser.lastName
            },
            totalScoreVisibility: data?.quizConfig?.totalScoreVisibility ? "Yes" : "No",
            totalPointsText: data?.totalPointsText || "",
            aboutGame: {
                titleAdmin: data.title,
                titleGame: data.displayTitle,
                visibilityType: data.accessibilityScope || "private",
                showIndividualScore: data.showIndividualScore ? "Yes" : "No",
                showProgressInPercentage: data?.showProgressInPercentage ? "Yes" : "No",
                showMainTimer: data?.showMainTimer ? "Yes" : "No",
                showHintCount: data?.showHintCount ? "Yes" : "No",
                showCorrectAnswerCount: data?.showCorrectAnswerCount ? "Yes" : "No",
                showWrongAnswerCount: data?.showWrongAnswerCount ? "Yes" : "No",
                skippingAllowed: data?.allowSkipping ? "Yes" : "No"
            },
            answerResultImage: data?.answerResultImageFileLocation || "",
            answerResultText: data?.answerResultText || "",
            connectorQuiz: data?.isConnectorQuiz ? "Yes" : "No",
            gamePoints: {
                correctAnswerPoints: data.correctPoints || "0",
                wrongAnswerPoints: data?.wrongPoints || "0",
                initialGamePoints: data?.initialScore || "0",
                skipAnswerPoint: data?.skipPoints || "0"
            },
            gameSummary: data?.summaryPageText || "",
            gameTimeLimit: {
                minutes: data.timeLimit
                    ? parseInt(data.timeLimit.split(":")[1]) + parseInt(data.timeLimit.split(":")[0]) * 60
                    : 0,
                seconds: data.timeLimit ? parseInt(data.timeLimit.split(":")[2]) : 0
            },
            gameType: data?.type || "",
            nextButtonText: data?.nextButtonText || ""
        },
        gameQuestions: questions,
        gameTemplate: {
            attachemntSectionTitle: data?.quizConfig?.attachmentTitle || "",
            browserTabTitle: data?.quizConfig?.browserTabTitle || "",
            gameScreenBackgroundImage: data?.quizConfig?.backgroundImageFileLocation || "",
            progressImage: data?.quizConfig?.progressImageFileLocation || "",
            proressTitle: data?.quizConfig?.progressTitle || "",
            colors: {
                backgroundColor: data?.quizConfig?.baseColor || "",
                buttonColor: data?.quizConfig?.buttonColor || "",
                correctAnswerBackgroundColor: data?.correctAnswerBackground || "",
                wrongAnswerBackgroundColor: data?.wrongAnswerBackground || ""
            },
            widlgoatGameTemplate: {
                correctAnswerHeading: data?.quizConfig?.correctAnswerHeading || "",
                correctAnswerImage: data?.quizConfig?.correctAnswerImageLocation || "",
                timeoutAnswerImage: data?.quizConfig?.timeoutAnswerImageLocation || "",
                timeoutHeading: data?.quizConfig?.timeoutAnswerHeading || "",
                wrongAnswerHeading: data?.quizConfig?.wrongAnswerHeading || "",
                wrongAnswerImage: data?.quizConfig?.wrongAnswerImageLocation || "",
                primaryColor: data?.quizConfig?.wildgoatPrimaryColor || "",
                secondaryColor: data?.quizConfig?.wildgoatSecondaryColor || "",
                tertiaryColor: data?.quizConfig?.wildgoatTertiaryColor || ""
            }
        }
    };
};

export const getGameInfoData = (quizId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getQuizDetailApiUrl(parseInt(quizId)), true)
        .then((response) => Promise.resolve(transformQuizData(response.data.data)))
        .catch((error) => Promise.reject(error));

export const getFilteredQuestionList = (questionList: GameInfoQuestionType[], searchText: string): any => {
    if (searchText === "") {
        return questionList;
    }
    return questionList.filter((item) => item.questionTitle.toLowerCase().includes(searchText.toLowerCase()));
};

export const onConfirmDelete = (selectedQuizId: number) => {
    return Client.getInstance()
        .deleteData(ApiConstants.deleteQuizApiUrl(selectedQuizId))
        .then(() => Promise.resolve())
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject(error);
        });
};

export const onConfirmQuizClone = (selectedQuizId: number) => {
    return Client.getInstance()
        .createData(ApiConstants.cloneQuizApiUrl(selectedQuizId), {})
        .then((response) => {
            toast.success("Quiz cloned", SiteConstants.successToastConfiguration);
            return Promise.resolve(response.data.data.id);
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject(error);
        });
};
