import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { deleteColor, linkColor } from "../../../../../constants/ColorConstant";
import { CaptionText, Header1, PrimaryOutlinedButton, TextButton } from "../../../../CommonComponent/v2/CommonStyle";
import ConfirmationBox from "../../../../CommonComponent/v2/ConfirmationBox";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import TabWrapper from "../../../../CommonComponent/v2/tab/Tab";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import MultibranchQuestion from "../../multibranch";
import GameAttachmentList from "./GameAttachment";
import GameQuestions from "./GameQuestions";
import GameSettings from "./GameSettings";
import GameTemplate from "./GameTemplate";
import { useGameInfoHook } from "./Hooks";
import { gameInfoTabs } from "./Util";
import { SiteConstants } from "../../../../../constants/SiteConstant";

const GameInfo = () => {
    const history = useHistory();
    const {
        cloneGame,
        deleteGame,
        setCloneGame,
        setDeleteGame,
        onConfirmQuestionClone,
        onDeleteQuestion,
        selectedTab,
        setSelectedTab,
        loading,
        gameInfo,
        gameId,
        gameType,
        searchText
    } = useGameInfoHook();

    const renderView = () => {
        if (!gameInfo) {
            return <>Somthign went wrong</>;
        }
        if (selectedTab === "settings") {
            return <GameSettings gameSettingInfo={gameInfo.gameSettings} />;
        }
        if (selectedTab === "questions") {
            return <GameQuestions gameQuestions={gameInfo.gameQuestions} />;
        }
        if (selectedTab === "attachments") {
            return <GameAttachmentList />;
        }
        if (selectedTab === "template") {
            return <GameTemplate gameTemplate={gameInfo.gameTemplate} gameType={gameInfo.gameSettings.gameType} />;
        }
        if (selectedTab === "multibranch") {
            return <MultibranchQuestion gameId={gameId} />;
        }
        return null;
    };

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() =>
                                history.push(
                                    `/admin/v2/gamelibrary?gameType=${gameType}${
                                        searchText ? `&searchtext=${searchText}` : ""
                                    }`
                                )
                            }
                            src={ArrowLeft}
                            alt={"back"}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Game Details
                    </Header1>
                </Grid>
                <Grid item xs={8} mt={"20px"}>
                    <CaptionText>You can review the game details here.</CaptionText>
                </Grid>
            </Grid>
            <Grid container spacing={"20px"} mt={"20px"}>
                <Grid item xs={8}>
                    <TabWrapper
                        tabsOptions={gameInfoTabs(gameInfo?.gameSettings.gameType || SiteConstants.wildgoat)}
                        selectedTab={selectedTab}
                        handleChange={setSelectedTab}
                        variant={"scrollable"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Grid container justifyContent={"flex-end"} spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryOutlinedButton
                                fontsize="16px"
                                onClick={() => setDeleteGame(true)}
                                fullWidth
                                style={{ padding: "16px 10px" }}
                                textcolor={deleteColor}>
                                Delete game
                            </PrimaryOutlinedButton>
                        </Grid>
                        <Grid item xs={6}>
                            <PrimaryOutlinedButton
                                fontsize="16px"
                                onClick={() => setCloneGame(true)}
                                textcolor={linkColor}
                                fullWidth
                                style={{ padding: "16px 19px" }}>
                                Duplicate game
                            </PrimaryOutlinedButton>
                        </Grid>
                        {cloneGame && (
                            <ConfirmationBox
                                title={`Do you want to duplicate the game - ${gameInfo?.gameSettings.aboutGame.titleAdmin}`}
                                message={"Are you sure you want to duplicate the game?"}
                                onConfirm={onConfirmQuestionClone}
                                onClose={() => setCloneGame(false)}
                                type={"clone"}
                            />
                        )}
                        {deleteGame && (
                            <ConfirmationBox
                                title={`Do you want to close the game - ${gameInfo?.gameSettings.aboutGame.titleAdmin}`}
                                message={`This will close the event and will not be able to edit the game details.`}
                                onConfirm={onDeleteQuestion}
                                onClose={() => setDeleteGame(false)}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <LoadingWrapper isLoading={loading}>
                <>
                    <Grid mt={"20px"}>{renderView()}</Grid>
                    {selectedTab !== "detail" && (
                        <Grid alignItems={"center"} spacing={"10px"} pb={"50px"} justifyContent={"center"} container>
                            <Grid item>
                                <TextButton
                                    fontsize="16px"
                                    onClick={() =>
                                        history.push(
                                            `/admin/gamelibrary/${gameInfo?.gameSettings.gameType}/update/${gameId}`
                                        )
                                    }>
                                    Edit details
                                </TextButton>
                            </Grid>
                            <Grid item>
                                <PrimaryOutlinedButton
                                    fontsize="16px"
                                    onClick={() =>
                                        history.push(
                                            `/admin/v2/gamelibrary?gameType=${gameType}${
                                                searchText ? `&searchtext=${searchText}` : ""
                                            }`
                                        )
                                    }>
                                    Back
                                </PrimaryOutlinedButton>
                            </Grid>
                        </Grid>
                    )}
                </>
            </LoadingWrapper>
        </Grid>
    );
};

export default GameInfo;
