import {
    MenuItem,
    Select,
    SelectProps,
    FormControl,
    InputLabel,
    Autocomplete,
    TextField,
    TextFieldProps
} from "@mui/material";
import { FC } from "react";
import { OptionType } from "./Type";
type SelectAutocompleteWrapperProps = {
    options: OptionType[];
    value: string;
    handleChange: (value: string) => void;
} & TextFieldProps;
const SelectAutocompleteWrapper: FC<SelectAutocompleteWrapperProps> = ({ options, value, handleChange, ...rest }) => {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            onChange={(event: any, newValue: OptionType | null) => {
                handleChange(newValue.value);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        borderRadius: "10px"
                    }}
                    fullWidth
                    {...rest}
                />
            )}
        />
    );
};

export default SelectAutocompleteWrapper;
