import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
    CaptionText,
    CheckingTextfieldButton,
    ErrorTextfieldButton,
    PrimaryButton,
    SuccessTextfieldButton
} from "../CommonStyle";
import { Box, CircularProgress } from "@mui/material";
import { STRIPE_PUBLISHABLE_KEY } from "../../../../constants/SiteConstant";
import { StyledTextField } from "../fields/Style";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { useStripeProvider } from "./hook";
import { successColor } from "../../../../constants/ColorConstant";
import { countries } from "countries-list";
import { OptionType } from "../fields/Type";
import SelectAutocompleteWrapper from "../fields/SelectAutocomplete";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

type StripeFormProps = {
    credit: string;
    paymentComplete: () => void;
    disabled?: boolean;
    noPaymentRequired?: boolean;
    buttonText?: string;
};

const StripeForm = ({ credit, paymentComplete, disabled, noPaymentRequired, buttonText }: StripeFormProps) => {
    const {
        availableType,
        handleSubmit,
        loading,
        isChecking,
        promoCode,
        setPromoCode,
        setCardHolderName,
        cardHolderName,
        promoCodeValue,
        country,
        setCountry
    } = useStripeProvider(credit, paymentComplete);

    const countryOption = Object.entries(countries).map(
        (item) =>
            ({
                value: item[0],
                label: item[1].name
            } as OptionType)
    );

    const renderAvailaiblityButton = () => {
        if (promoCode === "") {
            return null;
        }
        if (isChecking) {
            return <CheckingTextfieldButton style={{ width: 150 }}>Checking...</CheckingTextfieldButton>;
        }
        if (availableType === "available") {
            return (
                <SuccessTextfieldButton style={{ width: 150, gap: 5 }}>
                    <AiOutlineCheck /> Availaible
                </SuccessTextfieldButton>
            );
        }
        if (availableType === "notavailaible") {
            return (
                <ErrorTextfieldButton style={{ width: 150, display: "flex", gap: 5 }}>
                    <AiOutlineClose /> Invalid
                </ErrorTextfieldButton>
            );
        }
        return null;
    };
    return (
        <form onSubmit={handleSubmit} className="StripeForm">
            <div className="AddCard">
                <input
                    style={{ width: "100%" }}
                    type="text"
                    name="cardHolderNumber"
                    placeholder="Name on card"
                    onChange={(e) => setCardHolderName(e.target.value)}
                />

                <CardElement
                    id="card-element"
                    className="StripeCardElement"
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                color: "black",
                                fontFamily: "Inter, --apple-system, sans-serif",
                                fontSmoothing: "antialiased",
                                fontSize: "16px",
                                "::placeholder": {
                                    color: "#747474"
                                }
                            },
                            invalid: {
                                color: "#fa755a",
                                iconColor: "#fa755a"
                            }
                        }
                    }}
                />

                <div style={{ width: "100%", marginTop: 20 }}>
                    <SelectAutocompleteWrapper
                        options={countryOption}
                        value={country}
                        handleChange={(opt) => {
                            setCountry(opt);
                        }}
                        label={"Country"}
                        style={{ borderRadius: "10px" }}
                    />
                </div>
            </div>

            <Box mb={"30px"} className="AddCard">
                <StyledTextField
                    onChange={(e) => setPromoCode(e.target.value)}
                    value={promoCode}
                    fullWidth
                    placeholder="Enter Promo Code"
                    label={"Promo Code"}
                    InputProps={{
                        endAdornment: renderAvailaiblityButton()
                    }}
                />
                {promoCodeValue && (
                    <CaptionText>
                        Hurray, You will save{" "}
                        <span style={{ fontWeight: 600, color: successColor }}>{promoCodeValue}$</span>
                    </CaptionText>
                )}
            </Box>
            <PrimaryButton
                fullWidth
                startIcon={loading && <CircularProgress size={"20px"} />}
                disabled={loading || disabled || (!noPaymentRequired && (!cardHolderName || !country))}
                onClick={handleSubmit}>
                {buttonText || "Submit"}
            </PrimaryButton>
        </form>
    );
};

const StripeElement = ({ credit, paymentComplete, ...rest }: StripeFormProps) => {
    return (
        <Elements stripe={stripePromise}>
            <StripeForm {...rest} credit={credit} paymentComplete={paymentComplete} />
        </Elements>
    );
};

export default StripeElement;
