import { Grid } from "@mui/material";
import { FC } from "react";
import { primaryDarkColor } from "../../../../../../constants/ColorConstant";
import { extractHtmlContent } from "../../../../../../utils/JambarUtils";
import { CaptionText, SubText } from "../../../../../CommonComponent/v2/CommonStyle";
import { QuestionDetailType, QuestionHintsType } from "../Type";

type QuestionSettingsProps = {
    questionDetails?: QuestionDetailType;
    questionHints: QuestionHintsType[];
};

const QuestionSettings: FC<QuestionSettingsProps> = ({ questionDetails, questionHints }) => {
    if (!questionDetails) {
        return <></>;
    }

    const renderScorePenalty = () => {
        if (questionDetails.wrongPoints) {
            return <CaptionText>{questionDetails.wrongPoints} points</CaptionText>;
        }
        if (questionDetails.timePenalty) {
            return (
                <CaptionText>
                    {questionDetails.timePenalty.split(";").map((time: string) => (
                        <span>{time}sec, </span>
                    ))}
                </CaptionText>
            );
        }
        return (
            <CaptionText color={primaryDarkColor} fontWeight={400}>
                No score penalty
            </CaptionText>
        );
    };

    return (
        <Grid container mt={"15px"}>
            <Grid item xs={12}>
                <CaptionText color={primaryDarkColor} fontWeight={600}>
                    Maximum attempts
                </CaptionText>
                <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                    {questionDetails.maxAttempts}
                </CaptionText>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={"2px"}>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Force correct
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {questionDetails.forceCorrect ? "Yes" : "No"}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Non scoring
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {questionDetails.isNonScoring ? "Yes" : "No"}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Skipping allowed
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {questionDetails.skippingAllowed ? "Yes" : "No"}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Wrong penalty type
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {questionDetails.timePenalty ? "Time penalty" : "Score Penalty"}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Specific timeout
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {questionDetails.timeOutAllowed ? "Yes" : "No"}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Add hint for players
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {questionHints.length > 0 ? "Yes" : "No"}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            {questionDetails.timePenalty ? "Time penalty" : "Score Penalty"}
                        </CaptionText>
                        {renderScorePenalty()}
                    </Grid>
                    {questionDetails.skipPoints ? (
                        <Grid item xs={4}>
                            <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                                Skip points
                            </CaptionText>
                            <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                                {questionDetails.skipPoints}
                            </CaptionText>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {questionDetails.correctPoints ? (
                        <Grid item xs={4}>
                            <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                                Correct Score
                            </CaptionText>
                            <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                                {questionDetails.correctPoints}
                            </CaptionText>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid item xs={4}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Hint text
                        </CaptionText>
                        {questionHints.length > 0 ? (
                            questionHints.map((item, index) => (
                                <CaptionText key={item.id} mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                                    {index + 1}. {item.description}
                                </CaptionText>
                            ))
                        ) : (
                            <SubText style={{ fontStyle: "italic" }}>No hints found</SubText>
                        )}
                    </Grid>
                    <Grid item xs={8}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Correct answer text
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {extractHtmlContent(questionDetails.correctAnswerText).trim() ? (
                                <span dangerouslySetInnerHTML={{ __html: questionDetails.correctAnswerText }} />
                            ) : (
                                <SubText style={{ fontStyle: "italic" }}>No text found</SubText>
                            )}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={8}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Wrong answer text
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {extractHtmlContent(questionDetails.wrongAnswerText).trim() ? (
                                <span dangerouslySetInnerHTML={{ __html: questionDetails.wrongAnswerText }} />
                            ) : (
                                <SubText style={{ fontStyle: "italic" }}>No text found</SubText>
                            )}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={8}>
                        <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                            Skipping answer text
                        </CaptionText>
                        <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                            {extractHtmlContent(questionDetails.timeOutText).trim() ? (
                                <span dangerouslySetInnerHTML={{ __html: questionDetails.timeOutText }} />
                            ) : (
                                <SubText style={{ fontStyle: "italic" }}>No text found</SubText>
                            )}
                        </CaptionText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default QuestionSettings;
