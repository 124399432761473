import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { useDebounce } from "../../../../../commonhooks/Hooks";
import QrCodeModal from "../../../../CommonComponent/QrCodeModal";
import {
    CancelButton,
    CaptionText,
    CheckingTextfieldButton,
    ErrorTextfieldButton,
    Header1,
    PrimaryButton,
    SuccessTextfieldButton
} from "../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import CopyIcon from "../../../../Images/icons/copy.svg";
import QrIcon from "../../../../Images/icons/qr.svg";
import RocketIcon from "../../../../Images/icons/rocket.svg";
import { useLaunchEventHook } from "./Hooks";
import { EventType } from "./Type";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import FormikSelect from "../../../../CommonComponent/v2/fields/FormikSelect";
import { SiteConstants } from "../../../../../constants/SiteConstant";

type LaunchEventProps = {
    isUpdate?: boolean;
    backButtonClick?: () => void;
    prevLinkName: string;
};
const LaunchEvent: FC<LaunchEventProps> = ({ backButtonClick, prevLinkName, isUpdate = false }) => {
    const { values, handleSubmit }: any = useFormikContext();

    const data: EventType = values as EventType;

    const { availableType, isChecking, openQrModal, checkLinkStatus, setOpenQrModal, referenceOptions } =
        useLaunchEventHook(prevLinkName);
    const renderIcon = (eventLink: string) => {
        return (
            <>
                <img
                    src={CopyIcon}
                    onClick={() => window.navigator.clipboard.writeText(eventLink)}
                    style={{ margin: "0px 21px", cursor: "pointer" }}
                />
                <img style={{ cursor: "pointer" }} onClick={() => setOpenQrModal(eventLink)} src={QrIcon} />
                <img
                    style={{ cursor: "pointer", margin: "0px 21px" }}
                    onClick={() => window.open(eventLink, "_blank")}
                    src={RocketIcon}
                />
            </>
        );
    };

    const debounceLinkName = useDebounce(data.linkName, 300);

    useEffect(() => {
        debounceLinkName !== "" && checkLinkStatus(debounceLinkName);
    }, [debounceLinkName]);

    const renderAvailaiblityButton = () => {
        if (data.linkName === "") {
            return null;
        }
        if (isChecking) {
            return <CheckingTextfieldButton>Checking...</CheckingTextfieldButton>;
        }
        if (availableType === "available") {
            return (
                <SuccessTextfieldButton>
                    <AiOutlineCheck style={{ fontSize: 25 }} /> Availaible
                </SuccessTextfieldButton>
            );
        }
        if (availableType === "notavailaible") {
            return (
                <ErrorTextfieldButton style={{ width: 150 }}>
                    <AiOutlineClose /> Not Availaible
                </ErrorTextfieldButton>
            );
        }
        return null;
    };

    return (
        <Grid container py={isUpdate ? "15px" : "40px"}>
            <Grid item xs={12}>
                <Header1>{"Event URL"}</Header1>
            </Grid>
            <Grid item xs={7} mt={"20px"}>
                <FormikTextInput
                    fullWidth
                    label={"Link name"}
                    name={"linkName"}
                    id={"linkName"}
                    InputProps={{
                        endAdornment: renderAvailaiblityButton()
                    }}
                />
            </Grid>

            {![SiteConstants.wildgoat, SiteConstants.socketQuiz].includes(data.gameType) && (
                <>
                    <Grid item xs={12} mt={"30px"}>
                        <FormikSelect
                            id={"referenceLinkFirst"}
                            name={"referenceLinkFirst"}
                            label={"First reference game"}
                            options={referenceOptions}
                        />
                    </Grid>
                    <Grid item xs={12} mt={"20px"}>
                        <FormikSelect
                            id={"referenceLinkSecond"}
                            name={"referenceLinkSecond"}
                            label={"Second reference game"}
                            options={referenceOptions}
                        />
                    </Grid>
                </>
            )}
            {data.linkName && (
                <Grid item xs={12} mt={"40px"}>
                    <CaptionText fontWeight={700}>Preview links</CaptionText>
                    <CaptionText mt={"20px"}>
                        <span style={{ fontWeight: 700, marginRight: 10 }}>Event Link:</span>
                        {window.location.origin}/event/{data.linkName.split(" ").join("_")}
                        {renderIcon(`${window.location.origin}/event/${data.linkName.split(" ").join("_")}`)}
                    </CaptionText>
                    <CaptionText mt={"20px"}>
                        <span style={{ fontWeight: 700, marginRight: 10 }}>Leaderboard link:</span>
                        {window.location.origin}/leaderboard/{data.linkName.split(" ").join("_")}
                        {renderIcon(`${window.location.origin}/leaderboard/${data.linkName.split(" ").join("_")}`)}
                    </CaptionText>
                    <CaptionText mt={"20px"}>
                        <span style={{ fontWeight: 700, marginRight: 10 }}>Participant file link</span>
                        {window.location.origin}/participant/files?participantFilesLink=
                        {data.linkName.split(" ").join("_")}
                        {renderIcon(
                            `${window.location.origin}/participant/files?participantFilesLink=${data.linkName
                                .split(" ")
                                .join("_")}`
                        )}
                    </CaptionText>
                </Grid>
            )}
            {openQrModal && <QrCodeModal url={openQrModal} handleClose={() => setOpenQrModal("")} />}
            {!isUpdate && backButtonClick && (
                <Grid item xs={10}>
                    <Grid container mt={"68px"} justifyContent={"center"}>
                        <Grid item>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <CancelButton fontsize="16px" onClick={backButtonClick}>
                                    Back
                                </CancelButton>
                                <PrimaryButton
                                    fontsize="16px"
                                    onClick={() => handleSubmit()}
                                    disabled={data.linkName === "" || availableType !== "available" || isChecking}>
                                    Finish
                                </PrimaryButton>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default LaunchEvent;
