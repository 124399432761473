import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import { MCQAnswerOption, Question, QuestionTags } from "./Type";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { extractHtmlContent } from "../../../../utils/JambarUtils";

const getTransformedMcqOptions = (options: string): MCQAnswerOption[] => {
    const mcqOptions = JSON.parse(options);
    const transformedMcqOptions = mcqOptions.map((element: any) => {
        return {
            text: element.text,
            id: element.id
        } as MCQAnswerOption;
    });
    return transformedMcqOptions;
};
const transformData = (data: any): Question[] => {
    if (!data) return [];
    return data.map((event: any, index: number) => ({
        accessibilityScope: event.accessibilityScope,
        title: extractHtmlContent(event.title),
        id: event.id,
        sr: index + 1,
        gameType: event.gameType,
        tags: event.questionTags.map((tag: any) => ({ id: tag.tag.id, value: tag.tag.value })),
        createdBy: `${event.createdByUser.firstName} ${event.createdByUser.lastName}`,
        createdDate: JambarDateUtil.formatDate(event.createdAt),
        lastUpdatedBy: `${event.updatedByUser.firstName} ${event.updatedByUser.lastName}`,
        lastUpdatedDate: JambarDateUtil.formatDate(event.updatedAt),
        questionInfo: {
            itemId: event.id,
            imagePath: event.fileLocation,
            questionType: event.type,
            questionText: event.questionText,
            correctAnswer: event.answer,
            mcqAnswerOptions: event.type === "mcq" ? getTransformedMcqOptions(event.mcqOptions) : [],
            tags: event.questionTags.map((tag: any) => ({ id: tag.tag.id, value: tag.tag.value })),
            timeLimit: event.timeLimit,
            wrongPoints: event.wrongPoints,
            wrongAnswerText: event.wrongAnswerText,
            correctAnswerText: event.correctAnswerText,
            correctPoints: event.correctPoints,
            maxAttempts: event.maxAttempts,
            skipPoints: event.skipPoints,
            correctOptions: event.mcqAnswers
                ? event.mcqAnswers.split(",").map((element: string) => parseFloat(element))
                : [],
            skippingAllowed: event.allowSkipping,
            freeTextCorrectAnswerType: event.answerType,
            questionTitle: event.title,
            isVideoQuestion: event.videoQuestionFileLocation ? true : false
        }
    }));
};
const transformTagData = (data: any): QuestionTags[] => {
    if (!data) return [];
    const initialData = [
        { id: -1, key: -1, value: "all", label: "All" },
        { id: -2, key: -2, value: "no_tag", label: "Not tagged" }
    ];
    const allData = data.map((event: any, index: number) => ({
        id: event.id,
        key: index,
        value: event.value,
        label: event.value
    }));
    return [
        ...initialData,
        ...allData.sort((a: any, b: any) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    ];
};

export const getQuestionList = (status: string, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getQuestionsListApiUrl(status), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const getTagDropdownData = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
        .then((response: any) => Promise.resolve(transformTagData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const onConfirmClone = (selectedQuestionId: number) =>
    Client.getInstance()
        .createData(ApiConstants.getCloneQuestionApiUrl(selectedQuestionId), {})
        .then((response: any) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));

export const onConfirmRestore = (selectedQuestionId: number) =>
    Client.getInstance()
        .updateData(ApiConstants.undoSoftChallengeApiUrl(selectedQuestionId.toString()), {})
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const onConfirmDelete = (selectedQuestionId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteQuestionApiUrl(selectedQuestionId))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const onConfirmBatchDelete = (SelectedQuetionIds: number[]) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteQuestionsBulkApiUrl(SelectedQuetionIds.join(";")))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const filterDataBySearchText = (searchText: string, rows: Question[]) => {
    return rows.filter(
        (row: Question) =>
            (searchText !== "" && row.title.toLowerCase().includes(searchText.toLowerCase())) ||
            row.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.createdDate.toLowerCase().includes(searchText.toLowerCase()) ||
            row.lastUpdatedBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.lastUpdatedDate.toLowerCase().includes(searchText.toLowerCase()) ||
            row.sr.toString().toLowerCase().includes(searchText.toLowerCase())
    );
};

export const getFilteredQuestionList = (
    questionList: Question[],
    filterTags: string,
    searchText: string
): Question[] => {
    let filteredData: Question[] = [];
    if (filterTags === "no_tag") {
        filteredData = questionList.filter((item) => item.tags.length === 0);
    } else if (filterTags === "all") {
        filteredData = questionList;
    } else {
        filteredData = questionList.filter((item) => item.tags.find((item) => item.value === filterTags));
    }
    return filterDataBySearchText(searchText, filteredData);
};

export const setQuestionGameType = (gameType: string, questionIds: number[]) =>
    Client.getInstance()
        .updateData(ApiConstants.updateQuestionGameTypeApiUrl(), {
            gameType: gameType,
            questionIds: questionIds.join(";")
        })
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

export const questionGameType: Record<string, string> = {
    wildgoatGame: "wildgoat",
    virtualLinked: "simplesocket",
    socketGame: "simplesocket"
};
