import { Box, Grid } from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";

const QRReaderView = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box height={300} width={300}>
                    <Scanner
                        constraints={{
                            facingMode: "environment",
                            height: 300,
                            width: 300
                        }}
                        onScan={(result) => alert(result[0].rawValue)}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default QRReaderView;
