import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { linkColor, primaryDarkColor } from "../../../../../../constants/ColorConstant";
import { capitalizeFirstLetter } from "../../../../../../constants/HelperFns";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { whiteColor } from "../../../../../../constants/Styles";
import { youtubeParser } from "../../../../../../utils/JambarUtils";
import { CaptionText, SubText } from "../../../../../CommonComponent/v2/CommonStyle";
import { OptionType } from "../../../../../CommonComponent/v2/fields/Type";
import { GameButton } from "../../../eventmanager/addeventdetail/Style";
import { gameOptions } from "../../../eventmanager/addeventdetail/Util";
import { FREE_TEXT, FREE_TEXT_CONNECTORS, MCQ, QuestionDetailType } from "../Type";

type QuestionDetailsProps = {
    questionDetails?: QuestionDetailType;
    tagsOption: OptionType[];
};

const QuestionDetails: FC<QuestionDetailsProps> = ({ questionDetails, tagsOption }) => {
    if (!questionDetails) {
        return <></>;
    }

    const getTagList = (tagsIndex: string) => tagsOption.find((tag) => tagsIndex === tag.value)?.label;

    const renderGameType = () => {
        if (questionDetails.questionType === MCQ) {
            return (
                <CaptionText fontWeight={700} color={linkColor}>
                    MCQ Challenge
                </CaptionText>
            );
        }
        if (questionDetails.questionType === FREE_TEXT) {
            return (
                <CaptionText fontWeight={700} color={linkColor}>
                    Free Text Challenge
                </CaptionText>
            );
        }
        if (questionDetails.questionType === FREE_TEXT_CONNECTORS) {
            return (
                <CaptionText fontWeight={700} color={linkColor}>
                    Connector Challenge
                </CaptionText>
            );
        }
        return (
            <CaptionText fontWeight={700} color={linkColor}>
                Image challenge
            </CaptionText>
        );
    };
    const renderVideoOrImage = () => {
        if (questionDetails.youtubeVideoText) {
            return (
                <>
                    <CaptionText fontWeight={600} color="#333333">
                        YouTube video
                    </CaptionText>
                    <iframe
                        width="100%"
                        frameBorder={0}
                        style={{ borderRadius: 10 }}
                        height="200"
                        src={`https://www.youtube.com/embed/${youtubeParser(questionDetails.youtubeVideoText)}`}
                        allowFullScreen></iframe>
                </>
            );
        }
        if (questionDetails.isVideo && questionDetails.videoQuestion) {
            return (
                <>
                    <CaptionText fontWeight={600} color="#333333">
                        Video
                    </CaptionText>
                    <video width="100%" height="200" controls>
                        <source src={questionDetails.videoQuestion} />
                    </video>
                </>
            );
        }

        return (
            <>
                <CaptionText fontWeight={600} color="#333333">
                    Cover Image
                </CaptionText>
                <img
                    width={"100%"}
                    style={{
                        height: 250,
                        objectFit: "cover",
                        borderRadius: 20
                    }}
                    src={questionDetails.coverImage}
                    alt="Image"
                />
            </>
        );
    };

    const selectedGameType = gameOptions().find((item) => item.value === (questionDetails?.gameType || ""));

    return (
        <Grid container mt={"15px"} spacing={"20px"}>
            <Grid item xs={3}>
                <Grid>{renderVideoOrImage()}</Grid>
                <Grid mt={"30px"}>
                    <CaptionText color="#333333" fontWeight="700">
                        Challenge type
                    </CaptionText>
                    {renderGameType()}
                </Grid>
                <GameButton mt={"20px"} xs={12} textAlign={"center"} item isactive={true}>
                    {selectedGameType?.inActiveicon}
                    <CaptionText mt={"5px"} color={whiteColor} textAlign={"center"}>
                        {selectedGameType?.title}
                    </CaptionText>
                </GameButton>
            </Grid>
            <Grid item xs={5}>
                <CaptionText color="#333333" fontWeight="650">
                    About challenge
                </CaptionText>
                <CaptionText mt={"10px"} fontWeight="400">
                    Visibility type:{" "}
                    <SubText fontWeight={400} color={primaryDarkColor}>
                        {capitalizeFirstLetter(questionDetails.visibility)}
                    </SubText>
                </CaptionText>
                <CaptionText mt={"10px"} fontWeight="400">
                    Title:{" "}
                    <SubText fontWeight={400} color={primaryDarkColor}>
                        {questionDetails.QuestionTitle}
                    </SubText>
                </CaptionText>
                <CaptionText mt={"10px"} fontWeight="400">
                    Tags:{" "}
                    <SubText fontWeight={400} color={primaryDarkColor}>
                        {questionDetails.tags.map((item) => getTagList(item)).join(", ")}
                    </SubText>
                </CaptionText>
                <hr />
                <CaptionText mt={"20px"} mb={"10px"} color="#333333" fontWeight="650">
                    Top challenge text
                </CaptionText>
                <Box
                    sx={{
                        "& p": {
                            marginBottom: 0
                        }
                    }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: questionDetails.question
                        }}
                    />
                </Box>

                <CaptionText mt={"20px"} mb={"10px"} color="#333333" fontWeight="650">
                    Bottom challenge text
                </CaptionText>
                <Box
                    sx={{
                        "& p": {
                            marginBottom: 0
                        }
                    }}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: questionDetails.bottomQuestionText
                        }}
                    />
                </Box>

                {questionDetails?.gameType !== SiteConstants.wildgoat && (
                    <>
                        <Grid container spacing={3}>
                            <Grid item>
                                <CaptionText mb={"10px"} color="#333333" fontWeight="650">
                                    Reference item 1
                                </CaptionText>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: questionDetails.referenceItemText1
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <CaptionText mb={"10px"} color="#333333" fontWeight="650">
                                    Reference item 2
                                </CaptionText>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: questionDetails.referenceItemText2
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={"20px"}>
                    <Grid item xs={6}>
                        <CaptionText fontWeight={600} color="#333333">
                            Created by
                        </CaptionText>
                        <CaptionText fontWeight={400} color="#333333">
                            {questionDetails.createdUserDetail.firstName} {questionDetails.createdUserDetail.lastName}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={6}>
                        <CaptionText fontWeight={600} color="#333333">
                            Updated by
                        </CaptionText>
                        <CaptionText fontWeight={400} color="#333333">
                            {questionDetails.updatedUserDetail.firstName} {questionDetails.updatedUserDetail.lastName}
                        </CaptionText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default QuestionDetails;
